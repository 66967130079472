<template>
  <div class="toast-custom">
    <div class="toast-custom__list">
      <toast-item
        v-for="item of toastsItems"
        :key="item.id"
        :title="item.title"
        :type="item.type"
      />
    </div>
  </div>
</template>

<script setup>
import ToastItem from "@/components/ToastItem.vue";
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const toastsItems = computed(() => {
  return store.state.toasts;
});
</script>

<style lang="scss" scoped>
.toast-custom {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1100;

  &__list {
    display: grid;
    gap: 15px;
  }
}

@media (max-width: 768px) {
  /* Медиазапрос для мобильных устройств */
  .toast-custom {
    top: auto; /* Отменяем верхнюю позицию */
    top: 15px; /* Перемещаем тост вниз */
    left: 100px; /* Центрируем тост */
    right: 100px; /* Убираем смещение вправо */
  }
}
</style>
