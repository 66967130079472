const _ = require("lodash");

const toString = (item, prefixWithZero = true) => {
  if (!_.isNumber(item)) {
    return null;
  }
  const hours = Math.floor(item / 60);
  const minutes = item % 60;
  let prefixHours = hours >= 10 ? "" : "0";
  let prefixMinutes = minutes >= 10 ? "" : "0";

  if (!prefixWithZero) {
    prefixHours = "";
    prefixMinutes = "";
  }

  return `${prefixHours}${hours}:${prefixMinutes}${minutes}`;
};

const toNumber = (time) => {
  if (!_.isString(time) || !time.length) {
    return null;
  }
  const parsedTime = time.split(":");
  const hours = parseInt(parsedTime[0], 10);
  const minutes = parseInt(parsedTime[1], 10);
  const totalMinutes = hours * 60 + minutes;
  return totalMinutes;
};

const minutesToHours = (minutes) => {
  let result = parseInt(minutes / 60);
  result += (minutes % 60) / 100;

  return result;
};

module.exports = {
  toString,
  minutesToHours,
  toNumber,
};
