<template>
  <div class="container-fluid my-3 py-3">
    <div class="row mb-5">
      <div class="col-lg-9 mx-auto">
        <div class="card mt-2" id="run-migrations">
          <div class="card-body">
            <div class="d-flex align-items-center mb-sm-0 mb-4">
              <div class="w-50">
                <h6>Запустити міграції</h6>
                Список міграцій:
                <ul>
                  <li v-for="migration in availableMigrations" :key="migration">
                    {{ migration }}
                  </li>
                </ul>
              </div>
              <div class="w-50 text-end">
                <button
                  class="btn bg-gradient-danger mb-0 ms-2"
                  type="button"
                  name="button"
                  data-bs-toggle="modal"
                  :data-bs-target="`#${modalParamsRunMigrations.modalId}`"
                >
                  Запустити міграції
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <BmnCustomPopup :params="modalParamsRunMigrations"></BmnCustomPopup>
</template>

<style scoped></style>

<script>
import api from "@/services/api";
import BmnCustomPopup from "@/components/BmnCustomPopup.vue";

export default {
  data() {
    return {
      profile: {
        rolesMap: {
          isRoot: false,
          isOwner: false,
          isManager: false,
          isStaff: false,
        },
      },

      availableMigrations: [],

      modalParamsRunMigrations: {
        modalId: "modal-default-1",
        modalTitle: "Запустити міграції",
        modalDescription: "Ви впевнені, що хочете запустити міграції?",
        modalButtonCancelText: "Ні, не варто",
        modalButtonConfirmText: "Так, запустити",
        data: {},
        confirmHandler: async () => {
          const result = await api.maintenance.runMigrations();
          console.log(result);
        },
      },
    };
  },

  async created() {
    this.showDevFeatures = !!localStorage.getItem("showDevFeatures");
    if (!this.showDevFeatures) {
      window.location.href = "/";
      return;
    }

    try {
      const user = await api.myprofile.show();

      this.profile.rolesMap = user.rolesMap;

      if (!this.profile.rolesMap.isRoot) {
        window.location.href = "/";
        return;
      }

      const result = await api.maintenance.getMigrations();
      this.availableMigrations = result.migrations;
    } catch (error) {
      console.error(error);
    }
  },

  components: {
    BmnCustomPopup,
  },
};
</script>
