<template>
  <div class="py-4 container-fluid">
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <div class="col-4 mx-auto">
          <router-link
            to="/warehouse/newproduct"
            class="btn btn-icon bg-gradient-primary"
          >
            + Товар
          </router-link>
        </div>
        <div class="col-5 mx-auto">
          <router-link
            to="/warehouse/orders/new-product-order"
            class="btn btn-icon bg-gradient-primary"
          >
            + Продажа
          </router-link>
        </div>
        <div class="col-5">
          <router-link
            to="/warehouse/supply"
            class="btn btn-icon bg-gradient-primary"
          >
            + Поставка
          </router-link>
        </div>
      </div>
      <div class="d-flex">
        <div class="dropdown d-inline">
          <a
            href="javascript:;"
            class="btn btn-outline-dark dropdown-toggle"
            data-bs-toggle="dropdown"
            id="navbarDropdownMenuLink2"
          >
            Фильтры
          </a>
          <ul
            class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
            aria-labelledby="navbarDropdownMenuLink2"
            data-popper-placement="left-start"
          >
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Статус: Закончился</a
              >
            </li>
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Статус: В наличии</a
              >
            </li>
            <li>
              <a
                class="dropdown-item border-radius-md text-danger"
                href="javascript:;"
                >Отменить фильтры</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Все товары</h5>
                <p class="mb-0 text-sm">
                  Список всех добавленных товаров. Добавляйте товары, и
                  создавайте отгрузку прямо с этой страницы
                </p>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table class="table table-flush" id="products-list">
                <thead class="thead-light">
                  <tr>
                    <th>Товар</th>
                    <th>Бренд</th>
                    <th>Цена поставки</th>
                    <th>Цена продажи</th>
                    <th>Количество</th>
                    <th>Статус</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="d-flex">
                        <div class="my-auto form-check">
                          <a
                            href="javascript:;"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Посмотреть"
                          >
                            <i class="fas fa-eye text-secondary"></i>
                          </a>
                        </div>
                        <div class="my-auto form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="customCheck2"
                            checked
                          />
                        </div>
                        <img
                          class="w-10 ms-3"
                          src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/adidas-hoodie.jpg"
                          alt="hoodie"
                        />
                        <h6 class="my-auto ms-3">Шапунь</h6>
                      </div>
                    </td>
                    <td class="text-sm">John&Johnson</td>
                    <td class="text-sm">1350 грн</td>
                    <td class="text-sm">1800 грн</td>
                    <td class="text-sm">0</td>
                    <td>
                      <span class="badge badge-danger badge-sm"
                        >Закончился</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="d-flex">
                        <div class="my-auto form-check">
                          <a
                            href="javascript:;"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Посмотреть"
                          >
                            <i class="fas fa-eye text-secondary"></i>
                          </a>
                        </div>
                        <div class="my-auto form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="customCheck2"
                            checked
                          />
                        </div>
                        <img
                          class="w-10 ms-3"
                          src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/macbook-pro.jpg"
                          alt="mac"
                        />
                        <h6 class="my-auto ms-3">MacBook Pro</h6>
                      </div>
                    </td>
                    <td class="text-sm">Electronics</td>
                    <td class="text-sm">$1,869</td>
                    <td class="text-sm">877712</td>
                    <td class="text-sm">0</td>
                    <td>
                      <span class="badge badge-danger badge-sm"
                        >Закончился</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="d-flex">
                        <div class="my-auto form-check">
                          <a
                            href="javascript:;"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Посмотреть"
                          >
                            <i class="fas fa-eye text-secondary"></i>
                          </a>
                        </div>
                        <div class="my-auto form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="customCheck3"
                          />
                        </div>
                        <img
                          class="w-10 ms-3"
                          src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/metro-chair.jpg"
                          alt="metro-chair"
                        />
                        <h6 class="my-auto ms-3">Metro Bar Stool</h6>
                      </div>
                    </td>
                    <td class="text-sm">Furniture</td>
                    <td class="text-sm">$99</td>
                    <td class="text-sm">0134729</td>
                    <td class="text-sm">978</td>
                    <td>
                      <span class="badge badge-success badge-sm"
                        >В наличии</span
                      >
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>Название</th>
                    <th>Бренд</th>
                    <th>Цена</th>
                    <th>Цена поставки</th>
                    <th>Цена продажи</th>
                    <th>Статус</th>
                    <th>Действие</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import setTooltip from "@/assets/js/tooltip.js";

export default {
  name: "products-list",
  mounted() {
    if (document.getElementById("products-list")) {
      const dataTableSearch = new DataTable("#products-list", {
        searchable: true,
        fixedHeight: false,
        perPage: 25,
      });

      document.querySelectorAll(".export").forEach(function (el) {
        el.addEventListener("click", function () {
          var type = el.dataset.type;

          var data = {
            type: type,
            filename: "soft-ui-" + type,
          };

          if (type === "csv") {
            data.columnDelimiter = "|";
          }

          dataTableSearch.export(data);
        });
      });
    }
    setTooltip();
  },
};
</script>
