<template>
  <div>
    <footer class="py-3 footer">
      <div class="d-lg-none"></div>
      <div class="container-fluid">
        <div class="row align-items-center justify-content-lg-between">
          <div class="mb-4 col-lg-6 mb-lg-0">
            <div
              class="text-sm text-center copyright text-muted"
              :class="this.$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'"
            >
              ©
              {{ new Date().getFullYear() }}, зроблено з 🫶🏽 в Дніпрі
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<style scoped>
.small-button {
  font-size: 10px;
}
.customIcon {
  color: #7c8098;
}
.top-left-button {
  position: fixed;
  margin: 0px;
  top: 14px;
  left: 14px;
  z-index: 1000;
}
</style>

<script>
export default {
  name: "app-footer",
};
</script>
