<script>
export default {
  name: "shared-spinner",

  async created() {},

  props: ["loaded", "variant"],
};
</script>

<template>
  <div v-if="!loaded" class="main" :class="{ small: variant === 'small' }">
    <div class="loader-container">
      <div class="loader"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main {
  height: 500px;
  background-color: #ffffff;
  margin: 25px;
  border-radius: 0.75rem;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  color: black;
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: opacity 0.2s, -webkit-transform 0.2s;
  -o-transition: opacity 0.2s, transform 0.2s;
  transition: opacity 0.2s, transform 0.2s;
  transition: opacity 0.2s, transform 0.2s, -webkit-transform 0.2s;

  &.small {
    height: 300px;
    align-items: center;

    .loader-container {
      margin-top: 0;
      position: relative;
      top: 0;
      left: 0;
      transform: translate(0%, 0%);
    }
  }
}

.loader-container {
  margin-top: 30vh;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  position: fixed;
  top: 0;
  left: 50%;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.loader {
  position: relative;
  text-indent: -9999em;
  border-top: 5.5px solid rgba(95, 95, 217, 0.2);
  border-right: 5.5px solid rgba(95, 95, 217, 0.2);
  border-bottom: 5.5px solid rgba(95, 95, 217, 0.2);
  border-left: 5.5px solid #056efe;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
