<template>
  <!-- ALL WAREHOUSE IS NOW MVP -->
  <div class="container-fluid my-3 py-3">
    <div class="row mb-5">
      <div class="col-lg-3">
        <div class="card position-sticky top-1">
          <ul class="nav flex-column bg-white border-radius-lg p-3">
            <li class="nav-item pt-2">
              <a
                class="nav-link text-dark d-flex"
                data-scroll=""
                href="#basic-info"
              >
                <i class="material-icons text-lg me-2">receipt_long</i>
                <span class="text-sm">Общая информация</span>
              </a>
            </li>
            <div class="w-50 mt-3">
              <a class="btn btn-primary" href="" role="button">Сохранить</a>
            </div>
          </ul>
        </div>
      </div>
      <div class="col-lg-9 mt-lg-0 mt-4">
        <!-- Card Basic Info -->
        <div class="card" id="basic-info">
          <div class="card-header">
            <h5>Общая информация</h5>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-6">
                <div class="input-group input-group-static">
                  <label>Название</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Johnson&Johnson"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="input-group input-group-static">
                  <label>Контактное лицо</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Артем Камушкин"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6">
                <div class="input-group input-group-static">
                  <label>Телефон</label>
                  <input
                    type="tel"
                    class="form-control"
                    placeholder="+380671234567"
                    minlength="13"
                    maxlength="13"
                    pattern="\+[0-9]{12}"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Card Basic Info finish -->

        <!--  Save  -->
        <div class="d-flex align-items-center mb-sm-0 mt-4">
          <div class="w-50"></div>
          <div class="w-50 text-end">
            <a class="btn btn-primary" href="" role="button">Сохранить</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>
