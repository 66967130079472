<template>
  <aside
    id="sidenav-main"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-3"
    :class="`${
      store.state.isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start ms-3'
    } ${store.state.sidebarType}`"
  >
    <div class="sidenav-header">
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      ></i>
      <a class="m-0 navbar-brand" href="/">
        <img :src="logo" class="navbar-brand-img h-100" alt="main_logo" />
        <span class="ms-1 font-weight-bold text-white">{{ companyName }}</span>
      </a>
    </div>
    <hr class="horizontal light mt-0 mb-2" />
    <sidenav-list />
  </aside>
</template>
<script setup>
import SidenavList from "./SidenavList.vue";
import logoLight from "@/assets/img/fav.svg";

import logoDark from "@/assets/img/logo-ct-dark.png";
import api from "@/services/api";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useCompanyStore } from "@/storePinia/company";
import { storeToRefs } from "pinia";

const store = useStore();
const companyStore = useCompanyStore();
const { company } = storeToRefs(companyStore);

const companyName = ref("");
const props = defineProps({
  custom_class: {
    type: String,
    default: "",
  },
});

const logo = computed(() => {
  if (company.value?.photoLogo?.url) {
    return company.value.photoLogo.url;
  }

  return store.state.sidebarType === "bg-white" ||
    (store.state.sidebarType === "bg-transparent" && !store.state.isDarkMode)
    ? logoDark
    : logoLight;
});

onMounted(async () => {
  companyName.value = localStorage.getItem("app.companyName");

  if (companyName.value === "undefined") {
    companyName.value = "";
    localStorage.removeItem("app.companyName");
  }

  if (!companyName.value) {
    const company = await api.company.show();
    if (company) {
      companyName.value = company.name;
      localStorage.setItem("app.companyName", companyName.value);
    }
  }
});
</script>

<style scoped>
.navbar-brand-img {
  border: 0.1px solid white;
  border-radius: 4px;
}
</style>
