<template>
  <div
    ref="modalRef"
    class="modal fade"
    :id="params.modalId"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="params.modalId"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal- modal-dialog-centered modal-"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title font-weight-semibold" id="modal-title-default">
            {{ params.modalTitle }}
          </h6>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div v-if="params.modalDescription" class="modal-body text-start">
          <slot name="modal-body">
            {{ params.modalDescription }}
          </slot>
        </div>
        <div
          v-if="slots['modal-default']"
          class="d-flex justify-content-center align-items-center mt-3"
        >
          <slot name="modal-default"></slot>
        </div>
        <div
          v-if="slots['modal-qr']"
          class="d-flex justify-content-center align-items-center my-3"
        >
          <slot name="modal-qr"></slot>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary ml-auto"
            data-bs-dismiss="modal"
            @click="handleCancel"
          >
            {{ params.modalButtonCancelText }}
          </button>
          <button
            v-if="params.modalButtonConfirmText !== 'false'"
            type="button"
            class="btn"
            :class="{
              [getConfirmButtonColor()]: true,
            }"
            data-bs-dismiss="modal"
            :disabled="!isActiveConfirmButton()"
            @click="handleConfirm"
          >
            {{ params.modalButtonConfirmText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Modal from "bootstrap/js/dist/modal";
import { ref, defineProps, useSlots, watch } from "vue";

const props = defineProps({
  params: {
    type: Object,
    required: true,
    default: () => ({
      modalId: "modal-default",
      modalTitle: "Заголовок",
      modalDescription: "Опис",
      isSuccess: false,
      confirmButtonColor: "danger",
      modalButtonCancelText: "Відмінити",
      modalButtonConfirmText: "Підтвердити",
      data: {
        index: undefined,
        paymentId: undefined,
      },

      cancelHandler: () => {},
      confirmHandler: () => {},
      confirmButtonIsValid: () => {},
    }),
  },
});

const slots = useSlots();

const modalRef = ref(null);
const modalInstance = ref(null);

const isConfirmed = ref(false);

watch(modalRef, () => {
  if (modalRef.value) {
    modalInstance.value = new Modal(modalRef.value, {});

    modalRef.value?.addEventListener("hidden.bs.modal", () => {
      if (!isConfirmed.value) {
        handleCancel();
      }
      isConfirmed.value = false;
    });
  }
});

defineExpose({
  modalRef,
  showModal,
});

function handleCancel() {
  if (props.params.cancelHandler) {
    props.params.cancelHandler(props.params.data);
  }
}
function handleConfirm() {
  if (props.params.confirmHandler) {
    props.params.confirmHandler(props.params.data);
    isConfirmed.value = true;
  }
}

function showModal() {
  modalInstance.value.show();
}

function isActiveConfirmButton() {
  if (!props.params.confirmButtonIsValid) {
    return true;
  }
  return props.params.confirmButtonIsValid();
}

function getConfirmButtonColor() {
  if (props.params.confirmButtonColor === "info") {
    return "bg-gradient-info";
  }
  if (props.params.confirmButtonColor === "danger") {
    return "bg-gradient-danger";
  }
  if (props.params.confirmButtonColor === "success") {
    return "bg-gradient-success";
  }
  if (props.params.confirmButtonColor === "primary") {
    return "bg-gradient-primary";
  }
  return "bg-gradient-danger";
}
</script>

<style scoped>
button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
</style>
