<template>
  <div class="container-fluid">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div v-if="dataLoaded" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-3">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3">
                <label>Статус</label>
                <div class="input-group input-group-outline">
                  <SelectApp
                    v-model="statusModel"
                    :settings="{
                      options: optionsItems,
                      trackBy: 'value',
                      label: 'label',
                      allowEmpty: false,
                    }"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-lg-6">
                <label>Текст відгука</label>
                <div class="input-group input-group-outline">
                  <textarea
                    class="form-control"
                    :placeholder="reviewModel.comment"
                    rows="5"
                    style="height: 100px"
                    disabled
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="row">
                <div class="">
                  <label>Середня оцінка: {{ reviewModel.avgRating }}</label>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <div class="table-responsive">
                    <table class="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th
                            class="text-uppercase text-xxs font-weight-bolder opacity-7"
                          >
                            Параметр оцінки
                          </th>
                          <th
                            class="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2"
                          >
                            Оцінка
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in reviewModel.attributes"
                          :key="index"
                        >
                          <td>
                            <p class="text-sm font-weight-normal mb-0">
                              {{ item.label }}
                            </p>
                          </td>
                          <td>
                            <p class="text-sm font-weight-normal mb-0">
                              {{ item.value }}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class=""></div>
          </div>
        </div>
      </div>
    </div>
    <!--  Зберегти -->
    <div class="fixed-bottom">
      <div class="row">
        <div class="col-10"></div>
        <div class="col-lg-2">
          <div class="mx-4">
            <button
              type="button"
              class="block btn bg-gradient-primary"
              @click="togglePublishStatus"
            >
              Зберегти
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--  Зберегти finish-->
  </div>
</template>

<script>
import api from "@/services/api";
import SelectApp from "@/components/common/SelectApp.vue";

import Spinner from "@/views/shared/Spinner.vue";

export default {
  setup() {},
  props: {
    feedbackId: {
      type: String,
      required: true,
    },
  },
  components: { Spinner, SelectApp },
  name: "edit-review",
  data() {
    return {
      dataLoaded: false,
      reviewModel: null,
      statusModel: null,
    };
  },
  computed: {
    optionsItems() {
      return [
        {
          label: "Неопублікований",
          value: false,
        },
        {
          label: "Опублікований",
          value: true,
        },
      ];
    },
  },
  async mounted() {
    const { feedbackId } = this;

    this.reviewModel = await api.feedbacks.show(feedbackId);

    this.statusModel = this.reviewModel.published
      ? {
          label: "Опублікований",
          value: true,
        }
      : {
          label: "Неопублікований",
          value: false,
        };

    this.dataLoaded = true;
  },
  methods: {
    async togglePublishStatus() {
      const { feedbackId } = this;
      const { value } = this.statusModel;
      try {
        await api.feedbacks.update(feedbackId, { published: value });

        this.$store.commit("addToast", {
          title: "Збережено",
        });

        this.$router.push({ name: "ReviewListPage" });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped>
.table thead th {
  padding: 0.5rem;
}

.table-responsive {
  border: 1px solid #ddd; /* Внешняя граница для всего блока */
}
.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}
</style>
