<template>
  <div class="row">
    <div class="col-lg-4 col-md-8 col-12 mx-auto">
      <div class="card z-index-0 fadeIn3 fadeInBottom">
        <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
          <div class="customBackground border-radius-lg py-3 pe-1">
            <h4 class="text-black font-weight-bolder text-center mt-2 mb-0">
              Вхід
            </h4>
          </div>
        </div>
        <div class="card-body">
          <div class="tab-content tab-space">
            <div id="phone" class="tab-pane active">
              <div class="row">
                <form class="text-start" role="form">
                  <div
                    v-if="reason === 'jwtExpired'"
                    class="input-group input-group-outline my-3"
                  >
                    <div
                      class="alert alert-warning"
                      role="alert"
                      style="width: 100%; color: #664d03"
                    >
                      👨‍💻 Ви потрапили на цю сторінку не випадково.<br />
                      З метою підтримки безпеки даних наших користувачів ми
                      просимо їх наново логінитись раз на 3 місяці. Дякуємо за
                      розуміння.
                    </div>
                  </div>
                  <label>Телефон</label><label class="required">*</label>
                  <div class="input-group input-group-outline">
                    <input
                      v-model="phoneNumber"
                      class="form-control"
                      maxlength="13"
                      minlength="13"
                      pattern="\+[0-9]{12}"
                      type="tel"
                    />
                  </div>
                  <div class="col text-center">
                    <span v-show="showBlock">
                      Ми відправили запит на вхід вам в
                      <a
                        class="font-weight-normal text-primary"
                        href="https://t.me/Bookmenowbot"
                        target="_blank"
                        >Telegram</a
                      >. Очікуємо підтвердження входу через застосунок Telegram
                    </span>

                    <div
                      v-if="showNoTgChatIdInfo"
                      class="alert alert-no-tg-chat-info"
                      role="alert"
                    >
                      <span class="text-sm"
                        >Щоб використовувати вхід через Телеграм, необхідно
                        підписатись на нашого Телеграм бота і виконати всі його
                        інструкції.<br /><a
                          class="alert-link"
                          href="https://t.me/Bookmenowbot"
                          target="_blank"
                          >Підписатись</a
                        ></span
                      >
                    </div>
                    <div
                      class="form-check form-switch d-flex align-items-center"
                    ></div>
                    <div class="text-center">
                      <button
                        :disabled="isPhoneNumberEmpty || isLoading"
                        class="btn bg-gradient-primary my-2 mb-2 telegram-button"
                        type="button"
                        @click="signinTelegram"
                      >
                        <SmallLoader v-if="isLoading" />
                        <span v-else
                          ><i class="fab fa-telegram" /> Увійти через
                          Telegram</span
                        >
                      </button>
                    </div>
                    <button
                      v-if="!isLoading"
                      aria-controls="collapseExample"
                      aria-expanded="false"
                      class="btn btn-outline-primary"
                      data-bs-target="#collapseExample"
                      data-bs-toggle="collapse"
                      type="button"
                    >
                      увійти за паролем
                    </button>
                    <div id="collapseExample" class="collapse">
                      <div>
                        <div class="text-start">
                          <label>Пароль</label><label class="required">*</label>
                        </div>
                        <div class="input-group input-group-outline">
                          <label v-show="!password" class="form-label"></label>
                          <input
                            v-model="password"
                            class="form-control"
                            type="password"
                          />
                        </div>
                        <div
                          v-show="availableTenants.length > 0"
                          class="input-group input-group-outline my-3"
                        >
                          <SelectApp
                            v-model="tenantName"
                            :settings="{
                              options: availableTenants,
                              searchable: true,
                              placeholder: 'Оберіть компанію',
                              preselectFirst: true,
                            }"
                          />
                        </div>
                        <div class="text-center">
                          <button
                            :disabled="isDisabled || isLoading"
                            class="btn bg-gradient-primary w-100 my-4 mb-2"
                            type="button"
                            @click="signin"
                          >
                            <SmallLoader v-if="isLoading" />
                            <span v-else> Увійти</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="form-check form-switch d-flex align-items-center"
                  ></div>
                  <p class="mt-4 text-sm text-center">
                    Ще немає акаунта?
                    <router-link
                      class="text-primary text-gradient font-weight-bold"
                      to="/authentication/signup"
                    >
                      Зареєструйтесь
                    </router-link>
                  </p>
                </form>
              </div>
              <div
                v-if="showError"
                class="alert alert-danger alert-dismissible text-white"
                role="alert"
              >
                <span class="text-sm">{{ showError }}</span>
                <!--<button type="button" class="btn-close text-lg py-3 opacity-10" data-bs-dismiss="alert" aria-label="Close">-->
                <!--  <span aria-hidden="true">&times;</span>-->
                <!--</button>-->
              </div>
            </div>
            <div id="email" class="tab-pane">
              <div class="row">
                <form class="text-start" role="form">
                  <div class="input-group input-group-outline my-3">
                    <label class="form-label">Email</label>
                    <input class="form-control" type="email" />
                  </div>
                  <div class="input-group input-group-outline my-3">
                    <label class="form-label">Пароль</label>
                    <input class="form-control" type="password" />
                  </div>
                  Если вы забыли свой пароль - свяжитесь с нашей техподдержкой,
                  которая поможет вам с его восстановлением
                  <a
                    class="font-weight-normal text-primary"
                    href="mailto:support@bookmenow.pro"
                    target="_blank"
                    >support@bookmenow.pro</a
                  >
                  <div
                    class="form-check form-switch d-flex align-items-center"
                  ></div>
                  <div class="text-center">
                    <button
                      class="btn bg-gradient-primary w-100 my-4 mb-2"
                      type="button"
                    >
                      Войти
                    </button>
                  </div>
                  <p class="mt-4 text-sm text-center">
                    Еще нет аккаунта?
                    <a
                      class="font-weight-bold"
                      href="https://bookmenow.pro"
                      target="_blank"
                    >
                      Свяжитесь с нами!
                    </a>
                    <!--<router-link-->
                    <!--  to="/authentication/signup"-->
                    <!--  class="text-primary text-gradient font-weight-bold"-->
                    <!--&gt;-->
                    <!--  Зарегистрируйтесь-->
                    <!--</router-link>-->
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.required {
  color: red;
}
.not-authed-background {
  background-color: #458466;
  /* background: url("../../assets/img/app/bmnbackground.jpeg"); */
}
.telegram-button {
  display: inline-block;
  background-color: #0088cc; /* Цвет фона Telegram */
  color: #ffffff; /* Цвет текста */
  padding: 10px 20px; /* Отступы внутри кнопки */
  border-radius: 5px; /* Скругление углов */
  text-decoration: none; /* Убираем стандартное подчеркивание для ссылки */
  font-weight: bold; /* Жирный шрифт */
  font-size: 16px; /* Размер шрифта */
}

.telegram-button i {
  font-size: 24px; /* Размер иконки Telegram */
  margin-right: 10px; /* Отступ между иконкой и текстом */
}

.telegram-button:hover {
  background-color: white; /* Измененный цвет фона при наведении курсора */
}

.alert-no-tg-chat-info {
  background: #fff3cd;
  color: #975400;
}

.alert-no-tg-chat-info .alert-link {
  color: #794300;
}
.customBackground {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Пример тени */
  background-color: #ffbb0d;
}
</style>

<script>
import config from "@/config";
import SmallLoader from "@/views/shared/SmallLoader.vue";
import SelectApp from "@/components/common/SelectApp.vue";

const API_URL_BASE = config.API_URL_BASE;

const body = document.getElementsByTagName("body")[0];

export default {
  name: "signin-signin",

  components: {
    SelectApp,
    SmallLoader,
    // Navbar,
    // AppFooter,
    // VmdInput,
    // VmdCheckbox,
    // VmdButton,
  },

  created() {
    this.reason = this.$route.query.reason;

    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },

  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },

  mounted() {
    this.showBlock = false;
    this.telegramLoginChecks = null;
  },

  data() {
    return {
      reason: null,

      showError: "",
      showNoTgChatIdInfo: false,
      isLoading: false,
      phoneNumber: "",
      email: "",
      password: "",
      tenantName: null,
      showBlock: "",

      availableTenants: [],
    };
  },

  methods: {
    async signin() {
      return await this._signin(`${API_URL_BASE}/authentication/signin`, {
        phoneNumber: this.phoneNumber,
        password: this.password,
        tenantName: this.tenantName,
      });
    },

    async signinTelegram() {
      this.showError = "";
      this.showNoTgChatIdInfo = false;
      this.showBlock = false;

      if (this.telegramLoginChecks) {
        clearInterval(this.telegramLoginChecks);
      }

      const options = {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          phoneNumber: this.phoneNumber,
        }),
      };

      try {
        this.isLoading = true;
        const API_URL = `${API_URL_BASE}/authentication/signinTelegram`;
        const response = await fetch(API_URL, options);
        const result = await response.json();

        if (result.errorCode) {
          this.isLoading = false;
          if (result.errorCode === "no-telegram-chat-id") {
            this.showNoTgChatIdInfo = true;
            return;
          }

          this.showError = result.message;
          return;
        }

        const { chatId, messageDate } = result;
        let retryAmount = 15;
        const CHECK_LOGIN_URL = `${API_URL_BASE}/authentication/checkTelegramLogin?chatId=${chatId}&messageDate=${messageDate}`;

        this.showBlock = true;

        this.telegramLoginChecks = setInterval(async () => {
          const response = await fetch(CHECK_LOGIN_URL, { method: "GET" });
          const result = await response.json();

          if (result.state === "declined") {
            clearInterval(this.telegramLoginChecks);
            this.isLoading = false;
            this.showError = "Запит на авторизацію відхилено в Telegram";
          }

          if (result.state === "confirmed") {
            clearInterval(this.telegramLoginChecks);
            this.isLoading = false;

            const accessToken = result.user.accessToken;
            if (accessToken) {
              localStorage.setItem("accessToken", accessToken);
              window.location.href = "/";
              return;
            }
          }

          retryAmount--;

          if (retryAmount === 0) {
            clearInterval(this.telegramLoginChecks);
            await this.invalidateTelegramLogin(chatId, messageDate);
            this.showError =
              "Перевищено час очікування відповіді в Telegram-боті";
            this.isLoading = false;
          }
        }, 5000);
      } catch (error) {
        this.showError = "Внутрішня помилка. Спробуйте ще раз";
        this.isLoading = false;
      }
    },

    async _signin(API_URL, payload) {
      this.showError = "";
      this.showNoTgChatIdInfo = false;

      const options = {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(payload),
      };

      try {
        this.isLoading = true;
        const response = await fetch(API_URL, options);
        const result = await response.json();

        if (result.errorCode) {
          this.showError = result.message;
          return;
        }

        if (result.multiTenants) {
          this.availableTenants = result.availableTenants;
          return;
        }

        if (result.user) {
          const accessToken = result.user.accessToken;
          if (accessToken) {
            localStorage.setItem("accessToken", accessToken);
            window.location.href = "/";
          }
        }
      } catch (error) {
        this.showError = "Внутрішня помилка. Спробуйте ще раз";
      } finally {
        this.isLoading = false;
      }
    },

    async invalidateTelegramLogin(chatId, messageDate) {
      const API_URL = `${API_URL_BASE}/authentication/invalidateTelegramLogin`;
      try {
        await fetch(API_URL, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ chatId, messageDate }),
        });
      } catch (error) {
        console.warn(error);
      }
    },
  },

  computed: {
    currentYear() {
      return new Date().getFullYear();
    },

    isPhoneNumberEmpty() {
      return !this.phoneNumber;
    },

    isDisabled() {
      return !this.phoneNumber || !this.password;
    },
  },
};
</script>
