const minutesToHumanFormat = (minutes) => {
  if (!minutes) {
    return "-";
  }
  const parsedHours = Math.floor(minutes / 60);
  const parsedMinutes = minutes % 60;
  if (!parsedHours) {
    return `${parsedMinutes} хв`;
  }
  if (!parsedMinutes) {
    return `${parsedHours} г`;
  }
  return `${parsedHours} г ${parsedMinutes} хв`;
};

module.exports = {
  minutesToHumanFormat,
};
