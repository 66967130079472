<template>
  <div class="container-fluid my-3 py-3">
    <div class="row mb-5">
      <div class="col-lg-12">
        <!-- Card Общая информация -->
        <div class="card" id="basic-info">
          <div class="card-header">
            <div class="row">
              <div class="col-9">
                <h6>Журнал змiн</h6>
                <p class="text-sm mb-0">
                  Усi змiни якi були зроблені в адмін панелі.
                </p>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="card-body pt-4 p-3">
              <ul class="list-group">
                <li
                  class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
                >
                  <div class="d-flex flex-column">
                    <h6 class="mb-3 text-sm">Бронь</h6>
                    <span class="mb-2 text-xs"
                      >Користувач:
                      <span class="text-dark font-weight-bold ms-sm-2"
                        >Артем Павлов</span
                      ></span
                    >
                    <span class="mb-2 text-xs"
                      >Бронь:
                      <span class="text-dark font-weight-bold ms-sm-2"
                        >порядоквый номер записи залинкованный на запись</span
                      ></span
                    >
                  </div>
                  <div class="ms-auto text-end">
                    <span class="text-xs">23.06.2022 15:30</span>
                    <!-- <a class="btn btn-link text-danger text-gradient px-3 mb-0" href="javascript:;"><i class="material-icons text-sm me-2">delete</i>Delete</a>
                    <a class="btn btn-link text-dark px-3 mb-0" href="javascript:;"><i class="material-icons text-sm me-2">edit</i>Edit</a> -->
                  </div>
                </li>
                <li
                  class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
                >
                  <div class="d-flex flex-column">
                    <h6 class="mb-3 text-sm">Бронь</h6>
                    <span class="mb-2 text-xs"
                      >Користувач:
                      <span class="text-dark font-weight-bold ms-sm-2"
                        >Артем Павлов</span
                      ></span
                    >
                    <span class="mb-2 text-xs"
                      >Бронь:
                      <span class="text-dark font-weight-bold ms-sm-2"
                        >порядоквый номер записи залинкованный на запись</span
                      ></span
                    >
                    <span class="mb-2 text-xs"
                      >Старе значення:<span
                        class="text-dark ms-sm-2 font-weight-bold"
                        >23.06.2022 13:00</span
                      ></span
                    >
                    <span class="mb-2 text-xs"
                      >Нове значення:<span
                        class="text-dark ms-sm-2 font-weight-bold"
                        >23.06.2022 18:00</span
                      ></span
                    >
                  </div>
                  <div class="ms-auto text-end">
                    <span class="text-xs">23.06.2022 15:30</span>
                    <!-- <a class="btn btn-link text-danger text-gradient px-3 mb-0" href="javascript:;"><i class="material-icons text-sm me-2">delete</i>Delete</a>
                    <a class="btn btn-link text-dark px-3 mb-0" href="javascript:;"><i class="material-icons text-sm me-2">edit</i>Edit</a> -->
                  </div>
                </li>
                <li
                  class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
                >
                  <div class="d-flex flex-column">
                    <h6 class="mb-3 text-sm">Клієнт</h6>
                    <span class="mb-2 text-xs"
                      >Користувач:
                      <span class="text-dark font-weight-bold ms-sm-2"
                        >Артем Павлов</span
                      ></span
                    >
                    <span class="mb-2 text-xs"
                      >Клієнт:
                      <span class="text-dark font-weight-bold ms-sm-2"
                        >порядоквый номер клиента залинкованный на клиента</span
                      ></span
                    >
                  </div>
                  <div class="ms-auto text-end">
                    <span class="text-xs">23.06.2022 15:30</span>
                    <!-- <a class="btn btn-link text-danger text-gradient px-3 mb-0" href="javascript:;"><i class="material-icons text-sm me-2">delete</i>Delete</a>
                    <a class="btn btn-link text-dark px-3 mb-0" href="javascript:;"><i class="material-icons text-sm me-2">edit</i>Edit</a> -->
                  </div>
                </li>
                <li
                  class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
                >
                  <div class="d-flex flex-column">
                    <h6 class="mb-3 text-sm">Клієнт</h6>
                    <span class="mb-2 text-xs"
                      >Користувач:
                      <span class="text-dark font-weight-bold ms-sm-2"
                        >Артем Павлов</span
                      ></span
                    >
                    <span class="mb-2 text-xs"
                      >Клієнт:
                      <span class="text-dark font-weight-bold ms-sm-2"
                        >порядоквый номер записи залинкованный на запись</span
                      ></span
                    >
                    <span class="mb-2 text-xs"
                      >Старе значення:<span
                        class="text-dark ms-sm-2 font-weight-bold"
                      >
                        +380960047047</span
                      ></span
                    >
                    <span class="mb-2 text-xs"
                      >Нове значення<span
                        class="text-dark ms-sm-2 font-weight-bold"
                        >+380961234567</span
                      ></span
                    >
                  </div>
                  <div class="ms-auto text-end">
                    <span class="text-xs">23.06.2022 15:30</span>
                    <!-- <a class="btn btn-link text-danger text-gradient px-3 mb-0" href="javascript:;"><i class="material-icons text-sm me-2">delete</i>Delete</a>
                    <a class="btn btn-link text-dark px-3 mb-0" href="javascript:;"><i class="material-icons text-sm me-2">edit</i>Edit</a> -->
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Card Общая информация finish -->
      </div>
    </div>
  </div>
</template>

<script></script>
