<script setup>
import { computed } from "vue";

const props = defineProps({
  current: {
    type: Number,
    required: true,
  },
  steps: {
    type: Array,
    required: true,
  },
});

const currentStep = computed(() => props.current);
</script>

<template>
  <div class="">
    <div class="steps mb-3 mt-3">
      <div
        v-for="(_, index) of steps"
        :key="index"
        :class="{
          active: index + 1 === currentStep,
          completed: index + 1 < currentStep,
        }"
        class="step"
      >
        <div class="step__row">
          <div class="step__dot"></div>
          <div class="step__content mt-2"></div>
        </div>
      </div>
    </div>
    <div class="step-items">
      <template v-for="(item, index) of steps" :key="index">
        <Transition name="fade">
          <div v-if="currentStep === index + 1">
            <slot :name="`item_${index + 1}`" v-bind="item"></slot>
          </div>
        </Transition>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.steps {
  padding: 0px 0px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.step {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  flex-grow: 1;
  &:last-child {
    .step__content {
      text-align: right;
    }
  }
  &:first-child {
    .step__content {
      text-align: left;
    }
  }
  &__content {
    color: rgba(255, 255, 255, 0.5);
  }
  &__row {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  &::before {
    content: "";
    opacity: 0.5;
    background: black;
    height: 1px;
    position: absolute;
    top: 0;
    left: calc(50% + 8px);
    width: calc(100% - 16px);
    transform: translate(0%, 8px);
    transition: all 0.3s cubic-bezier(0.5, 0.02, 0.13, 0.5);
  }

  &::after {
    content: "";
    opacity: 1;
    background: black;
    height: 1px;
    position: absolute;
    top: 0px;
    left: 50%;
    width: 0;
    transform: translate(0%, 8px);
    transition: all 0.3s cubic-bezier(0.5, 0.02, 0.13, 0.5);
  }

  &:last-child {
    &::after {
      display: none;
    }

    &::before {
      display: none;
    }
  }

  &.completed {
    .step__content {
      color: rgba(255, 255, 255, 1);
    }
    .step__dot {
      opacity: 1;
    }

    &::after {
      width: calc(100% - 8px);
    }
  }

  &.active {
    .step__dot {
      opacity: 1;
      &::before {
        opacity: 1;
      }
    }
  }

  &__dot {
    position: relative;
    cursor: pointer;
    width: 16px;
    height: 16px;
    background: black;
    border-radius: 50%;
    opacity: 0.5;
    transition: all 0.3s cubic-bezier(0.5, 0.02, 0.13, 0.5);
    transform-origin: center;
    &::before {
      content: "";
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 5px black;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 50%;
      opacity: 0;
      transition: all 0.3s cubic-bezier(0.5, 0.02, 0.13, 0.5);
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  height: 0;
  overflow: hidden;
}
</style>
