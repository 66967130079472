import { createApp } from "vue";

import VueGoodTablePlugin from "vue-good-table-next";
import VueQRCodeComponent from "vue-qrcode-component";
import "vue-good-table-next/dist/vue-good-table-next.css";
import "@/assets/scss/table.scss";

import Multiselect from "vue-multiselect/src/Multiselect.vue";

import jsonwebtoken from "jsonwebtoken";

import "@/assets/scss/main.scss";
import App from "./App.vue";
import store from "./store";

import routerGuest from "@/router/guest";
import router from "@/router/internal";

import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";

import MaterialDashboard from "./material-dashboard";

import { createPinia } from "pinia";
const pinia = createPinia();

import api from "@/services/api";

import { useCompanyStore } from "@/storePinia/company";

const rootNumbers = [
  "+380509451432", // Viktor
  "+380960047047", // Artem
  "+380977053138", // Serhii
  "+380932653682", // Vlad
];

async function main() {
  const accessToken = localStorage.getItem("accessToken");

  const appInstance = createApp(App);
  appInstance.component("qr-code", VueQRCodeComponent);
  appInstance.component("multiselect", Multiselect);
  appInstance.use(store);

  appInstance.use(pinia);

  if (accessToken) {
    const decoded = jsonwebtoken.decode(accessToken, {});

    if (!decoded) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("localUser");
      window.location.href = "/";
      return;
    }

    const exp = decoded.exp * 1000;
    const isExpired = Date.now() > exp;

    if (isExpired) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("localUser");
      window.location.href = "/authentication/signin?reason=jwtExpired";
      return;
    }

    const localUser = {
      expiresIn: decoded.exp * 1000,
      phoneNumber: decoded.phoneNumber,
      userId: decoded.userId,
      rolesMap: decoded.rolesMap,
      tenantName: decoded.tenantName,
    };

    localStorage.setItem("localUser", JSON.stringify(localUser));

    const showDevFeatures = rootNumbers.includes(decoded.phoneNumber)
      ? "y"
      : "";
    localStorage.setItem("showDevFeatures", showDevFeatures);

    const storeCompany = useCompanyStore();

    const company = await api.company.show();
    storeCompany.setCompany(company);

    const branch = (await api.branches.list())[0];
    if (branch) {
      storeCompany.setBranch(branch);
    }

    appInstance.use(router);
  } else {
    localStorage.setItem("localUser", "");
    localStorage.setItem("showDevFeatures", "");

    appInstance.use(routerGuest);
  }

  appInstance.use(VueTilt);
  appInstance.use(VueSweetalert2);
  appInstance.use(MaterialDashboard);
  appInstance.use(VueGoodTablePlugin);
  appInstance.mount("#app");
}

main();
