<template>
  <Transition>
    <div v-if="isOpen" class="toast-custom__item" :class="[classToast]">
      <div class="toast-custom__content">
        <i class="fas fa-solid fa-check check"></i>
        <h4 class="toast-custom__title">{{ title }}</h4>
      </div>
      <!-- <button @click="isOpen = false" class="toast-custom__close">
        <img src="@/assets/img/close.svg" alt="Close" />
      </button> -->
      <div class="toast-custom__line"></div>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },

    time: {
      type: Number,
      default: 3000,
    },
    type: {
      type: String,
      default: "success",
    },
  },
  data() {
    return {
      isOpen: true,
      timer: null,
    };
  },
  computed: {
    classToast() {
      return this.type === "error" ? "error" : "success";
    },
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.isOpen = false;
      this.$emit("close-toast");
    }, this.time);
  },
  unmounted() {
    clearTimeout(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.toast-custom__content {
  display: flex;
  align-items: center;
}
.toast-custom {
  .v-enter-active,
  .v-leave-active {
    animation: animate 0.6s linear;
  }

  .toast-custom__title {
    padding-left: 8px;
  }

  .toast-custom__content .check {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    min-width: 20px;
    background-color: #204333;
    color: #fff;
    font-size: 10px;
    border-radius: 50%;
  }

  .v-enter-from,
  .v-leave-to {
    transform: translateX(100%);
  }

  &__item {
    background: #bfdbca;
    color: black;
    overflow: hidden;
    border-radius: 10px;
    padding: 10px 20px; /* Уменьшил правый отступ для автоматической ширины */
    position: relative;
    display: inline-block; /* Сделать ширину зависящей от содержимого */
    max-width: 100%; /* Предотвратить выход за пределы экрана */
    box-sizing: border-box; /* Включить паддинг и бордер в ширину элемента */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Добавление тени */

    &.error {
      background: #ef9a9a;
    }
  }

  &__content {
  }

  &__title {
    color: black;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__close {
    position: absolute;
    top: 7px;
    right: 10px; /* Уменьшил отступ справа, чтобы соответствовать ширине тоста */
    width: 20px; /* Уменьшил размер кнопки */
    height: 20px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0; /* Убрал паддинг */
    border: none;
    background: rgba(255, 255, 255, 0.5);

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__line {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 5px;
    background: rgba(255, 255, 255, 0.3);
    animation: width 2s linear;
  }
}

@keyframes animate {
  0% {
    transform: translateX(-50px);
  }
  20% {
    transform: translateX(0px);
  }
  40% {
    transform: translateX(-20px);
  }
  60% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes width {
  0% {
    width: 100%;
  }
  100% {
    width: 0px;
  }
}
</style>
