<script setup>
import { onMounted, ref } from "vue";

import ButtonApp from "@/components/common/other/ButtonApp.vue";

import api from "@/services/api";

const company = ref({});
const price = ref(0);

onMounted(async () => {
  company.value = await api.company.show();

  const crmMode = company.value.crmMode;
  const quantity = company.value.hardLimits.staffMax;

  switch (crmMode) {
    case "hotel":
      price.value = quantity * 250;
      if (quantity >= 8) {
        price.value = 2000;
      }
      break;
    case "general":
    default:
      price.value = quantity * 150;
      if (quantity >= 8) {
        price.value = 1250;
      }
      break;
  }
});
</script>

<template>
  <div class="card mt-6">
    <div class="card-header">
      <div class="row">
        <div class="col-12 text-center">
          <h4>Вітаємо</h4>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="row text-center">
        <p>
          Дякуємо, що обрали bookmenow! У вас є
          <strong>7 днів безкоштовного тестового періоду</strong>, щоб оцінити
          всі переваги нашого сервісу. Після завершення тестового періоду ви
          зможете продовжити користування, сплативши підписку вартістю
          <strong>{{ price }} грн на місяць</strong>.
        </p>
      </div>
      <div class="row text-center">
        <p>
          Для вашої зручності ми розробили мобільні застосунки, щоб ви могли
          керувати бізнесом у будь-який час та з будь-якого місця
        </p>
        <div class="d-flex justify-content-center align-items-center gap-3">
          <a
            href="https://apps.apple.com/ua/app/bookmenow/id6444885822"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="@/assets/img/AppStore.svg"
              alt="App Store"
              style="max-width: 175px"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.app.bookmenow"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="@/assets/img/GooglePlay.svg"
              alt="Google Play"
              style="max-width: 175px"
            />
          </a>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 text-center">
          <ButtonApp
            to="/beauty/onboarding/welcome"
            :class="'btn-icon bg-gradient-primary'"
          >
            Розпочати
          </ButtonApp>
        </div>
      </div>
    </div>
  </div>
</template>
