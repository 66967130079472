<template>
  <div class="container-fluid my-3 py-3">
    <div class="row mb-5">
      <div class="col-lg-12">
        <!-- Card Общая информация -->
        <div class="card" id="basic-info">
          <div class="card-header">
            <div class="row">
              <div class="col-9">
                <h6>Зарплатня</h6>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="nav-wrapper position-relative end-0">
              <ul class="nav nav-pills nav-fill p-1" role="tablist">
                <li class="nav-item">
                  <a
                    id="nav-profile-tab"
                    class="nav-link mb-0 px-0 py-1 active"
                    data-bs-toggle="tab"
                    href="#nav-profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="true"
                  >
                    Огляд
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link mb-0 px-0 py-1"
                    data-bs-toggle="tab"
                    href="#nav-contact"
                    role="tab"
                    aria-controls="dashboard"
                    aria-selected="false"
                  >
                    Схеми
                  </a>
                </li>
              </ul>
            </div>
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade active show"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <div class="card-body p-1">
                  <div class="col-lg-4">
                    <div class="card h-100">
                      <div class="card-header pb-0 p-3">
                        <ul class="list-group">
                          <li
                            class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                          >
                            <div class="d-flex flex-column">
                              <h6
                                class="mb-1 text-dark font-weight-bold text-sm"
                              >
                                На балансі співробітників
                              </h6>
                              <span class="text-xs"
                                >Загальна заборгованість по всім
                                спісробітникам</span
                              >
                            </div>
                            <div
                              class="d-flex align-items-center text-bold text-danger text-xs"
                            >
                              1732 грн
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="card-body pb-0"></div>
                    </div>
                  </div>
                  <!-- <div
                    class="
                      col-lg-4
                      border-0
                      d-flex
                      p-3
                      mb-2
                      bg-gray-100
                      border-radius-lg
                    "
                    >
                    <div class="d-flex flex-column">
                      <h6 class="mb-3 text-xs">
                        На балансі співробітників:
                        <span class="text-sm text-secondary">13468 грн</span>
                      </h6>
                    </div>
                  </div> -->
                  <div class="table-responsive">
                    <table class="table table-flush text-xs" id="products-list">
                      <thead class="thead-light">
                        <tr>
                          <th>Співробітник</th>
                          <th>Схема зарабітної плати</th>
                          <th>Баланс</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="d-flex">
                              <div class="my-auto form-check">
                                <span class="my-2 text-xs">Артем Павлов</span>
                              </div>
                            </div>
                          </td>
                          <td class="text-sm">
                            <div class="col-5">
                              <button
                                type="button"
                                class="btn btn-outline-secondary btn-sm mb-3 mb-md-0 ms-auto"
                                data-bs-toggle="modal"
                                name="button"
                                data-bs-target="#assignschema"
                              >
                                призначити
                              </button>
                              <!-- Modal -->
                              <div
                                class="modal fade"
                                id="assignschema"
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                              >
                                <div
                                  class="modal-dialog modal-dialog-centered"
                                  role="document"
                                >
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h5
                                        class="modal-title font-weight-normal"
                                        id="exampleModalLabel"
                                      >
                                        Призначити схему: Артем Павлов
                                      </h5>
                                      <button
                                        type="button"
                                        class="btn-close text-dark"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div class="modal-body">
                                      <!-- Operations -->
                                      <div class="row mx-1"></div>
                                      <div
                                        class="input-group input-group-static mb-4"
                                      >
                                        <label
                                          for="exampleFormControlSelect1"
                                          class="ms-0"
                                          >Оберіть схему</label
                                        >
                                        <select
                                          class="form-control"
                                          id="exampleFormControlSelect1"
                                        >
                                          <option>Схема для адмінів</option>
                                          <option>
                                            Схема для співробітників
                                          </option>
                                        </select>
                                      </div>
                                      <div class="row p-1 mt-2">
                                        <div class="col-6">
                                          <label>Починаючи з:</label
                                          ><br />date_picker
                                        </div>
                                        <div class="col-6">
                                          <label>Закінчуючи:</label
                                          ><br />date_picker
                                        </div>
                                      </div>

                                      <!-- Operations finish -->
                                    </div>
                                    <div class="modal-footer">
                                      <button
                                        type="button"
                                        class="btn bg-gradient-secondary"
                                        data-bs-dismiss="modal"
                                      >
                                        закрити
                                      </button>
                                      <button
                                        type="button"
                                        class="btn bg-gradient-primary"
                                      >
                                        Зберегти
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="text-sm">
                            <div class="d-flex">0</div>
                          </td>
                          <td class="text-sm"></td>
                        </tr>
                        <tr>
                          <td>
                            <div class="d-flex">
                              <div class="my-auto form-check">
                                <span class="my-2 text-xs text-dark"
                                  >Вiктор Купцов</span
                                >
                              </div>
                            </div>
                          </td>
                          <td class="text-sm">
                            <div class="col-5">
                              <span class="text-xs text-dark"
                                >Схема для адмінів
                                <button
                                  type="button"
                                  class="btn mb-3 mb-md-0 ms-auto"
                                  data-bs-toggle="modal"
                                  name="button"
                                  data-bs-target="#editschema"
                                >
                                  <i
                                    class="material-icons text-secondary position-relative"
                                    >drive_file_rename_outline</i
                                  >
                                </button>
                                <!-- Modal -->
                                <div
                                  class="modal fade"
                                  id="editschema"
                                  tabindex="-1"
                                  role="dialog"
                                  aria-labelledby="exampleModalLabel"
                                  aria-hidden="true"
                                >
                                  <div
                                    class="modal-dialog modal-dialog-centered"
                                    role="document"
                                  >
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5
                                          class="modal-title font-weight-normal"
                                          id="exampleModalLabel"
                                        >
                                          Редагувати схему: Віктор Купцов
                                        </h5>
                                        <button
                                          type="button"
                                          class="btn-close text-dark"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          <span aria-hidden="true"
                                            >&times;</span
                                          >
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <!-- Operations -->
                                        <div class="row mx-1"></div>
                                        <div
                                          class="input-group input-group-static mb-4"
                                        >
                                          <label
                                            for="exampleFormControlSelect1"
                                            class="ms-0"
                                            >Схема</label
                                          >
                                          <select
                                            class="form-control"
                                            id="exampleFormControlSelect1"
                                          >
                                            <option>Схема для адмінів</option>
                                            <option>
                                              Схема для співробітників
                                            </option>
                                          </select>
                                        </div>
                                        <div class="row p-1 mt-2">
                                          <div class="col-6">
                                            <label>Починаючи з:</label><br />
                                            date_picker (рекваеред)
                                          </div>
                                          <div class="col-6">
                                            <label>Закінчуючи:</label>
                                            <br />date_picker (не рекваеред)
                                          </div>
                                        </div>
                                        <!-- Operations finish -->
                                      </div>
                                      <div class="modal-footer">
                                        <button
                                          type="button"
                                          class="btn bg-gradient-secondary"
                                          data-bs-dismiss="modal"
                                        >
                                          закрити
                                        </button>
                                        <button
                                          type="button"
                                          class="btn bg-gradient-primary"
                                        >
                                          Зберегти
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </div>
                          </td>
                          <td class="text-sm text-center">
                            <div class="d-flex">346 грн</div>
                          </td>
                          <td class="text-sm">
                            <router-link to="/team/salaryinfo/" class="btn">
                              <i class="fas fa-eye text-secondary"></i>
                            </router-link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="nav-contact"
                role="tabpanel"
                aria-labelledby="nav-contact-tab"
              >
                <div class="card-body p-3">
                  <div class="table-responsive">
                    <router-link
                      to="/team/salary-addschema/"
                      class="btn btn-icon bg-gradient-primary"
                    >
                      + схема
                    </router-link>
                    <table class="table table-flush text-xs" id="products-list">
                      <thead class="thead-light">
                        <tr>
                          <th>Назва</th>
                          <th>Користувачі</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="d-flex">
                              <div class="my-auto form-check text-dark">
                                <span class="my-2 text-xs"
                                  >Схема для адмінів</span
                                >
                              </div>
                            </div>
                          </td>
                          <td class="text-sm">
                            <div class="col-5">
                              <span class="text-xs text-dark"
                                >Павлов Артем
                              </span>
                              <br />
                              <span class="text-xs text-dark"
                                >Виктор Купцов
                              </span>
                            </div>
                          </td>
                          <td class="text-sm">
                            <div class="d-flex">
                              <!-- <a
                              href="javascript:;"
                              data-bs-toggle="tooltip"
                              data-bs-original-title="Посмотреть"
                              >
                              <i class="fas fa-eye text-secondary"></i>
                            </a> -->
                              <i
                                class="material-icons text-secondary position-relative"
                                >drive_file_rename_outline</i
                              >
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="d-flex">
                              <div class="my-auto form-check">
                                <span class="my-2 text-xs text-dark"
                                  >Схема для співробітників</span
                                >
                              </div>
                            </div>
                          </td>
                          <td class="text-sm">
                            <div class="col-5">
                              <span class="text-xs text-dark"
                                >Немає співробітників
                              </span>
                            </div>
                          </td>
                          <td class="text-sm text-center">
                            <div class="d-flex">
                              <i
                                class="material-icons text-secondary position-relative"
                                >drive_file_rename_outline</i
                              >
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Card Общая информация finish -->
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";

export default {
  name: "salary",
  data() {
    return {};
  },
  mounted() {
    setNavPills();
  },
};
</script>
