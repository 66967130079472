<template>
  <!-- NOW MVP -->
  <div class="wrapper-page-analytics container-fluid py-4" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="wrapper-page-analytics container-fluid py-4" v-show="dataLoaded">
    <div class="row">
      <div class="col">
        <div class="dropdown d-inline">
          <a
            href="javascript:;"
            class="btn btn-sm btn-outline-dark dropdown-toggle"
            data-bs-toggle="dropdown"
            id="navbarDropdownMenuLink2"
          >
            🗓 {{ displaySelectedDateRange() }}
          </a>
          <ul
            class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
            aria-labelledby="navbarDropdownMenuLink2"
            data-popper-placement="left-start"
          >
            <li v-for="(dateRange, index) in listDateRanges" :key="index">
              <button
                class="dropdown-item border-radius-md"
                @click="filterByDateRange(dateRange._id)"
              >
                {{ dateRange.name }}
              </button>
            </li>
            <li>
              <hr class="horizontal dark my-2" />
            </li>
          </ul>
        </div>
      </div>
      <div class="col">
        <div class="dropdown d-inline">
          <a
            href="javascript:;"
            class="btn btn-sm btn-outline-dark dropdown-toggle"
            data-bs-toggle="dropdown"
            id="navbarDropdownMenuLink2"
          >
            {{ displaySelectedPosition() }}
          </a>
          <ul
            class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
            aria-labelledby="navbarDropdownMenuLink2"
            data-popper-placement="left-start"
          >
            <li v-for="(position, index) in listPositions" :key="index">
              <button
                class="dropdown-item border-radius-md"
                @click="filterByPositionId(position._id)"
              >
                {{ position.name }}
              </button>
            </li>
            <li>
              <hr class="horizontal dark my-2" />
            </li>
          </ul>
        </div>
      </div>
      <div class="col">
        <div class="dropdown d-inline">
          <a
            href="javascript:;"
            class="btn btn-sm btn-outline-dark dropdown-toggle"
            data-bs-toggle="dropdown"
            id="navbarDropdownMenuLink2"
          >
            {{ displaySelectedStaff() }}
          </a>
          <ul
            class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
            aria-labelledby="navbarDropdownMenuLink2"
            data-popper-placement="left-start"
          >
            <li v-for="(singleStaff, index) in listStaff" :key="index">
              <button
                class="dropdown-item border-radius-md"
                :class="{ 'bmn-staff-removed': singleStaff.isRemoved }"
                href="javascript:;"
                @click="filterByStaffId(singleStaff._id)"
              >
                {{ singleStaff.name }}
                <span v-if="singleStaff.positionId"
                  >({{ singleStaff.positionId.name }})</span
                >
                <span v-if="singleStaff._id && !singleStaff.isActive">
                  ❌
                </span>
              </button>
            </li>
            <li>
              <hr class="horizontal dark my-2" />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row"></div>
    <div class="row" v-show="selectedDateRange === 'custom'">
      <div class="col-lg-3 col-sm-12 text-end">
        <flat-pickr
          v-model="customSelectedDateRange"
          id="calendarFlatpickr"
          class="form-control datetimepicker"
          :config="calendarConfig"
          ref="datePickerWrap"
        ></flat-pickr>
        <div class="mb-4"></div>
      </div>
    </div>
    <div class="row">
      <h5>
        Завантаженість
        <div class="badge badge-info badge-sm mx-3">
          {{ computedSelectedDateRange }}
        </div>
      </h5>
    </div>
    <div class="row mb-4">
      <div class="row mt-3">
        <div class="col-lg-4 col-md-6 col-sm-6 mt-lg-0 mt-2">
          <div class="card mb-2">
            <div class="card-header p-3 pt-2">
              <div
                class="icon icon-lg icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-xl mt-n4 position-absolute"
              >
                <i class="material-icons opacity-10">leaderboard</i>
              </div>
              <div class="text-end pt-1">
                <p class="text-sm mb-0 text-capitalize">
                  Завантаженість
                  <a
                    href="javascript:;"
                    class="mx-3"
                    v-if="company.crmMode === 'hotel'"
                    data-bs-toggle="tooltip"
                    data-bs-original-title="Сума днів на які є бронювання зі статусом 'Клієнт відвідав' віднесена до кількості днів у обраному періоді."
                  >
                    <i
                      class="material-icons text-secondary position-relative text-lg"
                      >info</i
                    >
                  </a>
                  <a
                    href="javascript:;"
                    class="mx-3"
                    v-if="company.crmMode !== 'hotel'"
                    data-bs-toggle="tooltip"
                    data-bs-original-title="Сума годин на які є записи зі статусом 'Клієнт прийшов' віднесена до сами робочіх годин встановлинех в графіку роботи."
                  >
                    <i
                      class="material-icons text-secondary position-relative text-lg"
                      >info</i
                    >
                  </a>
                  <!-- <a href="javascript:;" class="mx-3" data-bs-toggle="tooltip" data-bs-original-title="Суммарная продолжительность записей со статусом 'Выполнена' отнесенная к суммарной продолжительности рабочего дня сотрудников. Процент показывает динамику в сравнении с аналогичным по длительности предшествующим периодом.">
                      <i class="material-icons text-secondary position-relative text-lg">info</i>
                    </a> -->
                </p>
                <h4 class="mb-0">{{ metrics.staffLoadOverall }} %</h4>
              </div>
            </div>
            <hr class="dark horizontal my-0" />
            <div class="card-footer p-2" v-if="showDevFeatures"></div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mt-4 mb-4">
        <div
          class="wrapper-page-analytics container-fluid py-4"
          v-if="!chartsDataLoaded.loadByStaff"
        >
          <Spinner :loaded="chartsDataLoaded.loadByStaff"></Spinner>
        </div>

        <div class="card z-index-2" v-if="chartsDataLoaded.loadByStaff">
          <Line
            :chart-data="charts.loadByStaff.data"
            :height="300"
            :chart-options="charts.loadByStaff.options"
          />
        </div>
      </div>
    </div>

    <!-- <div class="row">
        <h5>
          Середній чек
          <div class="badge badge-info badge-sm mx-3">
            {{ computedSelectedDateRange }}
          </div>
        </h5>
      </div>
      <div class="row mb-4">
        <div class="col-lg-12 mt-4 mb-4">
          <div class="card z-index-2">
            <div
              class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent"
            >
              <div
                class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1"
              >
                <div class="chart">
                  <canvas
                    id="chart-line"
                    class="chart-canvas"
                    height="170"
                  ></canvas>
                </div>
              </div>
            </div>
            <div class="card-body">
              <h6 class="mb-0">Помісячна середній чек</h6>
            </div>
          </div>
        </div>
      </div> -->
  </div>
</template>

<style scoped>
.wrapper-page-analytics >>> .flatpickr-input {
  color: #095bc6;
  background-color: #aecef7;
  border: 0;
  border-radius: 0.375rem;
  text-align: center;
  padding: 10px;
  font-weight: 700;
}

.bmn-staff-removed {
  text-decoration: line-through;
  opacity: 0.7;
}

.parent-container-no-data {
  height: 400px;
}

.vertical-center {
  font-size: x-large;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>

<script>
import { DateTime } from "luxon";
import * as _ from "lodash";
import Spinner from "@/views/shared/Spinner.vue";

import setTooltip from "@/assets/js/tooltip.js";
import api from "@/services/api";
import constants from "@/constants";

import flatPickr from "vue-flatpickr-component";
import { Ukrainian } from "flatpickr/dist/l10n/uk";

import MonthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";

import { Line } from "vue-chartjs";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  registerables,
} from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend, ...registerables);

const paletteColors = [
  "#1A73E8",
  "#4CAF50",
  "#D6EAFC",
  "#FFFFDF",
  "#D3C4E3",
  "#B8DEDB",
  "#D3C4E3",
  "GoldenRod",
  "Lavender",
  "LavenderBlush",
  "LightBlue",
  "LightGoldenRodYellow",
  "NavajoWhite",
  "PaleGreen",
  "PaleTurquoise",
  "PapayaWhip",
  "PeachPuff",
  "Pink",
  "Plum",
  "RosyBrown",
  "Salmon",
  "SkyBlue",
  // "#F47A1F",
  // "#FDBB2F",
  // "#377B2B",
  // "#7AC142",
  // "#007CC3",
  // "#00529B",
  // "#6050DC",
  // "#D52DB7",
  // "#FF2E7E",
  // "#FF6B45",
  // "#FFAB05",
  // "#E6F69D",
  // "#AADEA7",
  // "#64C2A6",
  // "#2D87BB",
];

const getPeriod = (name) => {
  const period = {
    dateFrom: null,
    dateTo: null,
  };

  switch (name) {
    //   case "allTime":
    //     period.dateFrom = null;
    //     period.dateTo = null;
    //     break;

    case "currentWeek":
      period.dateFrom = DateTime.now().startOf("week").toISODate();
      period.dateTo = DateTime.now().endOf("week").toISODate();
      break;

    case "previousWeek":
      period.dateFrom = DateTime.now()
        .minus({ week: 1 })
        .startOf("week")
        .toISODate();
      period.dateTo = DateTime.now()
        .minus({ week: 1 })
        .endOf("week")
        .toISODate();
      break;

    case "currentMonth":
      period.dateFrom = DateTime.now().startOf("month").toISODate();
      period.dateTo = DateTime.now().endOf("month").toISODate();
      break;

    case "nextMonth":
      period.dateFrom = DateTime.now()
        .plus({ month: 1 })
        .startOf("month")
        .toISODate();
      period.dateTo = DateTime.now()
        .plus({ month: 1 })
        .endOf("month")
        .toISODate();
      break;

    case "previousMonth":
      period.dateFrom = DateTime.now()
        .minus({ month: 1 })
        .startOf("month")
        .toISODate();
      period.dateTo = DateTime.now()
        .minus({ month: 1 })
        .endOf("month")
        .toISODate();
      break;

    case "currentYear":
      period.dateFrom = DateTime.now().startOf("year").toISODate();
      period.dateTo = DateTime.now().endOf("year").toISODate();
      break;

    case "previousYear":
      period.dateFrom = DateTime.now()
        .minus({ year: 1 })
        .startOf("year")
        .toISODate();
      period.dateTo = DateTime.now()
        .minus({ year: 1 })
        .endOf("year")
        .toISODate();
      break;

    case "monthToDate": // MTD
      period.dateFrom = DateTime.now().startOf("month").toISODate();
      period.dateTo = DateTime.now().toISODate();
      break;

    case "yearToDate": // YTD
      period.dateFrom = DateTime.now().startOf("year").toISODate();
      period.dateTo = DateTime.now().toISODate();
      break;
  }

  return period;
};

export default {
  name: "ecommerce-analytics",

  components: {
    flatPickr,
    Line,
    Spinner,
  },

  data() {
    return {
      dataLoaded: false,
      showDevFeatures: false,

      company: {
        crmMode: "",
        localizationMode: "",
      },

      selectedDateRange: "currentMonth",
      listDateRanges: [
        {
          _id: "previousMonth",
          name: "Попередній місяць",
        },
        {
          _id: "currentMonth",
          name: "Поточний місяць",
        },
        {
          _id: "nextMonth",
          name: "Наступний місяць",
        },
        {
          _id: "currentWeek",
          name: "Поточний тиждень",
        },
        {
          _id: "previousWeek",
          name: "Попередній тиждень",
        },
        {
          _id: "currentYear",
          name: "Поточний рік",
        },
        {
          _id: "previousYear",
          name: "Попередній рік",
        },
        {
          _id: "monthToDate",
          name: "Поточний місяць (до сьогодні)",
        },
        {
          _id: "yearToDate",
          name: "Поточний рік (до сьогодні)",
        },
        {
          _id: "custom",
          name: "Довільні дати",
        },
      ],
      customSelectedDateRange: null,
      calendarConfig: {
        mode: "range",
        dateFormat: "d-m-Y",
        locale: Ukrainian,
        inline: true,
        disableMobile: true,
        onChange: async (selectedDates) => {
          if (_.isArray(selectedDates) && selectedDates.length === 2) {
            this.selectedDateFrom = DateTime.fromJSDate(
              selectedDates[0]
            ).toISODate();
            this.selectedDateTo = DateTime.fromJSDate(
              selectedDates[1]
            ).toISODate();
            await this.fetchData();
          }
        },
      },

      selectedClientsPeriodFrom: null,
      selectedClientsPeriodTo: null,
      clientsPeriod: null,
      calendarConfigClients: {
        defaultDate: Date.now(),
        plugins: [
          new MonthSelectPlugin({
            shorthand: false, //defaults to false
            dateFormat: "🗓 F Y", //defaults to "F Y"
            altFormat: "🗓 F Y", //defaults to "F Y"
            theme: "light", // defaults to "light"
          }),
        ],
        locale: Ukrainian,
        inline: false,
        disableMobile: true,
        onChange: async (selectedDates) => {
          if (!_.isArray(selectedDates) || selectedDates.length !== 1) {
            selectedDates = [new Date()];
          }

          this.selectedClientsPeriodFrom = DateTime.fromJSDate(selectedDates[0])
            .startOf("month")
            .toISODate();
          this.selectedClientsPeriodTo = DateTime.fromJSDate(selectedDates[0])
            .endOf("month")
            .toISODate();

          const todayDate = DateTime.now().toISODate();
          if (
            DateTime.fromISO(todayDate).startOf("month").toISODate() ===
            this.selectedClientsPeriodFrom
          ) {
            // exception: current month -> to current date
            this.selectedClientsPeriodTo = todayDate;
          }

          await this.fetchDataClients();
        },
        maxDate: Date.now(),
      },

      selectedPositionId: null,
      listPositions: [
        {
          _id: null,
          name: "Всі категорії",
          positionId: null,
        },
      ],

      selectedStaffId: null,
      listStaff: [
        {
          _id: null,
          name: "Всі",
          positionId: null,
        },
      ],

      selectedDateFrom: null,
      selectedDateTo: null,

      metrics: {
        serviceOrdersIncomeSum: null,
        serviceOrdersIncomeMean: null,
        serviceOrdersCountersTotal: null,
        serviceOrdersCountersNew: null,
        serviceOrdersCountersDone: null,
        serviceOrdersCountersCancelled: null,
        serviceOrdersCountersByChannel: {
          labels: [],
          datasets: [],
        },
        serviceOrdersCountersByStaff: {
          labels: [],
          datasets: [],
        },

        staffLoadOverall: null,

        clientsNew: null,
        clientsReturned: null,
        clientsLost: null,
        clientsTotal: null,
      },

      chartsDataLoaded: {
        loadByStaff: false,
      },
      charts: {
        loadByStaff: {
          data: {
            labels: [],
            datasets: [
              {
                backgroundColor: paletteColors,
                data: [],
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                ticks: {
                  maxRotation: 0,
                  minRotation: 0,
                },
              },
              y: {
                min: 0,
                max: 100,
                ticks: {
                  stepSize: 10,
                },
              },
            },
          },
        },
      },
    };
  },

  async created() {
    this.company = await api.company.show();

    this.listStaff[0].name = this.getLabelStaff();
    this.listPositions[0].name = this.getLabelPosition();

    // TODO: set includeRemoved to 1 to include removed staffs
    const staff = await api.staff.list({ includeRemoved: 0 });
    const positions = await api.positions.list();

    this.listStaff = [...this.listStaff, ...staff];
    this.listPositions = [...this.listPositions, ...positions];

    const defaultPeriod = getPeriod("currentMonth");
    this.selectedDateFrom = defaultPeriod.dateFrom;
    this.selectedDateTo = defaultPeriod.dateTo;

    await this.fetchData();
  },

  async mounted() {
    this.showDevFeatures = !!localStorage.getItem("showDevFeatures");
    setTimeout(setTooltip, 200);
    await this.calendarConfigClients.onChange();
    this.dataLoaded = true;
  },

  methods: {
    getLabelStaff() {
      const localizationMode = this.company.localizationMode;

      switch (true) {
        case localizationMode === "spa":
          return "Всі ресурси";
        case localizationMode === "hotel":
          return "Всі номери";
        default:
          return "Всі майстри";
      }
    },

    getLabelPosition() {
      const localizationMode = this.company.localizationMode;

      switch (true) {
        case localizationMode === "spa":
        case localizationMode === "hotel":
          return "Всі категорії";
        default:
          return "Всі посади";
      }
    },

    async fetchData() {
      this.chartsDataLoaded.loadByStaff = false;

      const payload = {
        scope: "general",
        staffId: this.selectedStaffId,
        positionId: this.selectedPositionId,
      };

      if (this.selectedDateFrom) {
        payload.dateFrom = this.selectedDateFrom;
      }

      if (this.selectedDateTo) {
        payload.dateTo = this.selectedDateTo;
      }

      const metrics = await api.analytics.list(payload);

      this.charts.loadByStaff.data = await api.analytics.getStaffLoad(payload);

      this.metrics = {
        ...this.metrics,
        ...metrics,
      };

      this.chartsDataLoaded.loadByStaff = true;
    },

    async fetchDataClients() {
      const payload = {
        scope: "clients",
        dateFrom: this.selectedClientsPeriodFrom,
        dateTo: this.selectedClientsPeriodTo,
        staffId: this.selectedStaffId,
        positionId: this.selectedPositionId,
      };

      const metrics = await api.analytics.list(payload);
      this.metrics = {
        ...this.metrics,
        ...metrics,
      };
    },

    async filterByDateRange(dateRange) {
      this.selectedDateRange = dateRange;

      const rangeValue = getPeriod(dateRange);
      this.selectedDateFrom = rangeValue.dateFrom;
      this.selectedDateTo = rangeValue.dateTo;

      if (dateRange !== "custom") {
        this.$refs.datePickerWrap.fp.clear();
        await this.fetchData();
      }
    },

    displaySelectedDateRange() {
      const _dateRange = this.listDateRanges.find((element) => {
        return element._id === this.selectedDateRange;
      });
      return _dateRange.name;
    },
    async filterByPositionId(positionId) {
      this.selectedPositionId = positionId;
      await this.fetchData();
      await this.fetchDataClients();
    },
    displaySelectedPosition() {
      const _position = this.listPositions.find((element) => {
        return element._id === this.selectedPositionId;
      });
      return _position.name;
    },
    async filterByStaffId(staffId) {
      this.selectedStaffId = staffId;
      await this.fetchData();
      await this.fetchDataClients();
    },
    displaySelectedStaff() {
      const _staff = this.listStaff.find((element) => {
        return element._id === this.selectedStaffId;
      });

      const suffix = _staff._id && !_staff.isActive ? "❌" : "";

      if (_staff.positionId) {
        return `${_staff.name} (${_staff.positionId.name}) ${suffix}`;
      }
      return `${_staff.name} ${suffix}`;
    },
  },

  computed: {
    computedSelectedDateRange() {
      // if (this.selectedDateRange === "allTime") {
      //   return "За весь час";
      // }

      let from = "(Дата з)";
      let to = "(Дата до)";
      if (this.selectedDateFrom) {
        from = DateTime.fromISO(this.selectedDateFrom)
          .setLocale("uk-UA")
          .toFormat("dd.MM.yyyy");
      }
      if (this.selectedDateTo) {
        to = DateTime.fromISO(this.selectedDateTo)
          .setLocale("uk-UA")
          .toFormat("dd.MM.yyyy");
      }
      return `${from} - ${to}`;
    },
  },
};
</script>
