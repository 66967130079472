<template>
  <div class="fixed-plugin">
    <div class="shadow-lg card" :class="{ active: props.isOpen }">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="float-start">
          <h5 class="mt-3 mb-0">Фільтр</h5>
        </div>
        <div class="mt-4 float-end" @click="emit('close')">
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="material-icons">clear</i>
          </button>
        </div>
        <!-- End Toggle Button -->
      </div>
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable*/
const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["close"]);
</script>
<style scoped>
.shadow-lg.card.active {
  right: 0;
}
</style>
