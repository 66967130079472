<template>
  <div class="card mt-2">
    <div class="card-body">
      <!-- Клієнт -->
      <div class="row mt-2">
        <div class="col-lg-3">
          <h6>Клієнт</h6>
        </div>
        <div class="col-lg-9">
          <div class="row">
            <div class="col-lg-9">
              <div class="row">Додати клієнта</div>
            </div>
          </div>
        </div>
        <hr />
      </div>

      <!-- Клієнт finish -->

      <!-- Абонемент -->
      <div class="row">
        <div class="col-lg-3">
          <h6>Абонемент</h6>
        </div>
        <div class="col-lg-9">
          <div class="col-lg-4">
            <div class="row">
              <div class="">
                <div class="dropdown d-inline">
                  <a
                    href="javascript:;"
                    class="btn btn-sm btn-outline-dark dropdown-toggle"
                    data-bs-toggle="dropdown"
                    id="navbarDropdownMenuLink2"
                  >
                    Оберіть абонемент
                  </a>
                  <ul
                    class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                    aria-labelledby="navbarDropdownMenuLink2"
                    data-popper-placement="left-start"
                  >
                    <li>
                      <a
                        class="dropdown-item border-radius-md"
                        href="javascript:;"
                        >Гітара группове 12 відвідувань</a
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item border-radius-md"
                        href="javascript:;"
                        >Гітара группове 8 відвідувань</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
      <!-- Абонемент finish -->
      <div class="row">
        <div class="col-3 mt-2">
          <h6>Оплата</h6>
        </div>
        <div class="col-lg-9 mt-2">
          <div class="row">
            <div class="col-lg-6 span text-sm">
              <div>
                До сплати:
                <text class="text-bold">3124124 грн</text>
              </div>
              <div>
                Сплачено:
                <text class="text-success text-bold">3123 грн </text>
              </div>
              <div>
                Залишилось до сплати:
                <text class="text-danger text-bold">3123 грн </text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-lg-3 mt-2">
          <h6>Додати оплату</h6>
        </div>
        <div class="col-lg-9">
          <div class="row">
            <div class="col-lg-3 mt-2 span text-sm">
              <div class="input-group input-group-outline paymentInput">
                <label class="form-label">Сума грн.</label>
                <input type="number" class="form-control text-xs" />
              </div>
            </div>
            <div class="col-lg-9">
              <div class="row mt-2">
                <div class="col-lg-2">
                  <button
                    class="btn btn-icon btn-3 btn-outline-secondary"
                    style="width: 110px; padding-left: 5px; padding-right: 5px"
                    type="button"
                  >
                    <span class="btn-inner--icon"
                      ><i class="material-icons">payments</i></span
                    >
                    <span class="btn-inner--text"> Готівка</span>
                  </button>
                </div>
                <div class="col-lg-2">
                  <button
                    class="btn btn-outline-secondary"
                    style="width: 110px; padding-left: 5px; padding-right: 5px"
                    type="button"
                  >
                    <span class="btn-inner--icon"
                      ><i class="material-icons">credit_card</i></span
                    >
                    <span class="btn-inner--text"> На картку</span>
                  </button>
                </div>
                <div class="col-lg-2">
                  <button
                    class="btn btn-icon btn-3 btn-outline-secondary"
                    style="width: 110px; padding-left: 5px; padding-right: 5px"
                    type="button"
                  >
                    <span class="btn-inner--icon"
                      ><i class="material-icons">receipt_long</i></span
                    >
                    <span class="btn-inner--text"> monobank</span>
                  </button>
                </div>
                <div class="col-lg-2">
                  <button
                    class="btn btn-icon btn-3 btn-outline-secondary"
                    style="width: 110px; padding-left: 5px; padding-right: 5px"
                    type="button"
                  >
                    <span class="btn-inner--icon"
                      ><i class="material-icons">receipt_long</i></span
                    >
                    <span class="btn-inner--text">LiqPay</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="table-responsive">
          <table class="table table-hover" id="datatable-search">
            <thead class="thead-light">
              <tr>
                <th
                  class="text-uppercase text-xxs font-weight-bolder tablepayment"
                >
                  Дата
                </th>
                <th
                  class="text-uppercase text-xxs font-weight-bolder tablepayment"
                >
                  Вид оплати
                </th>
                <th
                  class="text-uppercase text-xxs font-weight-bolder tablepayment"
                >
                  Автор
                </th>
                <th
                  class="text-uppercase text-xxs font-weight-bolder tablepayment"
                >
                  Сума (грн)
                </th>
                <th
                  class="text-uppercase text-xxs font-weight-bolder tablepayment"
                >
                  Статус
                </th>
                <th
                  class="text-uppercase text-xxs font-weight-bolder tablepayment"
                >
                  Посилання
                </th>
                <th
                  class="text-uppercase text-xxs font-weight-bolder tablepayment"
                >
                  Дія
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-xs font-weight-normal tablepayment">
                  <span></span>
                </td>
                <td class="text-xs font-weight-normal tablepayment">
                  <span>Готівка</span>
                  <span>Картка</span>
                  <span>Онлайн</span>
                </td>
                <td class="text-xs font-weight-normal tablepayment">
                  <span>Клієнт</span>
                  <span></span>
                </td>
                <td class="text-xs font-weight-normal tablepayment">
                  <span>1321 грн</span>
                </td>
                <td class="text-xs font-weight-normal">
                  <span class="badge badge-dot">
                    <i class="bg-info"></i>
                    <span class="text-dark text-xs">Очікує</span>
                  </span>
                  <br />
                  <span class="badge badge-dot">
                    <i class="bg-success"></i>
                    <span class="text-dark text-xs">Сплачено</span>
                  </span>
                  <br />
                  <span class="badge badge-dot">
                    <i class="bg-warning"></i>
                    <span class="text-dark text-xs">Помилка</span>
                  </span>
                  <br />
                  <span class="badge badge-dot">
                    <i class="bg-warning"></i>
                    <span class="text-dark text-xs">Відмінено</span>
                  </span>
                  <br />
                  <span class="badge badge-dot">
                    <i class="bg-warning"></i>
                    <span class="text-dark text-xs">Прострочено</span>
                  </span>
                </td>
                <td class="text-xs font-weight-normal tablepayment">
                  <div>
                    <span> </span>
                    <i class="material-icons text-lg me-2 cursor-pointer">
                      copy
                    </i>
                  </div>
                </td>
                <td class="text-xs font-weight-normal tablepayment">
                  <div class="btn-group gap-3">
                    <span
                      data-bs-toggle="modal"
                      class="d-inline-flex cursor-pointer"
                    >
                      <i class="material-icons"> qr_code_scanner</i>
                    </span>
                    <div
                      class="d-inline-flex align-items-center justify-content-center"
                    >
                      <i
                        class="material-icons text-lg me-2 cursor-pointer"
                        data-bs-toggle="modal"
                      >
                        delete
                      </i>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="card mt-2">
    <div class="card-body">
      <!-- Історія використання абонемента -->
      <div class="row">
        <div class="col-lg-3 mt-2">
          <h6>Історія використання</h6>
        </div>
        <div class="col-lg-9">
          <div class="row">
            <table class="table table-flush text-xs" id="products-list">
              <thead class="thead-light">
                <tr>
                  <th class="text-center">Запис</th>
                  <th class="text-center">Послуга</th>

                  <th class="text-center">Залишок абонементу</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">
                    <span>01.02.2023 14:00</span>
                  </td>
                  <td class="text-center">
                    <span>Гітара групове</span>
                  </td>
                  <td class="text-center">
                    <span>12</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-center">
                    <span>03.02.2023 14:00</span>
                  </td>
                  <td class="text-center">
                    <span>Гітара групове</span>
                  </td>
                  <td class="text-center">
                    <span>11</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-center">
                    <span>03.02.2023 14:00</span>
                  </td>
                  <td class="text-center">
                    <span>Гітара групове</span>
                  </td>
                  <td class="text-center">
                    <span>10</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-center">
                    <span>03.02.2023 14:00</span>
                  </td>
                  <td class="text-center">
                    <span>Гітара групове</span>
                  </td>
                  <td class="text-center">
                    <span>9</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-center">
                    <span>03.02.2023 14:00</span>
                  </td>
                  <td class="text-center">
                    <span>Гітара групове</span>
                  </td>
                  <td class="text-center">
                    <span>8</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-center">
                    <span>03.02.2023 14:00</span>
                  </td>
                  <td class="text-center">
                    <span>Гітара групове</span>
                  </td>
                  <td class="text-center">
                    <span>7</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-center">
                    <span>03.02.2023 14:00</span>
                  </td>
                  <td class="text-center">
                    <span>Гітара групове</span>
                  </td>
                  <td class="text-center">
                    <span>6</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-center">
                    <span>03.02.2023 14:00</span>
                  </td>
                  <td class="text-center">
                    <span>Гітара групове</span>
                  </td>
                  <td class="text-center">
                    <span>5</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-center">
                    <span>03.02.2023 14:00</span>
                  </td>
                  <td class="text-center">
                    <span>Гітара групове</span>
                  </td>
                  <td class="text-center">
                    <span>4</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-center">
                    <span>03.02.2023 14:00</span>
                  </td>
                  <td class="text-center">
                    <span>Гітара групове</span>
                  </td>
                  <td class="text-center">
                    <span>3</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-center">
                    <span>03.02.2023 14:00</span>
                  </td>
                  <td class="text-center">
                    <span>Гітара групове</span>
                  </td>
                  <td class="text-center">
                    <span>2</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-center">
                    <span>03.02.2023 14:00</span>
                  </td>
                  <td class="text-center">
                    <span>Гітара групове</span>
                  </td>
                  <td class="text-center">
                    <span>1</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-center">
                    <span>03.02.2023 14:00</span>
                  </td>
                  <td class="text-center">
                    <span>Гітара групове</span>
                  </td>
                  <td class="text-center">
                    <span>0</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Історія використання абонемента finish-->
    </div>
  </div>
</template>

<script></script>

<style scoped></style>
