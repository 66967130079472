<script setup>
import { ref, onMounted, computed } from "vue";
import api from "@/services/api";
import SwitcherApp from "@/components/common/inputs/SwitcherApp.vue";
import ButtonApp from "@/components/common/other/ButtonApp.vue";
import SharedSpinner from "@/views/shared/Spinner.vue";

const props = defineProps({
  nameField: {
    type: String,
    required: true,
  },
  amenities: { type: Array, required: true },
});
const emit = defineEmits(["nextStep"]);

const amenities = ref([]);
const enabledAmenities = computed(() => {
  return amenities.value.reduce((acc, current) => {
    return [...acc, ...current.items.filter((item) => item.enabled)];
  }, []);
});

const isLoading = ref(false);

onMounted(async () => {
  try {
    isLoading.value = true;
    const res = await api.staff.getDefaultAmenities();
    if (res) {
      amenities.value = mappingAmenities(res);
    }
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
});

function mappingAmenities(amenities) {
  let newArray = [];
  if (!amenities) {
    return newArray;
  }
  if (!props.amenities.length > 0) {
    for (let key in amenities) {
      newArray.push({
        id: key,
        title: amenities[key][0].sectionName,
        items: amenities[key],
      });
    }
  } else {
    for (let key in amenities) {
      newArray.push({
        id: key,
        title: amenities[key][0].sectionName,
        items: amenities[key].map((item) => {
          const findIndex = props.amenities.findIndex(
            (itemF) => itemF === item.uid
          );
          if (findIndex > -1) {
            return { ...item, enabled: true };
          }
          return item;
        }),
      });
    }
  }
  return newArray;
}

function nextStep() {
  emit("nextStep", enabledAmenities.value, props.nameField);
}
</script>
<template>
  <div class="">
    <div class="row">
      <div class="col-12 text-center">
        <h4>Зручності</h4>
        <p>Повідомте гостям, що ви пропонуєте, як елементи сервісу</p>
      </div>
    </div>

    <SharedSpinner v-if="isLoading" :variant="'small'" />
    <div v-if="amenities && !isLoading" class="card-body pt-0">
      <div class="mb-4" v-for="item of amenities" :key="item.id">
        <div class="row">
          <div class="col-12 text-center">
            <h4>{{ item.title }}</h4>
          </div>
        </div>
        <div class="row mx-auto">
          <div class="col-lg-1"></div>
          <div class="col-lg-10 text-center">
            <div class="row">
              <div
                v-for="amenity of item.items"
                :key="amenity.uid"
                class="col-md-2 col-sm-6 col-6 mt-2"
              >
                <div class="card h-100">
                  <div class="card-body h-100">
                    <div class="d-flex mb-4">
                      <img
                        :src="require(`@/assets/icons/${amenity.iconName}.png`)"
                        width="24"
                        height="24"
                        :alt="amenity.iconAlt"
                      />
                      <div class="form-check form-switch ms-auto">
                        <SwitcherApp v-model="amenity.enabled" />
                      </div>
                    </div>
                    <p class="mb-0 text-sm">{{ amenity.text }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2"></div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-4"></div>
      <div class="col-lg-4 text-center">
        <ButtonApp @click="nextStep" :class="'btn-icon bg-gradient-primary'">
          Продовжити
        </ButtonApp>
      </div>
      <div class="col-lg-4"></div>
    </div>
  </div>
</template>
