<template>
  <div class="container-fluid">
    <!-- Button trigger modal -->
    <button
      type="button"
      class="btn bg-gradient-primary"
      @click="updateCategory()"
    >
      + Категорія
    </button>

    <!-- Modal -->
    <FinanceCategoryModal
      v-if="showModal"
      :attribute="selectedCategory"
      @submit="
        loadItems();
        closeModal();
      "
      @close="closeModal"
    />
  </div>
  <div class="row mx-1">
    <vue-good-table
      v-if="dataLoaded"
      :columns="columns"
      :rows="tableData.rows"
      :sort-options="{ actions: false }"
      theme="default"
      styleClass="vgt-table bordered"
      responsive
      compactMode
    >
      <template #table-row="props">
        <div v-if="props.column.field === 'label'">
          <div class="d-flex flex-column justify-content-center">
            <p class="text-xs font-weight-normal mb-0">
              {{ props.row.label }}
            </p>
          </div>
        </div>

        <div v-if="props.column.field === 'actions'">
          <div class="d-flex justify-content-start">
            <span
              @click="updateCategory(props.row)"
              data-toggle="tooltip"
              data-placement="top"
              title="Редагувати категорію"
              class="icon ml-2"
            >
              <i
                class="material-icons text-lg me-2 text-secondary cursor-pointer"
                >edit</i
              >
            </span>
            <span
              v-if="!props.row.isDefault"
              @click="deleteCategory(props.row)"
              data-toggle="tooltip"
              data-placement="top"
              title="Видалити категорію"
              class="icon"
            >
              <i
                class="material-icons text-lg me-2 ms-3 text-danger cursor-pointer"
                >delete</i
              >
            </span>
            <span v-else class="icon"></span>
          </div>
        </div>
      </template>
    </vue-good-table>

    <BmnCustomPopup
      ref="deleteModalRef"
      :params="modalParamsRemoveCategory"
    ></BmnCustomPopup>
  </div>
</template>

<script>
import api from "@/services/api";
import { useTable } from "@/composables/good-table";

import FinanceCategoryModal from "./FinanceCategoryModal.vue";
import BmnCustomPopup from "@/components/BmnCustomPopup.vue";

export default {
  setup() {
    const { loadItems, tableData, isLoading } = useTable(
      api.financeCategories.list
    );

    return {
      loadItems,
      isLoading,
      tableData,
    };
  },
  components: { FinanceCategoryModal, BmnCustomPopup },
  name: "financeCategories",
  data() {
    return {
      dataLoaded: false,
      showModal: false,
      reportData: [],
      selectedCategory: null,
      columns: [
        {
          label: "Назва",
          field: "label",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-sm font-weight-normal ",
        },
        {
          label: "Дія",
          field: "actions",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-sm font-weight-normal text-center",
        },
      ],
      modalParamsRemoveCategory: {
        modalId: "modal-default-1",
        modalTitle: "Видалити категорію",
        modalDescription:
          "Ви впевнені, що хочете видалити цю категорію? Після видалення відновлення буде неможливо.",
        modalButtonCancelText: "Скасувати",
        modalButtonConfirmText: "Видалити",
        data: {},
        confirmHandler: async () => {},
      },
    };
  },
  async mounted() {
    setTimeout(async () => {
      await this.loadItems();
      this.dataLoaded = true;
    }, 500);
  },
  methods: {
    updateCategory(item) {
      this.selectedCategory = item;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedCategory = null;
    },
    deleteCategory(item) {
      const listComponent = this;
      this.modalParamsRemoveCategory.modalTitle = `Видалити категорію: ${item.label}`;
      this.modalParamsRemoveCategory.confirmHandler = async () => {
        try {
          await api.financeCategories.delete(item._id);
          listComponent.loadItems();
          this.$store.commit("addToast", {
            title: "Збережено",
          });
        } catch (error) {
          this.$store.commit("addToast", {
            title: "Виникла помилка. Спробуйте ще раз",
            type: "error",
          });
        }
      };
      this.$refs.deleteModalRef.showModal();
    },
  },
};
</script>

<style scoped>
.icon {
  width: 10px;
}
.ml-2 {
  margin-left: 8px;
}
</style>
