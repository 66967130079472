<script setup>
import FullCalendar from "@fullcalendar/vue3";
import SharedSpinner from "@/views/shared/Spinner.vue";
import { useBreakpoints } from "@/composables/common/useBreakpoint";
import { computed, ref } from "vue";

const props = defineProps({
  options: {
    type: Object,
    required: true,
  },
  calendarLoaded: {
    type: Boolean,
    default: false,
  },
  isSmallOnMobile: {
    type: Boolean,
    default: false,
  },
});

const optionsForCalendar = computed(() => {
  return props.options;
});
const fullCalendarRef = ref(null);

defineExpose({
  fullCalendarRef,
});
const { isMobile } = useBreakpoints();
</script>

<template>
  <div class="calendar">
    <SharedSpinner
      class="calendar__spinner"
      v-show="calendarLoaded"
      :variant="'small'"
    ></SharedSpinner>
    <div
      class="calendar__inner"
      :class="{
        loaded: calendarLoaded,
        small: isMobile && isSmallOnMobile,
      }"
    >
      <FullCalendar
        class="demo-app-calendar"
        ref="fullCalendarRef"
        :options="optionsForCalendar"
      >
        <template v-slot:eventContent="arg">
          <slot :arg="arg" name="calendar-content"></slot>
        </template>
      </FullCalendar>
    </div>
  </div>
</template>

<style lang="css" scoped>
.calendar {
  position: relative;

  .demo-app-calendar {
    transition: all 0.3s cubic-bezier(0.5, 0.02, 0.13, 0.5);
  }
}

.calendar__inner.loaded {
  pointer-events: none;
  opacity: 0.4;
}

.calendar__inner {
  background: #fff;
}

.calendar__inner.small >>> .fc-timeline-event {
  padding: 5px;
}

.calendar__inner.small {
  @media (max-width: 768px) {
    & >>> a.fc-timeline-slot-cushion.fc-scrollgrid-sync-inner {
      font-size: 8px;
    }

    & >>> .fc-timeline-event p {
      font-size: 8px !important;
    }

    & >>> span.fc-datagrid-cell-main {
      font-size: 8px;
    }
  }
}

.calendar__spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

>>> .fc-timeline-slot-lane.fc-day.fc-day-sun,
>>> .fc-timeline-slot-lane.fc-day.fc-day-sat {
  background-color: rgba(255, 220, 40, 0.15);
}

>>> .fc-timeline .fc-resource-timeline-divider.fc-cell-shaded {
  pointer-events: none;
}

>>> .fc-timeline-slot-label.fc-day.fc-day-sun,
>>> .fc-timeline-slot-label.fc-day.fc-day-sat {
  background-color: rgba(255, 220, 40, 0.15);
}

>>> .fc .fc-scrollgrid-section table {
  height: 100%;
}

>>> .fc-timeline-header-row:first-child .fc-timeline-slot-label {
  background: transparent !important;
}

>>> .fc-resource .fc-datagrid-cell-cushion {
  padding-left: 0;
}

>>> .fc-datagrid-cell .fc-datagrid-cell-cushion {
  padding-left: 0;
}

>>> span.fc-datagrid-cell-main {
  font-size: 12px;
}

>>> a.fc-timeline-slot-cushion.fc-scrollgrid-sync-inner {
  font-size: 12px;
}

>>> .fc-timeline-event {
  pointer-events: none;
  background: transparent;
  padding: 10px;
}

>>> .fc-timeline-event-harness {
  pointer-events: none;
  background: transparent;
}

>>> .fc-highlight {
  //background-color: #ffffcc;
}

>>> .fc .fc-timeline-events {
  pointer-events: none;
}

>>> .fc-timeline-event p {
  color: #84889e;
  font-size: 12px;
  background: transparent;
  margin-bottom: 0;
  line-height: 15px;

  & .prices {
  }
}
</style>
