<template>
  <div class="container-fluid my-3 py-3">
    <div class="row">
      <div class="col-lg-12">
        <!-- Card Общая информация -->
        <div class="card" id="basic-info">
          <div class="card-header">
            <div class="row">
              <div class="col-9">
                <h5>Створити абонемент</h5>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <form>
              <div class="row">
                <div class="col-lg-3">
                  <div class="input-group input-group-outline">
                    <input
                      type="any"
                      class="form-control"
                      placeholder="Назва абонемента"
                    />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="input-group input-group-outline">
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Вартість (грн)"
                    />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="input-group input-group-outline">
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Кількість візитів"
                    />
                  </div>
                </div>
              </div>
              <!-- <h6 class="mt-3">Налаштування абонемента</h6> -->
              <!-- <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-6">
                      <div class="row">
                        <div class="w-50">
                          <label class="p-0 mb-0">
                            Термін дії
                            <a
                              href="javascript:;"
                              class="mx-3"
                              data-bs-toggle="tooltip"
                              data-bs-original-title="Бесстроковий за замовчуванням"
                            >
                              <i
                                class="material-icons text-secondary position-relative text-lg"
                                >info</i
                              >
                            </a>
                          </label>
                        </div>
                        <div class="w-50">
                          <label class="p-0"> Активувати</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="w-50">
                          <div
                            class="input-group input-group-outline comission"
                          >
                            <input
                              type="number"
                              class="form-control"
                              min="0"
                              placeholder="днів"
                            />
                          </div>
                        </div>
                        <div class="w-50">
                          <div class="dropdown d-inline">
                            <a
                              href="javascript:;"
                              class="btn btn-sm btn-outline-dark dropdown-toggle"
                              data-bs-toggle="dropdown"
                              id="navbarDropdownMenuLink2"
                            >
                              при першому відвідуванні
                            </a>
                            <ul
                              class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                              aria-labelledby="navbarDropdownMenuLink2"
                              data-popper-placement="left-start"
                            >
                              <li>
                                <a
                                  class="dropdown-item border-radius-md"
                                  href="javascript:;"
                                  >при першому відвідуванні</a
                                >
                              </li>
                              <li>
                                <a
                                  class="dropdown-item border-radius-md"
                                  href="javascript:;"
                                  >при продажу</a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col"></div>
                  </div>
                </div>
              </div> -->
              <!-- <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-6">
                      <div class="row">
                        <div class="w-50">
                          <label class="p-0"> Дозволяти заморозку</label>
                        </div>
                        <div class="w-50">
                          <label class="p-0 mb-0">
                            Загальний період заморозки</label
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="w-50">
                          <div class="dropdown d-inline">
                            <a
                              href="javascript:;"
                              class="btn btn-sm btn-outline-dark dropdown-toggle"
                              data-bs-toggle="dropdown"
                              id="navbarDropdownMenuLink2"
                            >
                              ні
                            </a>
                            <ul
                              class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                              aria-labelledby="navbarDropdownMenuLink2"
                              data-popper-placement="left-start"
                            >
                              <li>
                                <a
                                  class="dropdown-item border-radius-md"
                                  href="javascript:;"
                                  >ні</a
                                >
                              </li>
                              <li>
                                <a
                                  class="dropdown-item border-radius-md"
                                  href="javascript:;"
                                  >так</a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="w-50">
                          <div
                            class="input-group input-group-outline comission"
                          >
                            <input
                              type="number"
                              class="form-control"
                              min="0"
                              placeholder=" 0 днів"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-4"></div>
                  </div>
                </div>
              </div> -->
              <h6 class="mt-3">Діє на послуги</h6>
              <div class="input-group input-group-static">
                <label for="exampleFormControlSelect2" class="ms-0"
                  >Оберіть послугу (можна декаілька):</label
                >
                <select
                  multiple=""
                  class="form-control pb-4"
                  id="exampleFormControlSelect2"
                >
                  <option>Стрижка</option>
                  <option>Стрижка бороди</option>
                  <option>Масаж спини</option>
                  <option>Масаж стоп</option>
                  <option>Педікюр</option>
                </select>
              </div>
            </form>
          </div>
        </div>
        <!-- Card Общая информация finish -->
        <div class="d-flex align-items-center mb-sm-0 mt-4">
          <div class="w-50"></div>
          <div class="w-50 text-end">
            <button type="button" class="btn bg-gradient-primary">
              Зберегти
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.comission {
  min-width: 90px;
  max-width: 90px;
}
.col {
  flex: 0 0 0%;
}
</style>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";

export default {
  name: "salary",
  data() {
    return {};
  },
  mounted() {
    setNavPills();
    setTooltip();
  },
};
</script>
