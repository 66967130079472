<template>
  <div class="container-fluid my-3 py-3">
    <div class="row mb-5">
      <div class="col-lg-3">
        <div class="card position-sticky top-1">
          <ul class="nav flex-column bg-white border-radius-lg p-3">
            <li class="nav-item pt-2">
              <a
                class="nav-link text-dark d-flex"
                data-scroll=""
                href="#managers"
              >
                <i class="material-icons text-lg me-2">person</i>
                <span class="text-sm">Администраторы</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex" data-scroll="" href="#staff">
                <i class="material-icons text-lg me-2">person</i>
                <span class="text-sm">Мастера</span>
              </a>
            </li>

            <div class="w-50 mt-3">
              <button type="button" class="btn bg-gradient-primary">
                Сохранить
              </button>
            </div>
          </ul>
        </div>
      </div>
      <div class="col-lg-9 mt-lg-0">
        <!-- Администраторы  -->
        <div class="card" id="managers">
          <div class="card-header">
            <h6>Администриторы</h6>
            <p class="text-sm">
              Выберите какие разделы вы хотите, чтобы Администраторы
              просматривали, а какие хотите, чтобы могли редактировать.
            </p>
          </div>
          <div class="card-body pt-0">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th class="ps-1" colspan="4">
                      <p class="mb-0">Страница</p>
                    </th>
                    <th class="text-center">
                      <p class="mb-0">Просмотр</p>
                    </th>
                    <th class="text-center">
                      <p class="mb-0">Редактирование</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="ps-1" colspan="4">
                      <div class="my-auto">
                        <span class="text-dark d-block text-sm"
                          >Полный контроль</span
                        >
                        <span class="text-xs font-weight-normal"
                          >Такой уровень доступа позволяет читать и
                          редактировать все разделы, <br />а также управлять
                          уровнями доступов для других Администраторов и
                          Мастеров.</span
                        >
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          checked
                          type="checkbox"
                          id="flexSwitchCheckDefault11"
                        />
                      </div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td class="ps-1" colspan="4">
                      <div class="my-auto">
                        <span class="text-dark d-block text-sm">Календарь</span>
                        <!-- <span class="text-xs font-weight-normal">Notify when another user mentions you in a comment</span> -->
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          checked
                          type="checkbox"
                          id="flexSwitchCheckDefault11"
                        />
                      </div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td class="ps-1" colspan="4">
                      <div class="my-auto">
                        <span class="text-dark d-block text-sm">Записи</span>
                        <!-- <span class="text-xs font-weight-normal">Notify when another user comments your item.</span> -->
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          checked
                          type="checkbox"
                          id="flexSwitchCheckDefault14"
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          checked
                          type="checkbox"
                          id="flexSwitchCheckDefault15"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="ps-1" colspan="4">
                      <div class="my-auto">
                        <span class="text-dark d-block text-sm">Аналитика</span>
                        <!-- <span class="text-xs font-weight-normal">Notify when another user follows you.</span> -->
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault17"
                        />
                      </div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td class="ps-1" colspan="4">
                      <div class="my-auto">
                        <span class="text-dark d-block text-sm">Клиенты</span>
                        <!-- <span class="text-xs font-weight-normal">Notify when another user follows you.</span> -->
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault17"
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          checked
                          type="checkbox"
                          id="flexSwitchCheckDefault18"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="ps-1" colspan="4">
                      <div class="my-auto">
                        <span class="text-dark d-block text-sm">Склад</span>
                        <!-- <span class="text-xs font-weight-normal">Notify when another user follows you.</span> -->
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault17"
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          checked
                          type="checkbox"
                          id="flexSwitchCheckDefault18"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="ps-1" colspan="4">
                      <div class="my-auto">
                        <span class="text-dark d-block text-sm">Финансы</span>
                        <!-- <span class="text-xs font-weight-normal">Notify when another user follows you.</span> -->
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault17"
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          checked
                          type="checkbox"
                          id="flexSwitchCheckDefault18"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="ps-1" colspan="4">
                      <div class="my-auto">
                        <span class="text-dark d-block text-sm">Настройки</span>
                        <span class="text-xs font-weight-normal"
                          >Все, кроме раздела "Доступы"</span
                        >
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault17"
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          checked
                          type="checkbox"
                          id="flexSwitchCheckDefault18"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="ps-1" colspan="4">
                      <div class="my-auto">
                        <span class="text-dark d-block text-sm"
                          >Интеграции</span
                        >
                        <!-- <span class="text-xs font-weight-normal">Notify when another user follows you.</span> -->
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault17"
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          checked
                          type="checkbox"
                          id="flexSwitchCheckDefault18"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--  Администраторы finish  -->

        <!-- Мастера  -->
        <div class="card mt-3" id="staff">
          <div class="card-header">
            <h6>Мастера</h6>
            <p class="text-sm">
              Выберите какие разделы вы хотите, чтобы Мастера просматривали, а
              какие хотите, чтобы могли редактировать.
            </p>
          </div>
          <div class="card-body pt-0">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th class="ps-1" colspan="4">
                      <p class="mb-0">Страница</p>
                    </th>
                    <th class="text-center">
                      <p class="mb-0">Просмотр</p>
                    </th>
                    <th class="text-center">
                      <p class="mb-0">Редактирование</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="ps-1" colspan="4">
                      <div class="my-auto">
                        <span class="text-dark d-block text-sm"
                          >Полный контроль</span
                        >
                        <span class="text-xs font-weight-normal"
                          >Такой уровень доступа позволяет читать и
                          редактировать все разделы, <br />а также управлять
                          уровнями доступов для других Мастеров и
                          Администраторов.</span
                        >
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          checked
                          type="checkbox"
                          id="flexSwitchCheckDefault11"
                        />
                      </div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td class="ps-1" colspan="4">
                      <div class="my-auto">
                        <span class="text-dark d-block text-sm">Календарь</span>
                        <!-- <span class="text-xs font-weight-normal">Notify when another user mentions you in a comment</span> -->
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          checked
                          type="checkbox"
                          id="flexSwitchCheckDefault11"
                        />
                      </div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td class="ps-1" colspan="4">
                      <div class="my-auto">
                        <span class="text-dark d-block text-sm">Записи</span>
                        <!-- <span class="text-xs font-weight-normal">Notify when another user comments your item.</span> -->
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          checked
                          type="checkbox"
                          id="flexSwitchCheckDefault14"
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          checked
                          type="checkbox"
                          id="flexSwitchCheckDefault15"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="ps-1" colspan="4">
                      <div class="my-auto">
                        <span class="text-dark d-block text-sm">Аналитика</span>
                        <!-- <span class="text-xs font-weight-normal">Notify when another user follows you.</span> -->
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault17"
                        />
                      </div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td class="ps-1" colspan="4">
                      <div class="my-auto">
                        <span class="text-dark d-block text-sm">Клиенты</span>
                        <!-- <span class="text-xs font-weight-normal">Notify when another user follows you.</span> -->
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault17"
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          checked
                          type="checkbox"
                          id="flexSwitchCheckDefault18"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="ps-1" colspan="4">
                      <div class="my-auto">
                        <span class="text-dark d-block text-sm">Склад</span>
                        <!-- <span class="text-xs font-weight-normal">Notify when another user follows you.</span> -->
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault17"
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          checked
                          type="checkbox"
                          id="flexSwitchCheckDefault18"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="ps-1" colspan="4">
                      <div class="my-auto">
                        <span class="text-dark d-block text-sm">Финансы</span>
                        <!-- <span class="text-xs font-weight-normal">Notify when another user follows you.</span> -->
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault17"
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          checked
                          type="checkbox"
                          id="flexSwitchCheckDefault18"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="ps-1" colspan="4">
                      <div class="my-auto">
                        <span class="text-dark d-block text-sm">Настройки</span>
                        <span class="text-xs font-weight-normal"
                          >Все, кроме раздела "Доступы"</span
                        >
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault17"
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          checked
                          type="checkbox"
                          id="flexSwitchCheckDefault18"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="ps-1" colspan="4">
                      <div class="my-auto">
                        <span class="text-dark d-block text-sm"
                          >Интеграции</span
                        >
                        <!-- <span class="text-xs font-weight-normal">Notify when another user follows you.</span> -->
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault17"
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                      >
                        <input
                          class="form-check-input"
                          checked
                          type="checkbox"
                          id="flexSwitchCheckDefault18"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--  Мастера finish  -->

        <!--  Save  -->
        <div class="d-flex align-items-center mb-sm-0 mt-4">
          <div class="w-50"></div>
          <div class="w-50 text-end">
            <button type="button" class="btn bg-gradient-primary">
              Сохранить
            </button>
          </div>
        </div>
        <!--  Save finish  -->
      </div>
    </div>
  </div>
</template>

<script></script>
