<script setup>
import Dropzone from "dropzone";
import config from "@/config";
import { onMounted, ref } from "vue";
import ButtonApp from "@/components/common/other/ButtonApp.vue";
const API_URL_BASE = config.API_URL_BASE;

const props = defineProps({
  nameField: {
    type: String,
    required: true,
  },
  isLoading: { type: Boolean, required: true },
});

const emit = defineEmits(["nextStep"]);
function nextStep() {
  emit("nextStep", photo.value, props.nameField, true);
}
const photoRef = ref(null);
const photo = ref({
  key: "",
  url: "",
});

const dropzoneOptions = {
  url: `${API_URL_BASE}/settings/staff/uploadPhoto`,
  method: "POST",
  headers: {
    "x-access-token": localStorage.getItem("accessToken"),
  },
  paramName: "file",
  maxFiles: 1,
  maxFilesize: 2 * 1024 * 1024, // 2 MB
  createImageThumbnails: true,
  addRemoveLinks: false,
};

onMounted(() => {
  const dropzone = new Dropzone(photoRef.value, dropzoneOptions);
  dropzone.on("success", (file, result) => {
    photo.value.key = result.photoKey;
    photo.value.url = result.photoUrl;
    dropzone.removeFile(file);
  });

  dropzone.on("error", (file, message) => {
    console.warn(message);
  });
});

async function removePhoto() {
  const payload = {
    key: photo.value.key,
    url: photo.value.url,
  };
  const API_URL = `${API_URL_BASE}/settings/staff/removePhoto`;
  const options = {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      "x-access-token": localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(payload),
  };

  const response = await fetch(API_URL, options);
  await response.json();
  photo.value.key = null;
  photo.value.url = null;
}
</script>
<template>
  <div>
    <div class="row">
      <div class="col-12 text-center">
        <h4>Фото</h4>
      </div>
    </div>

    <div class="card-body pt-0">
      <div class="row text-center">
        <p>Фото буде відображатись на сторінці онлайн бронювання</p>
      </div>
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 text-center">
          <div class="row">
            <div class="col-12">
              <div ref="photoRef" class="form-control border dropzone">
                <a
                  v-show="photo.key && photo.url"
                  class="image-dropzone"
                  target="_blank"
                  :href="photo.url"
                >
                  <img alt="staff photo" :src="photo.url" width="200" />
                </a>
              </div>
            </div>
            <div class="col-12 mt-2" v-show="photo.key && photo.url">
              <a
                class="nav-link text-danger d-flex c-width-200"
                data-scroll=""
                href="#"
              >
                <i class="material-icons text-lg me-2">delete</i>
                <span class="text-sm" @click="removePhoto">Видалити</span>
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4"></div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 text-center">
          <ButtonApp
            :loading="isLoading"
            @click="nextStep"
            :class="'btn-icon bg-gradient-primary'"
          >
            Створити номер
          </ButtonApp>
        </div>
        <div class="col-lg-4"></div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.dropzone {
  position: relative;
  overflow: hidden;
}
.image-dropzone {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>
