import { computed, ref, watch } from "vue";
import { getFromLocalStorage, setToLocalStorage } from "@/utils/localStorage";
import { useStore } from "vuex";
import { useDaysSelection } from "@/composables/common/daysSelection";
import { getIntervalsFromISO } from "@/utils/time";
import { DateTime } from "luxon";
import timeSlotConverter from "@/utils/timeSlotConverter";
import api from "@/services/api";
import { Ukrainian } from "flatpickr/dist/l10n/uk";

export function useScheduleSetting({ onSuccess }) {
  const store = useStore();
  const { allDay, selectedDays, days } = useDaysSelection();
  const storeToast = useStore();

  const isLoading = ref(false);

  const dateRange = ref("");

  const dateNormalize = computed(() => {
    if (!dateRange.value) {
      return false;
    }
    const arr = dateRange.value.split(" ");
    if (arr.length === 3) {
      return { from: arr[0], to: arr[2] };
    } else if (arr.length > 0) {
      return { from: arr[0], to: null };
    }

    return false;
  });

  const selectedItems = ref([]);

  const defaultWorkTime = ref({
    from: "10:00",
    to: "20:00",
  });

  const timeWork = ref({
    from: defaultWorkTime.value.from,
    to: defaultWorkTime.value.to,
  });

  const timeBreak = ref({
    from: null,
    to: null,
  });

  function updateDefaultTime(updateTime = false) {
    defaultWorkTime.value = {
      from:
        getFromLocalStorage(`${store.state.tenantName}.scheduleTimeFrom`) ||
        "10:00",
      to:
        getFromLocalStorage(`${store.state.tenantName}.scheduleTimeTo`) ||
        "20:00",
    };

    if (updateTime) {
      timeWork.value.from = defaultWorkTime.value.from;
      timeWork.value.to = defaultWorkTime.value.to;
    }
  }

  watch(
    () => store.state.tenantName,
    (val) => {
      if (val) {
        updateDefaultTime(true);
      }
    }
  );
  const _freestyleDates = ref([]);
  const freestyleDates = ref([]);

  const configFlatpickr = ref({
    locale: { ...Ukrainian, rangeSeparator: " по " },
    mode: "range",
    altFormat: "M j, Y",
    conjunction: "11",
    altInput: true,
  });
  const configFlatpickrFreestyle = ref({
    locale: { ...Ukrainian },
    mode: "multiple",
    altFormat: "M j, Y",
    conjunction: ", ",
    altInput: true,
    inline: true,
    onChange: (selectedDates) => {
      if (!selectedDates) {
        freestyleDates.value = [];
        return;
      }

      freestyleDates.value = selectedDates
        .map((item) => {
          return DateTime.fromJSDate(item).toISODate();
        })
        .sort();
    },
  });

  const configFlatpickrTime = ref({
    allowInput: true,
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    static: true,
    time_24hr: true,
  });

  const isFreestyleWorkingSchedule = ref(false);

  async function saveWorkingDays(isDeleted = false) {
    if (selectedItems.value.length === 0) {
      return;
    }
    try {
      isLoading.value = true;
      const promises = selectedItems.value.map((item) =>
        saveWorkingHoursForRoom(item.code, isDeleted)
      );
      Promise.all(promises)
        .then(() => {
          setToLocalStorage(
            `${store.state.tenantName}.scheduleTimeFrom`,
            timeWork.value.from
          );
          setToLocalStorage(
            `${store.state.tenantName}.scheduleTimeTo`,
            timeWork.value.to
          );
          updateDefaultTime();
          reset();
          storeToast.commit("addToast", { title: "Збережено" });
          if (onSuccess && typeof onSuccess === "function") {
            onSuccess();
          }
        })
        .catch((error) => {
          console.error(error);
          storeToast.commit("addToast", {
            title: "Виникла помилка. Спробуйте ще раз",
            type: "error",
          });
        });
    } catch (error) {
      console.error(error);
      storeToast.commit("addToast", {
        title: "Виникла помилка. Спробуйте ще раз",
        type: "error",
      });
    } finally {
      isLoading.value = false;
    }
  }

  async function saveWorkingHoursForRoom(staffId, isDeleted = false) {
    const days = [];
    const intervals = getIntervalsFromISO(
      dateNormalize.value.from,
      dateNormalize.value.to ? dateNormalize.value.to : dateNormalize.value.from
    );

    const datesToSave = isFreestyleWorkingSchedule.value
      ? freestyleDates.value
      : intervals;

    datesToSave.forEach((item) => {
      const iteration = DateTime.fromISO(item);
      const dateIsInSelected =
        selectedDays.value.findIndex(
          (itemSelected) => itemSelected.number === iteration.weekday
        ) >= 0;
      if (!dateIsInSelected) {
        return;
      }
      if (isDeleted) {
        days.push({
          date: item,
          isActive: false,
          from: null,
          to: null,
          breakFrom: null,
          breakTo: null,
        });
      } else {
        days.push({
          date: item,
          isActive: true,
          from: timeSlotConverter.toNumber(timeWork.value.from),
          to: timeSlotConverter.toNumber(timeWork.value.to),
          breakFrom: timeSlotConverter.toNumber(timeBreak.value.from),
          breakTo: timeSlotConverter.toNumber(timeBreak.value.to),
        });
      }
    });
    const payload = {
      staffId,
      days,
    };
    await api.staff.constructorSaveWorkingHours(staffId, payload);
  }

  function reset() {
    dateRange.value = "";
    allDay.value = true;
    selectedItems.value = [];
    timeBreak.value.from = null;
    timeBreak.value.to = null;
    timeWork.value.to = defaultWorkTime.value.to;
    timeWork.value.from = defaultWorkTime.value.from;
  }

  return {
    defaultWorkTime,
    timeWork,
    timeBreak,
    allDay,
    dateNormalize,
    dateRange,
    selectedDays,
    isLoading,
    days,
    selectedItems,
    configFlatpickrTime,
    configFlatpickr,
    _freestyleDates,
    isFreestyleWorkingSchedule,
    freestyleDates,
    configFlatpickrFreestyle,
    saveWorkingDays,
    reset,
  };
}
