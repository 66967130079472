<template>
  <div class="container-fluid my-3 py-3">
    <div class="row">
      <div class="col-lg-12">
        <!-- Card Общая информация -->
        <div class="card" id="basic-info">
          <div class="card-header">
            <div class="row">
              <div class="col-9">
                <h5>Створити схему</h5>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <form>
              <div class="col-lg-6">
                <div class="input-group input-group-outline my-3">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Назва схеми"
                  />
                </div>
              </div>
              <h6>Налаштування схеми</h6>
              <label class="p-0 mb-0">
                Налаштування відрахувань комісій
                <a
                  href="javascript:;"
                  class="mx-3"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Відсоток та/або фіксована сума від виконаної послуги (враховуються послуги в статусі 'Клієнт прийшов')"
                >
                  <i
                    class="material-icons text-secondary position-relative text-lg"
                    >info</i
                  >
                </a>
              </label>
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col">
                      <div
                        class="input-group input-group-outline my-3 comission"
                      >
                        <input
                          type="number"
                          class="form-control"
                          min="0"
                          placeholder="0.00 %"
                        />
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="input-group input-group-outline my-3 comission"
                      >
                        <input
                          type="number"
                          placeholder="грн"
                          min="0"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <label class="mt-2 mb-0">Мінімальна сума за вихід</label>
              <div class="input-group input-group-outline my-3 comission">
                <input
                  type="number"
                  class="form-control"
                  min="0"
                  placeholder="грн"
                />
              </div>
              <h6>Додаткові конфігурації</h6>
              <label class="mt-2 mb-0"
                >Відсоток від всіх співробітників
                <a
                  href="javascript:;"
                  class="mx-3"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Відсоток від виконаних послуг всіма співробітниками (враховуються послуги в статусі 'Клієнт прийшов')"
                >
                  <i
                    class="material-icons text-secondary position-relative text-lg"
                    >info</i
                  >
                </a>
              </label>
              <div class="input-group input-group-outline my-3 comission">
                <input
                  type="number"
                  class="form-control"
                  min="0"
                  placeholder="%"
                />
              </div>
              <label class="mt-2 mb-0"
                >Погодинна оплата
                <a
                  href="javascript:;"
                  class="mx-3"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Враховується час всіх виконаних послуг (враховуються послуги в статусі 'Клієнт прийшов')"
                >
                  <i
                    class="material-icons text-secondary position-relative text-lg"
                    >info</i
                  >
                </a>
              </label>
              <div class="input-group input-group-outline my-3 comission">
                <input
                  type="number"
                  class="form-control"
                  min="0"
                  placeholder="грн/год"
                />
              </div>
            </form>
          </div>
        </div>
        <!-- Card Общая информация finish -->
        <div class="d-flex align-items-center mb-sm-0 mt-4">
          <div class="w-50"></div>
          <div class="w-50 text-end">
            <button type="button" class="btn bg-gradient-primary">
              Зберегти
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.comission {
  min-width: 90px;
  max-width: 90px;
}
.col {
  flex: 0 0 0%;
}
</style>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";

export default {
  name: "salary",
  data() {
    return {};
  },
  mounted() {
    setNavPills();
    setTooltip();
  },
};
</script>
