import * as _ from "lodash";
import constants from "@/constants";
import { ref } from "vue";
import { useRoute } from "vue-router";

import router from "@/router/internal";

export const useTable = (methodApi, source) => {
  const route = useRoute();
  const tablePerPage = constants.defaultDatatablePerPage;
  const tableData = ref({
    totalRecords: 0,
    rows: [],
  });
  const serverParams = ref({
    columnFilters: {},
    sort: [
      {
        field: "createdAt", // example: 'name'
        type: "desc", // 'asc' or 'desc'
      },
    ],
    page: 1,
    perPage: constants.defaultDatatablePerPage,
  });
  const isLoading = ref(false);

  const paginationOptions = ref({
    enabled: true,
    perPage: tablePerPage,
    position: "bottom",
    perPageDropdownEnabled: false,
    nextLabel: "наст.",
    prevLabel: "попер.",
    rowsPerPageLabel: "записів на сторінці",
    ofLabel: "із",
    pageLabel: "сторінка",
    allLabel: "всі",
  });
  const sortOptions = ref({
    enabled: true,
    multipleColumns: false,
    // initialSortBy: {
    //   field: 'createdAt',
    //   type: 'desc'
    // }
  });
  const updateParams = (newProps) => {
    serverParams.value = Object.assign({}, serverParams.value, newProps);
  };
  const onRowClick = (params) => {
    const url =
      route.path === "/settings/company" ? "/settings/branches" : route.path;
    router.push(`${url}/${params.row._id}`);
  };
  const onPageChange = async (params) => {
    updateParams({ page: params.currentPage });
    await loadItems();
  };

  const onPerPageChange = async (params) => {
    updateParams({ perPage: params.currentPerPage });
    await loadItems();
  };

  const onSortChange = async (params) => {
    updateParams({
      page: 1,
      sort: [
        {
          type: params[0].type,
          field: params[0].field,
        },
      ],
    });
    await loadItems();
  };

  const onColumnFilter = async (params) => {
    updateParams(params);
    await loadItems();
  };

  const loadItems = async () => {
    isLoading.value = true;

    const params = {
      limit: serverParams.value.perPage,
      offset: (serverParams.value.page - 1) * serverParams.value.perPage,

      sortField: _.get(serverParams.value, "sort[0].field"),
      sortDir: _.get(serverParams.value, "sort[0].type"),

      datatable: "y",
    };

    if (serverParams.value.columnFilters) {
      for (const [paramKey, paramValue] of Object.entries(
        serverParams.value.columnFilters
      )) {
        if (source === "service-orders-datatables" && paramKey === "clientId") {
          params["clientId-phoneNumber"] = paramValue;
        } else {
          params[paramKey] = paramValue;
        }
      }
    }

    const result = await methodApi(params);
    tableData.value.totalRecords = result.rows
      ? result.totalRecords
      : result?.length;
    tableData.value.rows = result.rows ? result.rows : result;
    isLoading.value = false;
  };
  return {
    onColumnFilter,
    onPageChange,
    onPerPageChange,
    onSortChange,
    loadItems,
    onRowClick,
    paginationOptions,
    sortOptions,
    tableData,
    isLoading,
    tablePerPage,
  };
};
