<template>
  <div v-show="!dataLoaded" class="container-fluid my-3 py-3">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>

  <div v-show="dataLoaded" class="container-fluid3">
    <div class="row">
      <div class="col-lg-9 col-lg-10 mx-auto">
        <!-- Card general  -->
        <div id="online-form-link" class="card">
          <div class="card-body">
            <h6 v-if="company.crmMode !== 'hotel'">
              Посилання на онлайн запис
            </h6>
            <h6 v-if="company.crmMode === 'hotel'">
              Посилання на сторінку онлайн бронювання
            </h6>
            <div class="row">
              <div class="col-12">
                <div class="d-flex align-items-center gap-2 text-break">
                  <a
                    v-if="
                      company.onlineFormMode === 'v2' ||
                      company.onlineFormMode === 'v3' ||
                      company.onlineFormMode === 'v4'
                    "
                    :href="`https://go.bookmenow.pro/t/${slug}`"
                    class="text-primary"
                    target="_blank"
                  >
                    https://go.bookmenow.pro/t/{{ slug }}
                  </a>
                  <br />

                  <a
                    v-if="company.onlineFormMode === 'v1'"
                    :href="`https://go.bookmenow.pro/t/${slug}/main`"
                    class="text-primary"
                    target="_blank"
                  >
                    https://go.bookmenow.pro/t/{{ slug }}/main
                  </a>
                  <i
                    v-if="company.onlineFormMode === 'v1'"
                    class="material-icons text-lg me-2 cursor-pointer"
                    @click="
                      copyToClipboard(`https://go.bookmenow.pro/t/${slug}/main`)
                    "
                  >
                    copy
                  </i>
                  <i
                    v-else
                    class="material-icons text-lg me-2 cursor-pointer"
                    @click="
                      copyToClipboard(`https://go.bookmenow.pro/t/${slug}`)
                    "
                  >
                    copy
                  </i>
                </div>
                <div class="row mt-2">
                  <div class="col-6">
                    <div class="row">
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-primary editAction"
                        @click="toggleEdit"
                      >
                        {{ isEditVisible ? "Скасувати" : "Редагувати" }}
                      </button>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <button
                        v-if="isEditVisible"
                        type="button"
                        class="btn btn-sm btn-primary saveAction"
                        @click="saveSlug"
                        :disabled="!tenantSlug || tenantSlug === slug"
                      >
                        Зберегти
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row editTennantName" v-if="isEditVisible">
                <div class="col-lg-6">
                  <div class="available">
                    <div class="element">
                      <a
                        v-if="
                          company.onlineFormMode === 'v2' ||
                          company.onlineFormMode === 'v3' ||
                          company.onlineFormMode === 'v4'
                        "
                        :href="`https://go.bookmenow.pro/t/${slug}`"
                        class="text-primary"
                        target="_blank"
                      >
                        https://go.bookmenow.pro/t/
                      </a>
                    </div>
                    <div
                      class="input-group input-group-lg input-group-outline my-3"
                    >
                      <input
                        type="text"
                        v-model="tenantSlug"
                        class="form-control form-control-lg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- General  finish -->

        <!-- Card Branding  -->
        <div id="branding" class="card mt-2">
          <div class="card-header">
            <h6>Брендинг</h6>
            <div class="row">
              <div class="col-lg-6">
                <div class="d-flex align-items-center gap-2 infoBlock">
                  <span class="material-symbols-outlined"> info </span>
                  <label style="color: #458466">
                    Вимоги до зображень:<br />
                    1. Розмір файлу не більше ніж 4mb<br />
                    2. Формат .gif, .jpeg, .jpg або .png
                  </label>
                </div>
              </div>
            </div>
            <div class="multisteps-form__content">
              <div class="row">
                <label class="form-control mb-0">Лого компанії</label>
                <div
                  v-show="!company.photoLogo.key && !company.photoLogo.url"
                  class="col-12"
                >
                  <div
                    id="photoLogo"
                    class="form-control border dropzone"
                  ></div>
                </div>
                <div
                  v-show="company.photoLogo.key && company.photoLogo.url"
                  class="col-12"
                >
                  <a :href="company.photoLogo.url" target="_blank">
                    <img
                      :src="company.photoLogo.url"
                      alt="company logo"
                      width="200"
                    />
                  </a>
                  <a
                    class="nav-link text-danger d-flex c-width-200"
                    data-scroll=""
                    href="#"
                  >
                    <i class="material-icons text-lg me-2">delete</i>
                    <span class="text-sm" @click="removePhoto('photoLogo')"
                      >Видалити</span
                    >
                  </a>
                </div>
              </div>
            </div>
            <div class="multisteps-form__content">
              <div class="row">
                <label
                  v-if="company.crmMode !== 'hotel'"
                  class="form-control mb-0"
                >
                  Фон для сторінки онлайн запису
                </label>
                <label
                  v-if="company.crmMode === 'hotel'"
                  class="form-control mb-0"
                >
                  Фон для сторінки онлайн бронювання
                </label>
                <div
                  v-show="
                    !company.photoBackground.key && !company.photoBackground.url
                  "
                  class="col-12"
                >
                  <div
                    id="photoBackground"
                    class="form-control border dropzone"
                  ></div>
                </div>
                <div
                  v-show="
                    company.photoBackground.key && company.photoBackground.url
                  "
                  class="col-12"
                >
                  <a :href="company.photoBackground.url" target="_blank">
                    <img
                      :src="company.photoBackground.url"
                      alt="company background"
                      width="200"
                    />
                  </a>
                  <a
                    class="nav-link text-danger d-flex c-width-200"
                    data-scroll=""
                    href="#"
                  >
                    <i class="material-icons text-lg me-2">delete</i>
                    <span
                      class="text-sm"
                      @click="removePhoto('photoBackground')"
                      >Видалити</span
                    >
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-0"></div>
        </div>
        <!-- Card Branding  finish -->

        <!-- Публічна оферта -->
        <div class="card mt-2 pb-3" v-if="company.crmMode === 'hotel'">
          <div class="card-header">
            <div class="row">
              <div class="col-12">
                <h6>Публічна оферта</h6>
              </div>
              <div class="w-85">
                <p class="text-sm mb-0">
                  Додайте текст вашої оферти і він буде відображатись на
                  сторінці онлайн бронювання.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="input-group input-group-outline">
                  <textarea
                    v-model="company.publicOffer"
                    type="text"
                    rows="2"
                    class="form-control"
                    style="height: 75px"
                    ref="description"
                    @input="autoResizeTextarea"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Публічна оферта finish -->

        <!-- Поля на конфірмі -->
        <div id="notifyOnCreated" class="card mt-2 pb-3">
          <div class="card-header">
            <div class="row">
              <div class="col-12">
                <h6>Поля на сторінці підтвердження бронювання</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <vue-good-table
                  :columns="datatableColumns"
                  :rows="clientFieldKeyLabelsDataTable"
                  theme="default"
                  styleClass="vgt-table bordered"
                  responsive
                  compactMode
                >
                  <template #table-row="props">
                    <div v-if="props.column.field === 'label'">
                      <div class="d-flex flex-column justify-content-center">
                        <p class="text-xs font-weight-normal mb-0">
                          {{ props.row.label }}
                        </p>
                      </div>
                    </div>
                    <div v-if="props.column.field === 'show'">
                      <div class="form-check form-switch">
                        <input
                          v-model="
                            company.confirmPageClientFields[props.row.key].show
                          "
                          class="form-check-input"
                          type="checkbox"
                          :disabled="
                            !company.confirmPageClientFields[props.row.key]
                              .mutable
                          "
                        />
                      </div>
                    </div>
                    <div v-if="props.column.field === 'required'">
                      <div class="form-check form-switch">
                        <input
                          v-model="
                            company.confirmPageClientFields[props.row.key]
                              .required
                          "
                          class="form-check-input"
                          type="checkbox"
                          :disabled="
                            !company.confirmPageClientFields[props.row.key]
                              .mutable
                          "
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
        <!-- Поля на конфірмі finish -->

        <!-- налаштування передплати -->
        <div
          id="notifyOnCreated"
          class="card mt-2 pb-3"
          v-if="company.crmMode === 'hotel'"
        >
          <div class="card-header">
            <div class="row">
              <div class="col-11">
                <h6>100% передплати за 1 добу</h6>
              </div>
              <div class="col-1">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="company.fullPrepaymentForOneDayBookings"
                    :disabled="!isAcquiringEnabled"
                  />
                </div>
              </div>

              <div class="w-85">
                <div class="d-flex align-items-center gap-2 infoBlock">
                  <span class="material-symbols-outlined mb-2"> info </span>
                  <label style="color: #458466">
                    Щоб скористатись цим налаштуванням у вас має бути
                    підключений еквайрінг plata by mono
                  </label>
                </div>
                <p>
                  Увімкнувши це налаштування, сума передплати під час онлайн
                  бронювання на 1 добу складатиме 100% від вартості обраної
                  доби. В разі якщо в бронюванні більше однієї доби, то вартість
                  передплати визначається встановленою передплатою в розділі
                  Шахматка - Ціни.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- налаштування передплати finish -->

        <!-- Підтвердження про запис -->
        <div id="notifyOnCreated" class="card mt-2 pb-3">
          <div class="card-header">
            <div class="row">
              <div class="col-11">
                <h6 v-if="company.crmMode !== 'hotel'">
                  SMS Підтвердження про запис
                </h6>
                <h6 v-if="company.crmMode === 'hotel'">
                  SMS Підтвердження про бронь
                </h6>
              </div>
              <div class="col-1">
                <div class="form-check form-switch">
                  <input
                    v-model="company.smsNotifyOnNewServiceOrderFromClient"
                    class="form-check-input"
                    type="checkbox"
                  />
                </div>
              </div>
              <div class="w-85">
                <p class="text-sm mb-0">
                  Відправляти клієнту <b>SMS</b> з підтвердженням
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Підтвердження про запис finish -->

        <div
          v-if="showDevFeatures && company.crmMode === 'hotel'"
          id="notifyOnCreated"
          class="card mt-2 pb-3"
        >
          <div class="card-header">
            <div class="row">
              <div class="col-11"><h6>Відображати ціни на кожен день</h6></div>
              <div class="col-1 form-check form-switch">
                <input
                  v-model="company.onlineFormSettings.calendarShowPrices"
                  class="form-check-input"
                  type="checkbox"
                />
              </div>
              <p class="text-sm mb-0">
                На календарі сторінки номеру чи категорії біля кожної дати буде
                відображатись мінімальна вартість доби
              </p>
            </div>
          </div>
        </div>

        <div
          v-if="company.crmMode === 'hotel'"
          id="notifyOnCreated"
          class="card mt-2 pb-3"
        >
          <div class="card-header">
            <div class="row">
              <div class="col-11"><h6>Бронювання за категорією номера</h6></div>
              <div class="col-1 form-check form-switch">
                <input
                  v-model="company.onlineFormSettings.bookByPosition"
                  class="form-check-input"
                  type="checkbox"
                />
              </div>
              <p class="text-sm mb-0">
                Увімкнувши цю функцію, гості будуть обирати не певний номер для
                бронювання, а категорію. Бронювання будуть створюватись на
                перший, за списком, вільний номер.
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="company.crmMode === 'hotel' && company.name !== 'visia'"
          id="notifyOnCreated"
          class="card mt-2 pb-3"
        >
          <div class="card-header">
            <div class="row">
              <div class="col-11"><h6>Автоматична відміна бронювання</h6></div>
              <div class="col-1 form-check form-switch">
                <input
                  v-model="branch.bookingCancelEnabled"
                  class="form-check-input"
                  type="checkbox"
                />
              </div>
              <p class="text-sm mb-0">
                Бронювання здійснені на сторінці онлайн форми будуть автоматично
                відмінятись через зазначений вами час в разі відсутності
                оплати.<br />
                <b>Важливо!</b> Якщо у вас не підключений еквайрінг, то единий
                спосіб уникнути автоматичної відміни - це вручну додати оплату
                бронюванню.
              </p>
              <label>Відміняти через (годин):</label>
              <div class="row">
                <div class="col-lg-4">
                  <div
                    :class="{
                      'is-filled': branch.bookingCancelAfterHours,
                    }"
                    class="input-group input-group-outline"
                  >
                    <label class="form-label">Годин</label>
                    <input
                      v-model="branch.bookingCancelAfterHours"
                      :disabled="!branch.bookingCancelEnabled"
                      class="form-control"
                      max="999"
                      min="1"
                      type="number"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Дополнительные настройки -->
        <div
          v-if="company.crmMode !== 'hotel'"
          id="additional-settings"
          class="card mt-2 pb-3"
        >
          <div class="card-body">
            <h6>Додаткові налаштування</h6>

            <div class="row mt-3">
              <span>
                {{ getLabelStaffChooseLayoutSize() }} - розмір зображень
              </span>
              <div class="col-lg-4">
                <select
                  id="choices-staff-layout-size"
                  v-model="company.staffLayoutSize"
                  class="form-control"
                  name="choices-staff-layout-size"
                ></select>
              </div>
            </div>
            <div v-if="company.crmMode !== 'hotel'" class="row mt-3">
              <span> Обрати послугу - розмір зображень </span>
              <div class="col-lg-4">
                <select
                  id="choices-service-layout-size"
                  v-model="company.serviceLayoutSize"
                  class="form-control"
                  name="choices-service-layout-size"
                ></select>
              </div>
            </div>

            <hr />
            <div class="row mt-3">
              <span> Крок часу для онлайн запису </span>
              <br />
              <span class="text-sm font-weight-normal">
                Встановлений крок буде відображатись в адмін панелі та на формі
                онлайн запису.</span
              >
              <div class="col-lg-4">
                <select
                  id="choices-form-timeslot-step"
                  v-model="company.formTimeslotStep"
                  class="form-control"
                  name="choices-client"
                ></select>
              </div>
              <hr />
            </div>
            <div class="row mt-3">
              <div class="col-lg-8">
                <span>
                  Мінімальна перерва <b>до та після існуючих</b> записів</span
                >
                <br />
                <span class="text-sm font-weight-normal">
                  Приклад: ви встановили перерву 30 хв. Є записаний клієнт на
                  15:00 на послугу тривалістю 1 година. Після цього запису,
                  найближчий вільний час буде 16:30<br />
                  Так само, ми забезпечимо 30 вільних хвилин перед існуючим
                  записом на 15:00.
                </span>
              </div>
              <div class="col-lg-4">
                <div class="row">
                  <div class="col-6 hours">
                    <label>Години:</label>
                    <div class="input-group input-group-outline">
                      <input
                        v-model="minBetweenBookingsHours"
                        class="form-control"
                        min="0"
                        placeholder="1"
                        type="number"
                      />
                    </div>
                  </div>
                  <div class="col-6 minutes">
                    <label>Хвилини:</label>
                    <div class="input-group input-group-outline">
                      <input
                        v-model="minBetweenBookingsMinutes"
                        class="form-control"
                        min="0"
                        placeholder="59"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <div class="row mt-3">
              <div class="col-lg-8">
                <span> Обмежити час до наступного запису <b>відтепер</b> </span>
                <br />
                <span class="text-sm font-weight-normal">
                  Приклад: ви встановили обмеження на 1 год. 30 хв. Зараз час
                  15:00. Онлайн записатись можно буде з 16:30</span
                >
              </div>
              <div class="col-lg-4">
                <div class="row">
                  <div class="col-6 hours">
                    <label>Години:</label>
                    <div class="input-group input-group-outline">
                      <input
                        v-model="minBeforeHours"
                        class="form-control"
                        min="0"
                        placeholder="1"
                        type="number"
                      />
                    </div>
                  </div>
                  <div class="col-6 minutes">
                    <label>Хвилини:</label>
                    <div class="input-group input-group-outline">
                      <input
                        v-model="minBeforeMinutes"
                        class="form-control"
                        min="0"
                        placeholder="59"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <div class="row mt-3">
              <div class="col-lg-8">
                <span
                  >Перейменувати крок "Оберіть співробітника" на онлайн
                  формі.</span
                ><br />
                <span class="text-sm font-weight-normal">
                  Приклад:"Оберіть майстра" чи "Оберіть стіл"
                </span>
              </div>
              <div class="col-lg-4">
                <div class="input-group input-group-outline">
                  <input
                    v-model="branch.mappingNames.chooseStaff"
                    class="form-control"
                    placeholder="Оберіть майстра"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-lg-8">
                <span>Основний колір сторінки бронювання</span><br />
              </div>
              <div class="col-lg-4">
                <div
                  class="input-group input-group-outline d-inline-flex align-items-center"
                >
                  <div class="color-picker__wrap">
                    <input
                      v-model="company.onlineFormPrimaryColor"
                      class="form-control color-picker"
                      placeholder="Оберіть колір"
                      type="color"
                    />
                  </div>

                  <span class="mx-2">
                    {{ company.onlineFormPrimaryColor }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Дополнительные настройки finish -->

        <!-- Кольори -->
        <div v-if="company.crmMode === 'hotel'" class="card mt-2 pb-3">
          <div class="card-header">
            <div class="row">
              <div class="col-10">
                <h6>Кольори</h6>
              </div>
              <div class="row mt-2">
                <div class="col-lg-8"><span>Колір фону</span><br /></div>
                <div class="col-lg-4">
                  <div
                    class="input-group input-group-outline d-inline-flex align-items-center"
                  >
                    <div class="color-picker__wrap">
                      <input
                        v-model="company.onlineFormSettings.backgroundColor"
                        class="form-control color-picker"
                        placeholder="Оберіть колір"
                        type="color"
                      />
                    </div>

                    <span class="mx-2">
                      {{ company.onlineFormSettings.backgroundColor }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-8"><span>Колір тексту</span><br /></div>
                <div class="col-lg-4">
                  <div
                    class="input-group input-group-outline d-inline-flex align-items-center"
                  >
                    <div class="color-picker__wrap">
                      <input
                        v-model="company.onlineFormSettings.textPrimaryColor"
                        class="form-control color-picker"
                        placeholder="Оберіть колір"
                        type="color"
                      />
                    </div>

                    <span class="mx-2">
                      {{ company.onlineFormSettings.textPrimaryColor }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-8">
                  <span>Колір другорядного тексту</span><br />
                </div>
                <div class="col-lg-4">
                  <div
                    class="input-group input-group-outline d-inline-flex align-items-center"
                  >
                    <div class="color-picker__wrap">
                      <input
                        v-model="company.onlineFormSettings.textSecondaryColor"
                        class="form-control color-picker"
                        placeholder="Оберіть колір"
                        type="color"
                      />
                    </div>

                    <span class="mx-2">
                      {{ company.onlineFormSettings.textSecondaryColor }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-8"><span>Колір фону кнопок</span><br /></div>
                <div class="col-lg-4">
                  <div
                    class="input-group input-group-outline d-inline-flex align-items-center"
                  >
                    <div class="color-picker__wrap">
                      <input
                        v-model="
                          company.onlineFormSettings
                            .primaryButtonBackgroundColor
                        "
                        class="form-control color-picker"
                        placeholder="Оберіть колір"
                        type="color"
                      />
                    </div>

                    <span class="mx-2">
                      {{
                        company.onlineFormSettings.primaryButtonBackgroundColor
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-8">
                  <span>Колір тексту кнопок</span><br />
                </div>
                <div class="col-lg-4">
                  <div
                    class="input-group input-group-outline d-inline-flex align-items-center"
                  >
                    <div class="color-picker__wrap">
                      <input
                        v-model="
                          company.onlineFormSettings.primaryButtonFontColor
                        "
                        class="form-control color-picker"
                        placeholder="Оберіть колір"
                        type="color"
                      />
                    </div>

                    <span class="mx-2">
                      {{ company.onlineFormSettings.primaryButtonFontColor }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Кольори finish -->

        <!-- Facebook pixel -->
        <div id="formView" class="card mt-2 pb-3">
          <div class="card-header">
            <div class="row">
              <div class="col-10">
                <h6>Facebook pixel</h6>
              </div>
              <div class="row mt-3">
                <div class="col-lg-8">
                  <p>
                    Щоб підключити Facebook pixel до вашої сторінки онлайн
                    бронювання - введіть ідентифікатор вашого Facebook пікселя і
                    натисніть "Зберегти".
                  </p>
                </div>
                <div class="col-lg-4">
                  <label class="form-label">Ідентифікатор</label>
                  <div class="input-group input-group-outline">
                    <input
                      v-model="company.facebookPixelId"
                      class="form-control"
                      min="1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Facebook pixel finish-->

        <!-- Google Tag Manager pixel -->
        <div id="formView" class="card mt-2 pb-3">
          <div class="card-header">
            <div class="row">
              <div class="col-10">
                <h6>Google Tag Manager</h6>
              </div>
              <div class="row mt-3">
                <div class="col-lg-8">
                  <p>
                    Щоб підключити Google Tag Manager до вашої сторінки онлайн
                    бронювання - введіть ідентифікатор вашого Google Tag Manager
                    контейнера і натисніть "Зберегти".
                  </p>
                </div>
                <div class="col-lg-4">
                  <label class="form-label">Ідентифікатор</label>
                  <div class="input-group input-group-outline">
                    <input
                      v-model="company.googleTagManagerId"
                      class="form-control"
                      min="1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Google Tag Manager pixel finish-->

        <div v-if="showDevFeatures" class="card mt-4 pb-3">
          <div class="badge bg-gradient-warning">SUPER ADMIN</div>
          <div class="card-header">
            <div class="row">
              <div class="col-10">
                <h6>Версія сторінкі онлайн бронювання</h6>
              </div>
              <div class="col-lg-4">
                <select
                  id="choices-onlineFormMode"
                  v-model="company.onlineFormMode"
                  class="form-control"
                  name="choices-client"
                ></select>
              </div>
              <div class="text-sm mb-0">
                <ul>
                  <li><strong>v1</strong> - 3 steps</li>
                  <li v-if="company.crmMode !== 'hotel'">
                    <strong>v2</strong> - black & white
                  </li>
                  <li v-if="company.crmMode === 'hotel'">
                    <strong>v3</strong> - hotel
                  </li>
                  <li v-if="company.crmMode === 'hotel'">
                    <strong>v4</strong> - hotel (widget)
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="showDevFeatures && company.crmMode === 'hotel'"
          class="card mt-4 pb-3"
        >
          <div class="badge bg-gradient-warning">SUPER ADMIN</div>
          <div class="card-header">
            <div class="row">
              <div class="col-10">
                <h6>Версія <i>"user payment flow"</i> онлайн бронювання</h6>
              </div>
              <div class="col-lg-4">
                <select
                  id="choices-onlineFormSettingsUserPaymentFlow"
                  v-model="company.onlineFormSettings.userPaymentFlow"
                  class="form-control"
                  name="choices-client"
                ></select>
              </div>
              <div class="text-sm mb-0">
                <ul>
                  <li>
                    <strong>paymentFirst</strong> - після конфірма спочатку
                    відправляємо на оплату
                  </li>
                  <li>
                    <strong>successFirst</strong> - після конфірма спочатку
                    відправляємо на success сторінку, а потім очікуємо оплату
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- NOT MVP -->
        <div v-if="showDevFeatures" id="wayforpay" class="card mt-4 pb-3">
          <div class="badge bg-gradient-info">DEV</div>
          <div class="card-header">
            <div class="row">
              <h6>Підтвердження номера телефону по Telegram</h6>
              <div class="w-85">
                <p class="text-sm mb-0">
                  Увімкнувши цю функцію ваш клиєнт зможе записатись онлайн
                  тілька підтвердивши номер телефону через наш
                  <a href="https://t.me/Bookmenowbot" target="_blank"
                    ><b>Telegram bot</b></a
                  >. Увімкніть цю функцію якщо хочете мінімізувати кількість
                  фейкових записів.<br />
                </p>
              </div>
              <div class="w-15 form-check form-switch">
                <input
                  v-model="company.requireConfirmationByTelegram"
                  class="form-check-input"
                  type="checkbox"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Подтверждение телефона по SMS -->
        <div
          v-if="showDevFeatures"
          id="phone-confirm-sms"
          class="card mt-4 pb-3"
        >
          <div class="badge bg-gradient-info">DEV</div>
          <div class="card-header">
            <div class="row">
              <h6>Подтверждение номера телефона по SMS</h6>
              <div class="w-85">
                <p class="text-sm mb-0">
                  Увімкнувши цю функцію ваш клиєнт зможе записатись онлайн
                  тільки підтвердивши номер телефону через <b>SMS</b> або через
                  <b>Telegram</b> (якщо ця функцію увімкнена). Ми відправемо
                  вашому клієнту код-підтвердження в СМС повідомленні на
                  вказаний їі номер телефону. Увімкніть цю функцію якщо хочете
                  мінімізувати кількість фейкових записів.<br />
                  Послуга відправки SMS повідомлень сплачується окремо через наш
                  інтерфейс в розділі
                  <a href="/integrations/sms"><b>Інтеграції - SMS</b></a
                  >.
                </p>
              </div>
              <div class="w-15 form-check form-switch">
                <input
                  v-model="company.requireConfirmationBySms"
                  class="form-check-input"
                  type="checkbox"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- Подтверждение телефона по SMS finish -->
        <div class="fixed-bottom">
          <div class="row">
            <div class="col-10"></div>
            <div class="col-lg-2">
              <div class="mx-4">
                <button
                  class="block btn bg-gradient-primary"
                  type="button"
                  @click="save"
                >
                  Зберегти
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.form-check {
  padding-left: 0;
}

.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}

.hours,
.minutes {
  max-width: 80px;
  min-width: 80px;
}
</style>

<script>
import Dropzone from "dropzone";
import Choices from "choices.js";
import Spinner from "@/views/shared/Spinner.vue";

import config from "@/config";
import api from "@/services/api";
import constants from "@/constants";
import timeDisplay from "@/utils/timeDisplay";
import useClipboard from "vue-clipboard3";
import { autoResizeTextarea } from "@/components/common/functions/autoResizeTextarea";

const API_URL_BASE = config.API_URL_BASE;

export default {
  name: "settings-companyDetails",

  components: {
    Spinner,
  },

  data() {
    return {
      isEditVisible: false,
      dataLoaded: false,
      showDevFeatures: false,
      tenantName: null,
      slug: null,
      tenantSlug: null,

      isAcquiringEnabled: false,

      company: {
        crmMode: null,
        localizationMode: null,
        onlineFormMode: null,
        onlineFormPrimaryColor: null,
        onlineFormSettings: {
          userPaymentFlow: null,
          calendarShowPrices: false,
          backgroundColor: "",
          textPrimaryColor: "",
          textSecondaryColor: "",
          primaryButtonBackgroundColor: "",
          primaryButtonFontColor: "",
          bookByPosition: false,
        },
        photoLogo: {
          key: "",
          url: "",
        },
        photoBackground: {
          key: "",
          url: "",
        },
        requireConfirmationByTelegram: true,
        requireConfirmationBySms: true,

        minMinutesBetweenBookings: 0,

        formTimeslotStep: null,

        publicOffer: "",

        serviceLayoutSize: "small",
        staffLayoutSize: "small",

        wayForPay: {
          enabled: true,
          merchantLogin: "",
          // merchantSecretKey: "",
          // optionalPayment: false,
        },

        facebookPixelId: "",
        googleTagManagerId: "",
        confirmPageClientFields: {
          firstName: {
            show: true,
            required: true,
          },
          lastName: {
            show: true,
            required: false,
          },
          phoneNumber: {
            show: false,
            required: false,
          },
          email: {
            show: false,
            required: false,
          },
          instagramTag: {
            show: false,
            required: false,
          },
          telegramTag: {
            show: false,
            required: false,
          },
          comment: {
            show: true,
            required: false,
          },
        },
      },

      datatableColumns: [
        {
          label: "Поле",
          field: "label",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-sm font-weight-normal text-center",
        },
        {
          label: "Відображати",
          field: "show",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-sm font-weight-normal text-center",
        },
        {
          label: "Обовʼязкове",
          field: "required",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
          tdClass: "text-sm font-weight-normal text-center",
        },
      ],

      clientFieldKeyToLabels: {
        firstName: "Імʼя гостя", // TODO: використати "клієнта" для бьюті
        lastName: "Прізвище",
        phoneNumber: "Номер телефону",
        email: "Email",
        instagramTag: "Instagram nickname",
        telegramTag: "Telegram nickname",
        comment: "Коментар",
      },

      clientFieldKeyLabelsDataTable: [
        {
          key: "firstName",
          label: "Імʼя гостя",
        },
        {
          key: "lastName",
          label: "Прізвище",
        },
        {
          key: "phoneNumber",
          label: "Номер телефону",
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "instagramTag",
          label: "Instagram nickname",
        },
        {
          key: "telegramTag",
          label: "Telegram nickname",
        },
        {
          key: "comment",
          label: "Коментар",
        },
      ],

      branch: {
        showCancelledServiceOrders: false,
        minMinutesBeforeBooking: 0,
        mappingNames: {
          chooseStaff: "",
        },
        bookingCancelEnabled: false,
        bookingCancelAfterHours: null,
      },

      minBeforeHours: null,
      minBeforeMinutes: null,

      minBetweenBookingsHours: null,
      minBetweenBookingsMinutes: null,
    };
  },

  async beforeMount() {
    this.showDevFeatures = !!localStorage.getItem("showDevFeatures");

    let tenantName;
    try {
      const localUser = localStorage.getItem("localUser");
      if (localUser) {
        const parsed = JSON.parse(localUser);
        tenantName = parsed.tenantName;
        this.$store.commit("setTenantName", tenantName);
      }
    } catch (error) {
      console.error(error);
    }

    this.tenantName = this.$store.state.tenantName;

    this.company = await api.company.show();

    if (this.company.crmMode !== "hotel") {
      this.clientFieldKeyToLabels["firstName"] = "Імʼя клієнта";
    }

    if (this.company.crmMode === "hotel") {
      const result = await api.branches.isAcquiringEnabled();
      this.isAcquiringEnabled = result?.isAcquiringEnabled || false;
    }

    const branches = await api.branches.list();
    this.branch = branches[0];
    if (!this.branch.mappingNames) {
      this.branch.mappingNames = {
        chooseStaff: "",
      };
    }

    const { slug } = await api.myprofile.getTenantSlug();
    this.slug = slug;
    this.tenantSlug = slug;

    if (this.branch.minMinutesBeforeBooking) {
      this.minBeforeHours = Math.floor(
        this.branch.minMinutesBeforeBooking / 60
      );
      this.minBeforeMinutes = this.branch.minMinutesBeforeBooking % 60;
    }

    if (this.company.minMinutesBetweenBookings) {
      this.minBetweenBookingsHours = Math.floor(
        this.company.minMinutesBetweenBookings / 60
      );
      this.minBetweenBookingsMinutes =
        this.company.minMinutesBetweenBookings % 60;
    }
  },

  mounted() {
    this.dataLoaded = true;

    setTimeout(autoResizeTextarea, 1000, {
      target: this.$refs.description,
    });

    const dropzoneOptionsLogo = {
      url: `${API_URL_BASE}/settings/company/uploadPhoto/logo`,
      method: "POST",
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
      },
      paramName: "file",
      maxFiles: 1,
      maxFilesize: 2 * 1024 * 1024, // 2 MB
      createImageThumbnails: true,
      addRemoveLinks: false,
    };

    const dropzoneLogo = new Dropzone("#photoLogo", dropzoneOptionsLogo);

    dropzoneLogo.on("addedfile", (file) => {
      this.checkFileType(dropzoneLogo, file);
    });

    dropzoneLogo.on("success", (file, result) => {
      if (!this.company.photoLogo) {
        this.company.photoLogo = {};
      }
      this.company.photoLogo.key = result.photoKey;
      this.company.photoLogo.url = result.photoUrl;

      dropzoneLogo.removeFile(file);
    });

    dropzoneLogo.on("error", (file, message) => {
      console.warn(message);
    });

    // TODO: refactor to reuse uploading/removing code
    const dropzoneOptionsBackground = {
      url: `${API_URL_BASE}/settings/company/uploadPhoto/background`,
      method: "POST",
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
      },
      paramName: "file",
      maxFiles: 1,
      maxFilesize: 2 * 1024 * 1024, // 2 MB
      createImageThumbnails: true,
      addRemoveLinks: false,
    };
    const dropzoneBackground = new Dropzone(
      "#photoBackground",
      dropzoneOptionsBackground
    );

    dropzoneBackground.on("addedfile", (file) => {
      this.checkFileType(dropzoneBackground, file);
    });

    dropzoneBackground.on("success", (file, result) => {
      if (!this.company.photoBackground) {
        this.company.photoBackground = {};
      }
      this.company.photoBackground.key = result.photoKey;
      this.company.photoBackground.url = result.photoUrl;

      dropzoneBackground.removeFile(file);
    });

    dropzoneBackground.on("error", (file, message) => {
      console.warn(message);
    });

    setTimeout(() => {
      const selector = document.getElementById("choices-form-timeslot-step");
      if (selector) {
        const durationOptions = [
          {
            value: 15,
            label: timeDisplay.minutesToHumanFormat(15),
            selected: 15 === this.company.formTimeslotStep,
          },
          {
            value: 30,
            label: timeDisplay.minutesToHumanFormat(30),
            selected: 30 === this.company.formTimeslotStep,
          },
          {
            value: 60,
            label: timeDisplay.minutesToHumanFormat(60),
            selected: 60 === this.company.formTimeslotStep,
          },
          {
            value: 120,
            label: timeDisplay.minutesToHumanFormat(120),
            selected: 120 === this.company.formTimeslotStep,
          },
          {
            value: 180,
            label: timeDisplay.minutesToHumanFormat(180),
            selected: 180 === this.company.formTimeslotStep,
          },
        ];
        if (!this.company.formTimeslotStep) {
          durationOptions[1].selected = true;
        }
        const choiceApi = new Choices(selector, {
          allowHTML: false,
          removeItemButton: false,
          searchEnabled: false,
          choices: durationOptions,
          shouldSort: false,
        });

        this.company.formTimeslotStep = choiceApi.getValue().value;
      }
    }, 500);

    setTimeout(() => {
      const selector = document.getElementById("choices-service-layout-size");
      if (selector) {
        const options = [
          {
            value: "small",
            label: "Малі",
            selected: "small" === this.company.serviceLayoutSize,
          },
          {
            value: "big",
            label: "Великі",
            selected: "big" === this.company.serviceLayoutSize,
          },
        ];
        if (!this.company.serviceLayoutSize) {
          options[0].selected = true;
        }
        const choiceApi = new Choices(selector, {
          allowHTML: false,
          removeItemButton: false,
          searchEnabled: false,
          choices: options,
          shouldSort: false,
        });

        this.company.serviceLayoutSize = choiceApi.getValue().value;
      }
    }, 500);

    setTimeout(() => {
      const selector = document.getElementById("choices-staff-layout-size");
      if (selector) {
        const options = [
          {
            value: "small",
            label: "Малі",
            selected: "small" === this.company.staffLayoutSize,
          },
          {
            value: "big",
            label: "Великі",
            selected: "big" === this.company.staffLayoutSize,
          },
        ];
        if (!this.company.staffLayoutSize) {
          options[0].selected = true;
        }
        const choiceApi = new Choices(selector, {
          allowHTML: false,
          removeItemButton: false,
          searchEnabled: false,
          choices: options,
          shouldSort: false,
        });

        this.company.staffLayoutSize = choiceApi.getValue().value;
      }
    }, 500);

    setTimeout(() => {
      const selector = document.getElementById("choices-onlineFormMode");
      if (selector) {
        const options = [
          {
            value: "v1",
            label: "v1 (3 steps)",
            selected: "v1" === this.company.onlineFormMode,
          },
          {
            value: "v2",
            label: "v2 (black & white)",
            disabled: this.company.crmMode === "hotel",
            selected: "v2" === this.company.onlineFormMode,
          },
          {
            value: "v3",
            label: "v3 (hotel)",
            disabled: this.company.crmMode !== "hotel",
            selected: "v3" === this.company.onlineFormMode,
          },
          {
            value: "v4",
            label: "v4 (hotel widget)",
            disabled: this.company.crmMode !== "hotel",
            selected: "v4" === this.company.onlineFormMode,
          },
        ];
        if (!this.company.onlineFormMode) {
          options[0].selected = true;
        }
        const choiceApi = new Choices(selector, {
          allowHTML: false,
          removeItemButton: false,
          searchEnabled: false,
          choices: options,
          shouldSort: false,
        });

        this.company.onlineFormMode = choiceApi.getValue().value;
      }
    }, 500);

    setTimeout(() => {
      const selector = document.getElementById(
        "choices-onlineFormSettingsUserPaymentFlow"
      );
      if (selector) {
        const options = [
          {
            value: "paymentFirst",
            label: "paymentFirst",
            selected:
              "paymentFirst" ===
              this.company.onlineFormSettings.userPaymentFlow,
          },
          {
            value: "successFirst",
            label: "successFirst",
            selected:
              "successFirst" ===
              this.company.onlineFormSettings.userPaymentFlow,
          },
        ];
        if (!this.company.onlineFormSettings.userPaymentFlow) {
          options[0].selected = true;
        }
        const choiceApi = new Choices(selector, {
          allowHTML: false,
          removeItemButton: false,
          searchEnabled: false,
          choices: options,
          shouldSort: false,
        });

        this.company.onlineFormSettings.userPaymentFlow =
          choiceApi.getValue().value;
      }
    }, 500);
  },

  methods: {
    async toggleEdit() {
      this.tenantSlug = this.slug;
      this.isEditVisible = !this.isEditVisible;
    },
    async saveSlug() {
      if (this.tenantSlug) {
        try {
          const { slug } = await api.myprofile.setTenantSlug({
            slug: this.tenantSlug,
          });
          if (slug) {
            this.slug = slug;
            this.$store.commit("addToast", {
              title: "Збережено",
            });
          }
        } catch (error) {
          this.$store.commit("addToast", {
            title: "Помилка. Назва вже зайнята",
            type: "error",
          });
        }
      }
      this.isEditVisible = !this.isEditVisible;
    },
    autoResizeTextarea,
    async copyToClipboard(value) {
      const { toClipboard } = useClipboard();
      try {
        await toClipboard(value);
        this.$store.commit("addToast", {
          title: "Скопійовано",
        });
      } catch (error) {
        console.error(error);
      }
    },
    async save() {
      this.company.minMinutesBetweenBookings = 0;
      if (this.minBetweenBookingsHours) {
        this.company.minMinutesBetweenBookings +=
          this.minBetweenBookingsHours * 60;
      }
      if (this.minBetweenBookingsMinutes) {
        this.company.minMinutesBetweenBookings +=
          this.minBetweenBookingsMinutes;
      }

      try {
        await api.company.update({
          photoLogo: this.company.photoLogo,
          photoBackground: this.company.photoBackground,
          requireConfirmationByTelegram:
            this.company.requireConfirmationByTelegram,
          requireConfirmationBySms: this.company.requireConfirmationBySms,
          publicOffer: this.company.publicOffer,
          minMinutesBetweenBookings: this.company.minMinutesBetweenBookings,
          formTimeslotStep: this.company.formTimeslotStep,
          serviceLayoutSize: this.company.serviceLayoutSize,
          staffLayoutSize: this.company.staffLayoutSize,
          onlineFormMode: this.company.onlineFormMode,
          onlineFormPrimaryColor: this.company.onlineFormPrimaryColor,
          onlineFormSettings: {
            userPaymentFlow: this.company.onlineFormSettings.userPaymentFlow,
            calendarShowPrices:
              this.company.onlineFormSettings.calendarShowPrices,
            backgroundColor: this.company.onlineFormSettings.backgroundColor,
            textPrimaryColor: this.company.onlineFormSettings.textPrimaryColor,
            textSecondaryColor:
              this.company.onlineFormSettings.textSecondaryColor,
            primaryButtonBackgroundColor:
              this.company.onlineFormSettings.primaryButtonBackgroundColor,
            primaryButtonFontColor:
              this.company.onlineFormSettings.primaryButtonFontColor,
            bookByPosition: this.company.onlineFormSettings.bookByPosition,
          },
          wayForPay: this.company.wayForPay,
          facebookPixelId: this.company.facebookPixelId,
          googleTagManagerId: this.company.googleTagManagerId,
          confirmPageClientFields: this.company.confirmPageClientFields,
          fullPrepaymentForOneDayBookings:
            this.company.fullPrepaymentForOneDayBookings,
        });

        this.branch.minMinutesBeforeBooking = 0;
        if (this.minBeforeHours) {
          this.branch.minMinutesBeforeBooking += this.minBeforeHours * 60;
        }
        if (this.minBeforeMinutes) {
          this.branch.minMinutesBeforeBooking += this.minBeforeMinutes;
        }
        await api.branches.updateOne(this.branch._id, {
          showCancelledServiceOrders: this.branch.showCancelledServiceOrders,
          minMinutesBeforeBooking: this.branch.minMinutesBeforeBooking,
          mappingNames: this.branch.mappingNames,
          bookingCancelEnabled: this.branch.bookingCancelEnabled,
          bookingCancelAfterHours: this.branch.bookingCancelAfterHours,
        });

        this.$store.commit("addToast", {
          title: "Збережено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    async removePhoto(name) {
      if (!this.company[name]) {
        console.warn("no photo for removing");
        return;
      }

      const payload = {
        key: this.company[name].key,
      };

      const API_URL = `${API_URL_BASE}/settings/staff/removePhoto`;
      const options = {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          "x-access-token": localStorage.getItem("accessToken"),
        },
        body: JSON.stringify(payload),
      };

      const response = await fetch(API_URL, options);
      await response.json();

      this.company[name].key = null;
      this.company[name].url = null;
    },

    getLabelStaffChooseLayoutSize() {
      const localizationMode = this.company?.localizationMode;

      switch (true) {
        case localizationMode === "spa":
          return "Обрати ресурс";
        case localizationMode === "hotel":
          return "Обрати номер";
        default:
          return "Обрати майстра";
      }
    },

    checkFileType(dropzone, file) {
      const allowedTypes = [
        "image/gif",
        "image/jpeg",
        "image/jpg",
        "image/png",
      ];

      if (!allowedTypes.includes(file.type)) {
        dropzone.removeFile(file);

        this.$store.commit("addToast", {
          title: `Формат ${file.type} не підтримується`,
          type: "error",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.color-picker__wrap {
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
  border: 1px solid #d2d6da;
  transition: all 0.3s cubic-bezier(0.5, 0.02, 0.13, 0.5);
  border-radius: 0.375rem !important;
  padding: 5px;
}

.input-group.input-group-outline .color-picker__wrap .form-control {
  border: none !important;
}

.input-group .form-control.form-control.color-picker {
  cursor: pointer;
  padding: 0 !important;
  position: absolute;
  width: 120%;
  left: -2px;
  top: -2px;
  height: 120%;
  border: none !important;
}

.infoBlock {
  color: #458466;
  background-color: #deede3;
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 12px 12px 12px 12px;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}
.row {
  width: 100%;
}
.col-12 {
  width: 100%;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  text-align: left;
}
th,
td {
  padding: 12px;
  border: 1px solid #ddd;
  width: 33.33%;
}
th {
  background-color: #f2f2f2;
}
.form-check {
  display: flex;
  justify-content: center;
}
.available {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: -10px;
}
.element {
  margin-right: 4px;
}

.saveAction {
  margin-left: 15px;
}
</style>
