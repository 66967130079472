<template>
  <div class="container-fluid my-3 py-3" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>

  <div class="container-fluid" v-show="dataLoaded">
    <div class="row">
      <div class="col-lg-12 mt-lg-0">
        <!-- Card Basic Info -->
        <div class="card" id="basic-info">
          <div class="card-body">
            <h6>Загальна інформація</h6>
            <div class="row">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-3 mt-1">
                    <label>Імʼя</label>
                    <InputApp
                      v-model="clientTemplate.firstName"
                      class="text-capitalize"
                      :style="{ fontSize: '14px !important' }"
                    >
                    </InputApp>
                  </div>
                  <div class="col-lg-3 mt-1">
                    <label>Прізвище</label>
                    <InputApp
                      v-model="clientTemplate.lastName"
                      class="text-capitalize"
                      :style="{ fontSize: '14px !important' }"
                    >
                    </InputApp>
                  </div>
                  <div class="col-lg-3 mt-1">
                    <label>Телефон</label>
                    <div class="d-flex gap-2">
                      <div
                        class="input-group input-group-outline has-validation w-auto"
                      >
                        <select
                          class="form-control form-select phonecode text-end"
                          required="required"
                          :disabled="isStaffOnly && mode === 'edit'"
                          v-model="clientTemplate.phoneCode"
                          :style="{ fontSize: '14px !important' }"
                        >
                          <option :value="defaultPhoneCode" selected="selected">
                            {{ defaultPhoneCode }}
                          </option>
                        </select>
                      </div>
                      <div class="flex-grow-1">
                        <InputApp
                          @paste="(e) => onPastePhoneNumber(e, true)"
                          class="form-control"
                          v-model="clientTemplate.phoneNumber"
                          :disabled="isStaffOnly && mode === 'edit'"
                          type="tel"
                          minlength="10"
                          maxlength="10"
                          pattern="[0-9]{10}"
                          :style="{ fontSize: '14px !important' }"
                        >
                          <template #iconBtn>
                            <ButtonIcon
                              :link="`viber://chat?number=%2B38${clientTemplate.phoneNumber}`"
                              :disabled="!clientTemplate.phoneNumber"
                            >
                              <template #icon> <ViberIcon /> </template>
                            </ButtonIcon>
                          </template>
                        </InputApp>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row d-block d-sm-none"
                    v-if="this.clientTemplate.phoneNumber"
                  >
                    <div class="col-lg-6 mt-1">
                      <a :href="`tel:${this.clientTemplate.phoneNumber}`">
                        <button
                          class="btn btn-outline-primary w-100 phoneCallBtn"
                        >
                          <span class="btn-inner--icon"
                            ><i class="material-icons">phone</i></span
                          >
                          Подзвонити {{ this.clientTemplate.phoneNumber }}
                        </button>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-3 mt-1">
                    <label>Email</label>
                    <InputApp
                      v-model="clientTemplate.email"
                      :disabled="isStaffOnly && mode === 'edit'"
                      type="email"
                      :style="{ fontSize: '14px !important' }"
                    >
                    </InputApp>
                  </div>
                  <div class="row">
                    <div class="col-lg-3 mt-2">
                      <label>Дата нарождення</label>
                      <InputApp v-model="clientTemplate.dob">
                        <template #customInput>
                          <flat-pickr
                            v-model="clientTemplate.dob"
                            class="form-control datetimepicker text-sm"
                            :config="calendarConfig"
                          >
                          </flat-pickr>
                        </template>
                      </InputApp>
                    </div>
                    <div class="col-lg-3 mt-2">
                      <label>Instagram нікнейм</label>
                      <InputApp
                        v-model="clientTemplate.linkInstagram"
                        :disabled="isStaffOnly && mode === 'edit'"
                        :style="{ fontSize: '14px !important' }"
                      >
                        <template #iconBtn>
                          <ButtonIcon
                            :link="linkToInstagram"
                            :disabled="!clientTemplate.linkInstagram"
                          >
                            <template #icon> <InstagramIcon /> </template>
                          </ButtonIcon>
                        </template>
                      </InputApp>
                    </div>

                    <div class="col-lg-3 mt-2">
                      <label>Telegram нікнейм</label>
                      <InputApp
                        v-model="clientTemplate.linkTelegram"
                        :disabled="isStaffOnly && mode === 'edit'"
                        :style="{ fontSize: '14px !important' }"
                      >
                        <template #iconBtn>
                          <ButtonIcon
                            :link="linkToTelegram"
                            :disabled="!clientTemplate.linkTelegram"
                          >
                            <template #icon> <TelegramIcon /> </template>
                          </ButtonIcon>
                        </template>
                      </InputApp>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-lg-3">
                      <div>
                        <label class="text-sm">Останній візит: </label>
                        <span class="text-sm mx-1">{{ lastVisitText }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2" v-if="clientTemplate._id">
                    <div
                      class="col-lg-3 infoBlock d-flex align-items-center gap-2"
                    >
                      <span class="text-xs" style="color: #458466"
                        >ID клієнта: {{ clientTemplate._id }}</span
                      >
                      <i
                        class="material-icons text-md ms-2 me-2 cursor-pointer"
                        @click="copyToClipboard(clientTemplate._id)"
                      >
                        copy
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-0" v-if="mode === 'edit' && showDevFeatures">
            <div class="row">
              <div class="badge bg-gradient-info">DEV</div>
              <div class="col-lg-3">
                <div>
                  <label class="text-sm">Візитів:</label>
                  <span class="text-sm"> 84</span>
                </div>
              </div>
              <div class="col-lg-3">
                <div>
                  <label class="text-sm">Прибуток:</label>
                  <span class="text-sm"> 25200 грн</span>
                </div>
              </div>
              <div class="col-lg-3">
                <div>
                  <label class="text-sm">Середній чек:</label>
                  <span class="text-sm"> 300 грн</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Card Basic Info finish -->

        <!-- Photo -->
        <div class="card mt-2" id="photo">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3">
                <div class="row">
                  <div class="w-50">
                    <h6>Фото</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="multisteps-form__content">
              <div class="row mt-3">
                <div
                  class="col-12"
                  v-show="
                    !clientTemplate.photo?.key && !clientTemplate.photo?.url
                  "
                >
                  <div
                    class="form-control border dropzone"
                    id="clientPhoto"
                  ></div>
                </div>
                <div
                  class="col-12"
                  v-show="
                    clientTemplate.photo?.key && clientTemplate.photo?.url
                  "
                >
                  <a target="_blank" :href="clientTemplate.photo?.url">
                    <img
                      alt="client photo"
                      :src="clientTemplate.photo?.url"
                      width="200"
                    />
                  </a>
                  <a
                    class="nav-link text-danger d-flex c-width-200"
                    data-scroll=""
                    href="#"
                  >
                    <i class="material-icons text-lg me-2">delete</i>
                    <span class="text-sm" @click="removePhoto">Видалити</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-0"></div>
        </div>
        <!-- Photo finish  -->

        <!-- Напоминания о записи -->
        <div class="card mt-2 pb-3" id="reminder">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6">
                <div class="row">
                  <div class="w-50">
                    <h6>Нагадування про запис</h6>
                  </div>
                </div>
              </div>
            </div>
            <p class="text-sm mb-0">
              Якщо в
              <a href="/settings/company#reminder"
                ><b>Налаштуваннях Компанії</b></a
              >
              увімкнена функція нагадування, тоді тут ви можете встановити
              індивідуальний графік відправлення нагадувань про запис по СМС або
              Telegram.
            </p>
            <div class="col-lg-4">
              <br />Нагадати за:
              <select
                class="form-control"
                name="choices-reminder"
                id="choices-reminder"
                v-model="clientTemplate.remindBeforeMinutes"
              ></select>
            </div>
          </div>
        </div>
        <!-- Напоминания о записи finish -->

        <!-- Комментарии -->
        <div class="card mt-2 pb-3" id="comments" v-show="dataLoaded">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3">
                <div class="row">
                  <div class="w-50">
                    <h6>Коментарі</h6>
                  </div>
                </div>
              </div>
            </div>
            <p class="text-sm mb-0">
              Запишіть будь-яку інформацію про Клієнта, яка вам здається
              важливою
            </p>
            <div class="input-group input-group-outline">
              <textarea
                type="text"
                rows="2"
                class="form-control"
                v-model="clientTemplate.comment"
                ref="description"
                @input="autoResizeTextarea"
                style="height: 75px"
              ></textarea>
            </div>
          </div>
        </div>
        <!-- Комментарии finish -->

        <!-- Історія використання абонемента -->
        <!-- <div class="card mt-2" v-if="showDevFeatures">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3 mt-2">
                <h6>Історія використання абонементів</h6>
              </div>
              <div class="col-lg-9">
                <div class="row mt-2">
                  Абонемент 2
                  <table class="table table-flush text-xs" id="products-list">
                    <thead class="thead-light">
                      <tr>
                        <th class="text-center">Запис</th>
                        <th class="text-center">Послуга</th>

                        <th class="text-center">Залишок абонементу</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">
                          <span>01.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>12</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>11</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>10</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>9</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>8</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>7</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>6</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>5</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>4</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>3</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>2</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>1</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>0</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  Абонемент 1
                  <table class="table table-flush text-xs" id="products-list">
                    <thead class="thead-light">
                      <tr>
                        <th class="text-center">Запис</th>
                        <th class="text-center">Послуга</th>

                        <th class="text-center">Залишок абонементу</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">
                          <span>01.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>12</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>11</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>10</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>9</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>8</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>7</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>6</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>5</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>4</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>3</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>2</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>1</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <span>03.02.2023 14:00</span>
                        </td>
                        <td class="text-center">
                          <span>Гітара групове</span>
                        </td>
                        <td class="text-center">
                          <span>0</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- Історія використання абонемента finish-->

        <!-- History -->
        <div class="card mt-2" id="history">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3">
                <div class="row">
                  <div class="w-50">
                    <h6>Історія</h6>
                  </div>
                </div>
              </div>
              <div class="col-lg-9">
                <div class="text-end"></div>
              </div>
            </div>
            <div class="table-responsive">
              <div
                class="alert alert-light text-center"
                v-if="!clientOrders?.length"
              >
                Жодного запису 🤨
              </div>

              <table
                class="table table-hover"
                id="datatable-basic"
                v-if="clientOrders?.length"
              >
                <thead class="thead-light">
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    ></th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      style="min-width: 85px"
                      v-if="company.crmMode !== 'hotel'"
                    >
                      Дата запису
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      style="min-width: 85px"
                      v-if="company.crmMode === 'hotel'"
                    >
                      Дата заїзду
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      v-if="company.crmMode !== 'hotel'"
                    >
                      Послуга
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      v-if="company.crmMode !== 'hotel'"
                    >
                      Майстер
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      v-if="company.crmMode === 'hotel'"
                    >
                      Номер
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Сума
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Статус
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Коментар
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="clickable-row"
                    v-for="(order, index) in clientOrders"
                    :key="index"
                  >
                    <td class="text-center font-weight-normal">
                      <router-link
                        :to="`/service-orders/${order._id}`"
                        class="mx-3"
                        data-bs-toggle="tooltip"
                        data-bs-original-title="Переглянути"
                      >
                        <i
                          class="material-icons text-secondary position-relative text-lg"
                        >
                          visibility
                        </i>
                      </router-link>
                    </td>
                    <td class="text-xs text-center font-weight-normal">
                      {{ order.serviceDate }}
                    </td>
                    <td
                      class="text-xs text-center font-weight-normal"
                      v-if="company.crmMode !== 'hotel'"
                    >
                      <div
                        v-for="(service, innerIndex) in order.services"
                        :key="innerIndex"
                      >
                        {{ service?.serviceId?.name }}
                      </div>
                    </td>
                    <td class="text-xs text-center font-weight-normal">
                      {{ order?.staffId?.name }}
                    </td>
                    <td class="text-xs text-center font-weight-normal">
                      {{ getPrice(order) }} грн
                    </td>
                    <td class="text-xs font-weight-normal">
                      <span
                        class="badge badge-dot"
                        style="padding: 0"
                        v-if="order.status === 'done'"
                      >
                        <i class="bg-success"></i>
                        <span class="text-dark text-xs">Клієнт прийшов</span>
                      </span>
                      <span
                        class="badge badge-dot me-4"
                        style="padding: 0"
                        v-if="order.status === 'new'"
                      >
                        <i class="bg-info"></i>
                        <span class="text-dark text-xs">Нова</span>
                      </span>
                      <span
                        class="badge badge-dot me-4"
                        style="padding: 0"
                        v-if="order.status === 'cancelled'"
                      >
                        <i class="bg-danger"></i>
                        <span class="text-dark text-xs">Відмінено</span>
                      </span>
                    </td>
                    <td
                      class="text-xs font-weight-normal"
                      style="white-space: pre-wrap"
                    >
                      {{ order.comment }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row mt-2">
              <div class="col-lg-4"></div>
              <div class="col-lg-4 text-center">
                <ButtonApp
                  @click="goToCreateOrder"
                  :class="'btn-outline-secondary'"
                >
                  Забронювати ще
                </ButtonApp>
              </div>
              <div class="col-lg-4"></div>
            </div>
          </div>
        </div>
        <!-- History finish -->

        <!-- Mobile number status  -->
        <div class="card mt-4" id="status" v-if="showDevFeatures">
          <div class="badge bg-gradient-info">DEV</div>
          <div class="card-header d-flex">
            <h6 class="mb-0">Статус підтвердження номеру телефона</h6>
          </div>
          <div class="card-body">
            <div class="d-flex">
              <p class="my-auto">Через Telegram</p>
              <p class="text-secondary text-sm ms-auto my-auto me-3"></p>
              <span
                class="badge badge-success ms-auto mb-auto"
                v-if="clientTemplate.isConfirmed"
              >
                Підтверджено
              </span>
              <span
                class="badge badge-danger ms-auto mb-auto"
                v-if="!clientTemplate.isConfirmed"
              >
                Не підтверджено
              </span>
            </div>
            <hr class="horizontal dark" />
            <div class="d-flex">
              <p class="my-auto">Через SMS</p>
              <p class="text-secondary text-sm ms-auto my-auto me-3"></p>
              <span
                class="badge badge-success ms-auto mb-auto"
                v-if="clientTemplate.isConfirmed"
              >
                Підтверджено
              </span>
              <span
                class="badge badge-danger ms-auto mb-auto"
                v-if="!clientTemplate.isConfirmed"
              >
                Не підтверджено
              </span>
            </div>
          </div>
        </div>
        <!-- Mobile number status finish  -->

        <!-- Сертифікати -->
        <div class="card mt-4 pb-3" id="certificates" v-if="showDevFeatures">
          <div class="badge bg-gradient-info">DEV</div>
          <div class="card-header">
            <h6>Сертифікати</h6>
            <p class="text-sm mb-0">
              Додайте сертифікат Клієнту і він зможе їм розрахуватись у
              майбутньому
            </p>
            <button
              type="button"
              class="btn btn-sm bg-gradient-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              + сертифікат
            </button>
            <!-- Modal -->
            <div
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5
                      class="modal-title font-weight-normal"
                      id="exampleModalLabel"
                    >
                      + Сертифікат
                    </h5>
                    <button
                      type="button"
                      class="btn-close text-dark"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="form-check mb-3">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="customRadio1"
                      />
                      <label class="custom-control-label" for="customRadio1"
                        >Сертифікат на суму</label
                      >
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="customRadio2"
                      />
                      <label class="custom-control-label" for="customRadio2"
                        >Сертифікат на послугу</label
                      >
                    </div>
                    <div class="input-group input-group-outline mb-2 certsum">
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Сума грн"
                      />
                    </div>
                    <div class="input-group input-group-static">
                      <label for="exampleFormControlSelect2" class="ms-0"
                        >Оберіть послугу (можна декаілька):</label
                      >
                      <select
                        multiple=""
                        class="form-control pb-4"
                        id="exampleFormControlSelect2"
                      >
                        <option>Стрижка</option>
                        <option>Стрижка бороди</option>
                        <option>Масаж спини</option>
                        <option>Масаж стоп</option>
                        <option>Педікюр</option>
                      </select>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn bg-gradient-secondary"
                      data-bs-dismiss="modal"
                    >
                      закрити
                    </button>
                    <button type="button" class="btn bg-gradient-primary">
                      Зберегти
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-hover" id="datatable-search">
              <thead class="thead-light text-center">
                <tr>
                  <th class="text-xs">Дата видачі</th>
                  <th class="text-xs">Дата закінчення</th>
                  <th class="text-xs">На суму/послугу</th>
                  <th class="text-xs">Менеджер</th>
                  <th class="text-xs">Коментар</th>
                  <th class="text-xs">Ціна</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-xs font-weight-normal text-center">
                    <span class="my-2 text-xs">03.05.2022</span>
                  </td>
                  <td class="text-xs font-weight-normal text-center">
                    <span class="my-2 text-xs">03.05.2023</span>
                  </td>
                  <td class="text-xs font-weight-normal text-center">
                    <span class="my-2 text-xs">1000 грн</span>
                  </td>
                  <td class="text-xs font-weight-normal text-center">
                    <span class="my-2 text-xs">Сергей Петров</span>
                  </td>
                  <td class="text-xs font-weight-normal text-center">
                    <span class="my-2 text-xs">блабла</span>
                  </td>
                  <td
                    class="text-xs font-weight-normal text-success text-center"
                  >
                    <span class="my-2 text-xs">1000 грн </span>
                  </td>
                </tr>
                <tr>
                  <td class="text-xs font-weight-normal text-center">
                    <span class="my-2 text-xs">01.05.2022</span>
                  </td>
                  <td class="text-xs font-weight-normal text-center">
                    <span class="my-2 text-xs">03.05.2023</span>
                  </td>
                  <td class="text-xs font-weight-normal text-center">
                    <span class="my-2 text-xs">Стрижка бороди</span>
                  </td>
                  <td class="text-xs font-weight-normal text-center">
                    <span class="my-2 text-xs">Сергей Петров</span>
                  </td>
                  <td class="text-xs font-weight-normal text-center">
                    <span class="my-2 text-xs">блабла</span>
                  </td>
                  <td
                    class="text-xs font-weight-normal text-success text-center"
                  >
                    <span class="my-2 text-xs">400 грн</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Сертифікати finish -->

        <!-- Скидка -->
        <div class="card mt-4 pb-3" id="discount" v-if="showDevFeatures">
          <div class="badge bg-gradient-info">DEV</div>
          <div class="card-header">
            <h6>Знижка</h6>
            <p class="text-sm mb-0">
              Встановіть знижку клієнту та після бронювання він побачить свою
              актуальну ціну з урахуванням знижки.
            </p>
            <div class="col-lg-3 col-sm-12">
              <select
                class="form-control"
                name="choices-discount"
                id="choices-discount"
                v-model="clientTemplate.discountPercent"
              >
                <option value="" selected>Оберіть знижку</option>
                <option value="1">1 %</option>
                <option value="2">2 %</option>
                <option value="3">3 %</option>
                <option value="5">5 %</option>
                <option value="10">10 %</option>
                <option value="20">20 %</option>
                <option value="30">30 %</option>
                <option value="50">50 %</option>
                <option value="75">75 %</option>
                <option value="100">100 %</option>
              </select>
            </div>
          </div>
        </div>
        <!-- Скидка finish -->

        <!-- Черный список -->
        <!-- было бы прикольно сделать, чтобы если клиент в черном списке,
        то когда заходишь в карточку такого клиента, то цвета черного текста и
        белого бекграунда инверсировали в белый текст и черный бекграунд всех карточек
        Если тебе кажется, что тут больше подойдет свитчер как компонент - я не против -->
        <div class="card mt-4" id="blacklist" v-if="showDevFeatures">
          <div class="badge bg-gradient-info">DEV</div>
          <div class="card-body">
            <div class="d-flex align-items-center mb-sm-0 mb-4">
              <div class="w-50">
                <h6>Чорний лист</h6>
                <p class="text-sm mb-0">
                  Додайте клієнта до чорного листа якщо не хочете щоб він міг
                  забронювати послугу через форму онлайн запису.
                </p>
              </div>
              <div class="w-50 text-end">
                <button
                  class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto"
                  type="button"
                  name="button"
                >
                  Відновити
                </button>
                <button
                  class="btn bg-gradient-secondary mb-0 ms-2"
                  type="button"
                  name="button"
                >
                  В чорний лист
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Черный список finish -->

        <!--  Зберегти -->
        <div class="fixed-bottom">
          <div class="row">
            <div class="col-10"></div>
            <div class="col-lg-2">
              <div class="mx-4">
                <button
                  type="button"
                  class="block btn bg-gradient-primary"
                  @click="save"
                  :disabled="!preSaveValidation() || isLoading"
                >
                  <SmallLoader v-if="isLoading" />
                  <span v-else> Зберегти</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--  Зберегти фiнiш-->
      </div>
    </div>
  </div>
</template>
<style scoped>
.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}

.viber {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  background-image: url("../../../assets/img/viber.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.certsum {
  max-width: 50%;
}

.phonecode {
  min-width: 50px;
}

.mylabel {
  margin-left: 0rem;
}
.customHeight {
  height: 120px !important;
}
.text-capitalize {
  text-transform: capitalize;
}
.infoBlock {
  color: #458466;
  background-color: #deede3;
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 12px 12px 12px 12px;
}
.phoneCallBtn {
  margin-bottom: 0px !important;
}
</style>

<script>
import Dropzone from "dropzone";
import Choices from "choices.js";
import { DateTime } from "luxon";
import * as _ from "lodash";

import flatPickr from "vue-flatpickr-component";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";

import config from "@/config";
import api from "@/services/api";
import isStaffOnly from "@/services/isStaffOnly";
import constants from "@/constants";
import validator from "@/utils/validator";
import timeDisplay from "@/utils/timeDisplay";
import SmallLoader from "@/views/shared/SmallLoader.vue";
import ButtonApp from "@/components/common/other/ButtonApp.vue";
import router from "@/router/internal";
import ButtonIcon from "@/components/common/other/ButtonIcon.vue";
import ViberIcon from "@/components/icons/ViberIcon.vue";
import InstagramIcon from "@/components/icons/InstagramIcon.vue";
import TelegramIcon from "@/components/icons/TelegramIcon.vue";
import InputApp from "@/components/common/inputs/InputApp.vue";
import Spinner from "@/views/shared/Spinner.vue";
import { mask } from "vue-the-mask";
const API_URL_BASE = config.API_URL_BASE;

import { autoResizeTextarea } from "@/components/common/functions/autoResizeTextarea";
import useClipboard from "vue-clipboard3";

// import setTooltip from "@/assets/js/tooltip.js";

const getLastVisitText = (lastVisit) => {
  if (!lastVisit) {
    return "ніколи";
  }
  const dateNow = DateTime.now();
  const dateLastVisit = DateTime.fromISO(lastVisit);

  const diff = dateNow.diff(dateLastVisit, "days");
  const days = Math.floor(diff.days);

  if (!days) {
    return "сьогодні";
  }

  let daysText = "днів";
  if (days === 1) {
    daysText = "день";
  } else if (days <= 4) {
    daysText = "дні";
  }

  const formattedDate = dateLastVisit.setLocale("uk").toFormat("dd.LL.yyyy");
  return `${days} ${daysText} тому (${formattedDate})`;
};

export default {
  name: "client-details",
  directives: { mask },
  components: {
    Spinner,
    InputApp,
    TelegramIcon,
    InstagramIcon,
    ViberIcon,
    ButtonIcon,
    ButtonApp,
    SmallLoader,
    flatPickr,
  },

  async beforeMount() {
    this.showDevFeatures = !!localStorage.getItem("showDevFeatures");
  },

  async mounted() {
    this.company = await api.company.show();

    if (this.$route.params.id) {
      this.mode = "edit";
      this.clientTemplate = await api.clients.findOne(this.$route.params.id);

      this.clientOrders = await api.serviceOrders.list({
        clientId: this.$route.params.id,
        limit: 50,
        sortField: "serviceDate",
        sortDir: "desc",
      });

      this.lastVisitText = getLastVisitText(this.clientTemplate.lastVisitAt);

      if (this.clientTemplate.phoneNumber) {
        this.clientTemplate.phoneCode = this.clientTemplate.phoneNumber.slice(
          0,
          3
        );
        this.clientTemplate.phoneNumber =
          this.clientTemplate.phoneNumber.slice(3);
      } else {
        this.clientTemplate.phoneCode = constants.defaultPhoneCode;
      }
    } else {
      this.mode = "create";
    }

    setTimeout(() => {
      // setTooltip();
      const selector = document.getElementById("choices-reminder");
      if (selector) {
        const durationOptions = [
          {
            value: -2,
            label: "Із налаштувань Онлайн запису",
            selected: -1 === this.clientTemplate.remindBeforeMinutes,
          },
          {
            value: -1,
            label: "Не нагадувати",
            selected: -1 === this.clientTemplate.remindBeforeMinutes,
          },
          {
            value: 60,
            label: timeDisplay.minutesToHumanFormat(60),
            selected: 60 === this.clientTemplate.remindBeforeMinutes,
          },
          {
            value: 120,
            label: timeDisplay.minutesToHumanFormat(120),
            selected: 120 === this.clientTemplate.remindBeforeMinutes,
          },
          {
            value: 180,
            label: timeDisplay.minutesToHumanFormat(180),
            selected: 180 === this.clientTemplate.remindBeforeMinutes,
          },
          {
            value: 240,
            label: timeDisplay.minutesToHumanFormat(240),
            selected: 240 === this.clientTemplate.remindBeforeMinutes,
          },
          {
            value: 300,
            label: timeDisplay.minutesToHumanFormat(300),
            selected: 300 === this.clientTemplate.remindBeforeMinutes,
          },
          {
            value: 1440, // 24 hours
            label: timeDisplay.minutesToHumanFormat(1440),
            selected: 1440 === this.clientTemplate.remindBeforeMinutes,
          },
          {
            value: 2880, // 48 hours
            label: timeDisplay.minutesToHumanFormat(2880),
            selected: 2880 === this.clientTemplate.remindBeforeMinutes,
          },
          {
            value: 4320, // 72 hours
            label: timeDisplay.minutesToHumanFormat(4320),
            selected: 4320 === this.clientTemplate.remindBeforeMinutes,
          },
        ];
        const choiceApi = new Choices(selector, {
          allowHTML: false,
          removeItemButton: false,
          searchEnabled: false,
          choices: durationOptions,
          shouldSort: false,
        });
        if (!this.clientTemplate.remindBeforeMinutes) {
          durationOptions[0].selected = true;
        }

        this.clientTemplate.remindBeforeMinutes =
          choiceApi.getValue().value || null;
      }
    }, 500);

    setTimeout(() => {
      const selectorDiscount = document.getElementById("choices-discount");
      if (selectorDiscount) {
        new Choices(selectorDiscount, {
          allowHTML: true,
          removeItemButton: true,
        });
      }
    });

    this.dataLoaded = true;

    setTimeout(autoResizeTextarea, 1000, {
      target: this.$refs.description,
    });

    const dropzoneOptions = {
      url: `${API_URL_BASE}/clients/uploadPhoto`,
      method: "POST",
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
      },
      paramName: "file",
      maxFiles: 1,
      maxFilesize: 2 * 1024 * 1024, // 2 MB
      createImageThumbnails: true,
      addRemoveLinks: false,
    };
    const dropzone = new Dropzone("#clientPhoto", dropzoneOptions);

    dropzone.on("success", (file, result) => {
      this.clientTemplate.photo = {
        key: result.photoKey,
        url: result.photoUrl,
      };

      dropzone.removeFile(file);
    });

    dropzone.on("error", (file, message) => {
      console.warn(message);
    });
  },

  data() {
    return {
      isLoading: false,
      showDevFeatures: false,
      calendarConfig: {
        mode: "single",
        dateFormat: "Y-m-d",
        locale: Ukrainian,
        inline: false,
        maxDate: "today",
        disableMobile: true,
      },

      mode: "", // create | edit
      dataLoaded: false,
      isStaffOnly: isStaffOnly(),

      defaultPhoneCode: constants.defaultPhoneCode,

      company: {
        crmMode: "",
      },

      lastVisitText: "",
      clientTemplate: {
        firstName: "",
        lastName: "",
        phoneCode: constants.defaultPhoneCode,
        phoneNumber: "",
        email: "",
        photo: {
          key: "",
          url: "",
        },
        comment: "",
        dob: "",
        isConfirmed: false,
        lastVisitAt: "",
        remindBeforeMinutes: null,
        discountPercent: null,
        linkInstagram: null,
        linkTelegram: null,
        isRemoved: false,
      },

      clientOrders: [],

      copyIconTooltipText: "Скопировать",
    };
  },

  computed: {
    linkToTelegram() {
      if (
        this.clientTemplate.linkTelegram &&
        this.clientTemplate.linkTelegram.startsWith("https://")
      ) {
        return this.clientTemplate.linkTelegram;
      }

      return `https://t.me/${this.clientTemplate.linkTelegram}`;
    },

    linkToInstagram() {
      if (
        this.clientTemplate.linkInstagram &&
        this.clientTemplate.linkInstagram.startsWith("https://")
      ) {
        return this.clientTemplate.linkInstagram;
      }

      return `https://www.instagram.com/${this.clientTemplate.linkInstagram}`;
    },
  },

  methods: {
    autoResizeTextarea,
    normalizePhoneNumber(input) {
      if (!input) {
        return "";
      }
      const normalized = input.replace(/\D/g, "");
      if (!normalized) {
        return "";
      }
      if (normalized.length === 12 && normalized.startsWith("38")) {
        return normalized.slice(2);
      }
      return normalized;
    },
    onPastePhoneNumber(event, fromModal = false) {
      event.preventDefault();
      const clipboard = event.clipboardData;
      const text = clipboard.getData("Text");
      if (fromModal) {
        this.clientTemplate.phoneNumber = this.normalizePhoneNumber(text);
      } else {
        this.clientTemplate.phoneNumber = this.normalizePhoneNumber(text);
      }
    },
    goToCreateOrder() {
      router.push({
        path: "/service-orders/new",
        query: { clientID: this.$route.params.id },
      });
    },
    async save() {
      this.clientTemplate.remindBeforeMinutes =
        this.clientTemplate.remindBeforeMinutes || null;

      const payload = { ...this.clientTemplate };
      if (payload.phoneNumber) {
        payload.phoneNumber = `${this.clientTemplate.phoneCode}${this.clientTemplate.phoneNumber}`;
      } else {
        payload.phoneNumber = "";
      }
      delete payload.phoneCode;

      try {
        this.isLoading = true;
        if (this.mode === "create") {
          await api.clients.create(payload);
        } else if (this.mode === "edit") {
          await api.clients.updateOne(this.$route.params.id, payload);
        }

        this.$store.commit("addToast", {
          title: "Збережено",
        });

        await this.$router.push("/clients");
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async copyToClipboard(value) {
      const { toClipboard } = useClipboard();
      try {
        await toClipboard(value);
        this.$store.commit("addToast", {
          title: "Скопійовано",
        });
      } catch (error) {
        console.error(error);
      }
    },

    preSaveValidation() {
      const phoneNumber = this.clientTemplate.phoneNumber;
      if (phoneNumber) {
        if (this.isStaffOnly) {
          return true;
        }
        if (!validator.phoneNumber(phoneNumber)) {
          return false;
        }
      }

      return true;
    },

    async removePhoto() {
      const payload = {
        key: this.clientTemplate.photo.key,
        url: this.clientTemplate.photo.url,
      };
      const API_URL = `${API_URL_BASE}/clients/removePhoto`;
      const options = {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          "x-access-token": localStorage.getItem("accessToken"),
        },
        body: JSON.stringify(payload),
      };

      const response = await fetch(API_URL, options);
      await response.json();

      this.clientTemplate.photo.key = null;
      this.clientTemplate.photo.url = null;
    },

    getPrice(order) {
      return order.fixedFinalPrice;
    },
  },
};
</script>
