<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import useClipboard from "vue-clipboard3";
import RoomSelect from "@/components/integrations/widget/RoomSelect.vue";

const store = useStore();

const room = ref(null);
const tenantName = ref(store.state.tenantName);
const iframeUrl = computed(
  () =>
    // `http://localhost:8081/t/${tenantName.value}/room/${room.value.id}?isWidget=1`
    `https://go.bookmenow.pro/t/${tenantName.value}/room/${room.value.id}?isWidget=1`
);
const jsUrl = computed(
  // () => `http://localhost:8080/widget-room.js?tenant=${tenantName.value}`
  () =>
    `https://bmn-production.s3.eu-central-1.amazonaws.com/f/widgetForm/widget-room.js?tenant=${tenantName.value}`
);

async function copyToClipboard(content) {
  const { toClipboard } = useClipboard();

  try {
    await toClipboard(content);

    store.commit("addToast", {
      title: "Скопійовано",
    });
  } catch (error) {
    console.error(error);
  }
}
</script>

<template>
  <div class="card">
    <div class="card-header">
      <h5>Календар обраного номера</h5>
    </div>

    <div class="card-body pt-0">
      <RoomSelect v-model="room" class="mb-3" />

      <div v-if="room?.id">
        <p class="text-sm">Попередній перегляд:</p>

        <iframe
          :src="iframeUrl"
          allowtransparency="true"
          width="100%"
          height="600"
        ></iframe>

        <p class="text-sm">Додайте цей код на ваш сайт в розділ head</p>

        <div
          class="d-sm-flex bg-gray-100 border-radius-lg p-2 my-4 pt-4 position-relative"
        >
          <span
            class="cursor-pointer position-absolute top-4 end-2 d-inline-flex align-items-center justify-content-center"
          >
            <i
              class="material-icons text-lg cursor-pointer"
              @click="
                copyToClipboard(
                  `<script src=&quot;${jsUrl}&quot; charset=&quot;UTF-8&quot;></script>`
                )
              "
            >
              copy
            </i>
          </span>
          <pre class="code-style">
&lt;script src="{{ jsUrl }}" charset="UTF-8"&gt;&lt;/script&gt;</pre
          >
        </div>

        <p class="text-sm">
          Додайте цей код на сторінці там де потрібно відображати віджет.
        </p>

        <div
          class="d-sm-flex bg-gray-100 border-radius-lg p-2 my-4 pt-4 position-relative"
        >
          <span
            class="cursor-pointer position-absolute top-4 end-2 d-inline-flex align-items-center justify-content-center"
          >
            <i
              class="material-icons text-lg cursor-pointer"
              @click="
                copyToClipboard(
                  `<div class=&quot;bmn-widget-room&quot; data-room-id=&quot;${room.id}&quot;></div>`
                )
              "
            >
              copy
            </i>
          </span>

          <pre class="code-style">
&lt;div class="bmn-widget-room" data-room-id="{{
              room.id
            }}"&gt;&lt;/div&gt;</pre
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.code-style {
  color: #d63384;
}
</style>
