const RE_PHONE_NUMBER = /^\d{10}$/;

const phoneNumber = (input) => {
  if (!input) {
    return false;
  }
  if (!RE_PHONE_NUMBER.test(input)) {
    return false;
  }
  return true;
};

module.exports = {
  phoneNumber,
};
