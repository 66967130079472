<template>
  <div
    ref="modalRef"
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            v-if="attribute"
            class="modal-title font-weight-normal"
            id="exampleModalLabel"
          >
            Редагувати категорію: {{ attribute.label }}
          </h5>
          <h5
            v-else
            class="modal-title font-weight-normal"
            id="exampleModalLabel"
          >
            Нова категорія
          </h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="attribute?.key" class="mb-3">
            <p v-if="attribute.key === 'no-category'">
              Це системна категорія. <br />Використовується для транзакцій по
              замовчуванню
            </p>
            <p v-if="attribute.key === 'reservation-payment'">
              Це системна категорія. <br />Використовується для оплат по
              бронюванню
            </p>
            <p v-if="attribute.key === 'reservation-refund'">
              Це системна категорія. <br />Використовується для повернення по
              бронюванню
            </p>
          </div>
          <label>Назва категорії</label>
          <div class="row">
            <div class="col-lg-12">
              <div class="input-group input-group-outline">
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.label"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-primary"
            data-bs-dismiss="modal"
            @click="$emit('close')"
          >
            Закрити
          </button>
          <button
            type="button"
            class="btn bg-gradient-primary"
            @click="save"
            :disabled="!formData.label"
          >
            Зберегти
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import api from "@/services/api";
import {
  ref,
  reactive,
  onMounted,
  defineEmits,
  onBeforeUnmount,
  defineProps,
} from "vue";

import { useStore } from "vuex";
import Modal from "bootstrap/js/dist/modal";

const emit = defineEmits(["submit", "close"]);
const store = useStore();

const props = defineProps({
  attribute: {
    type: Object,
  },
});

const formData = reactive({
  label: props.attribute ? props.attribute.label : "",
});

const modalRef = ref(null);
const modalInstance = ref(null);
async function save() {
  try {
    if (props.attribute) {
      await api.financeCategories.update(props.attribute._id, formData);
    } else {
      await api.financeCategories.create(formData);
    }

    store.commit("addToast", {
      title: "Збережено",
    });

    emit("submit");
  } catch (error) {
    console.error(error);
    store.commit("addToast", {
      title: "Виникла помилка. Спробуйте ще раз",
      type: "error",
    });
  }
}

onMounted(() => {
  modalInstance.value = new Modal(modalRef.value, {});
  modalInstance.value.show();
});
onBeforeUnmount(() => {
  modalInstance.value.hide();
});
</script>
