<script setup>
import StepIntro from "@/views/pages/hotelonboarding/steps/StepIntro.vue";
import StepName from "@/views/pages/hotelonboarding/steps/StepName.vue";
import StepMaxGuests from "@/views/pages/hotelonboarding/steps/StepMaxGuests.vue";
import StepCheckinCheckout from "@/views/pages/hotelonboarding/steps/StepCheckinCheckout.vue";
import { integerTransformer, useRouteQuery } from "vue-use-route-query";
import StepMainPhoto from "@/views/pages/hotelonboarding/steps/StepMainPhoto.vue";
import StepFinish from "@/views/pages/hotelonboarding/steps/StepFinish.vue";
import StepOffers from "@/views/pages/hotelonboarding/steps/StepOffers.vue";
import { ref } from "vue";
import api from "@/services/api";
import { useStore } from "vuex";

const storeToast = useStore();
const isLoading = ref(false);

const currentStep = useRouteQuery("currentStep", 0, integerTransformer);
const name = useRouteQuery("name", "");
const maxGuests = useRouteQuery("maxGuests", 2, integerTransformer);
const checkinFrom = useRouteQuery("checkinFrom", "14:00");
const checkinTo = useRouteQuery("checkinTo", "20:00");
const checkout = useRouteQuery("checkout", "12:00");
const amenities = useRouteQuery("amenities", []);
const idCreatedNumber = useRouteQuery("idRoom", "");

const steps = [
  {
    component: StepIntro,
    stepSkip: false,
    nameField: "none",
    props: {},
  },
  {
    component: StepName,
    stepSkip: false,
    nameField: "name",
    props: {
      modelValue: name.value,
    },
  },
  {
    component: StepMaxGuests,
    stepSkip: false,
    nameField: "maxGuests",
    props: {
      modelValue: maxGuests.value,
    },
  },
  {
    component: StepCheckinCheckout,
    stepSkip: false,
    nameField: "checkinCheckout",
    props: {
      checkinFrom: checkinFrom.value,
      checkinTo: checkinTo.value,
      checkout: checkout.value,
    },
  },
  {
    component: StepOffers,
    stepSkip: true,
    nameField: "amenities",
    props: {
      amenities: amenities.value.length > 0 ? JSON.parse(amenities.value) : [],
    },
  },
  {
    component: StepMainPhoto,
    stepSkip: true,
    nameField: "photo",
  },
  {
    component: StepFinish,
    stepSkip: false,
    nameField: "finish",
  },
];

async function nextStep(val, nameVal, isFinish = false) {
  if (nameVal === "name") {
    name.value = val;
  }
  if (nameVal === "maxGuests") {
    maxGuests.value = val;
  }
  if (nameVal === "checkinCheckout") {
    checkinFrom.value = val.checkinFrom;
    checkinTo.value = val.checkinTo;
    checkout.value = val.checkout;
  }
  if (nameVal === "amenities") {
    amenities.value = JSON.stringify(getIdsFromAmenities(val));
    dataPayloadTemplate.value.amenities = val;
  }
  if (nameVal === "photo") {
    dataPayloadTemplate.value.photo.key = val.key;
    dataPayloadTemplate.value.photo.url = val.url;
  }

  if (!isFinish) {
    currentStep.value++;
    return;
  }
  await createRoom();
}

const dataPayloadTemplate = ref({
  isActive: true,
  onlineBooking: true,
  amenities: [],
  checkinRules: {
    guestsMax: 2,
    checkinTime: {
      from: "15:00",
      to: "21:00",
    },
    checkoutTime: "12:00",
    smartCheckin: {
      enabled: false,
      iconName: "CheckInIcon",
      text: "",
      description: "",
    },
    petsFriendly: {
      enabled: false,
      iconName: "PetsIcon",
      text: "",
      description: "",
    },
    children: {
      enabled: false,
      iconName: "KidsIcon",
      text: "",
      description: "",
    },
  },
  name: "",
  photo: {
    key: "",
    url: "",
  },
  cancellationPolicy: {
    enabled: false,
    rules: [
      {
        enabled: true,
        rule: "greaterThen",
        days: 10,
        returnPercentage: 100,
      },
      {
        enabled: true,
        rule: "lessThen",
        days: 10,
        returnPercentage: 50,
      },
      {
        enabled: true,
        rule: "lessThen",
        days: 3,
        returnPercentage: 0,
      },
    ],
  },
  isRemoved: false,
  hotelOnboarding: true,
});

async function getAmenities() {
  let arrAmenities = [];
  try {
    const res = await api.staff.getDefaultAmenities();
    if (res) {
      arrAmenities = mappingAmenities(res);
    }
  } catch (error) {
    console.error(error);
  }
  return arrAmenities;
}
function mappingAmenities(amenities) {
  let newArray = [];
  if (!amenities) {
    return newArray;
  }

  for (let key in amenities) {
    newArray.push({
      id: key,
      title: amenities[key][0].sectionName,
      items: amenities[key],
    });
  }

  newArray = newArray.reduce((acc, current) => {
    return [...acc, ...current.items.filter((item) => item.enabled)];
  }, []);

  return newArray;
}
function getIdsFromAmenities(amenitiesArr) {
  return amenitiesArr.map((item) => item.uid);
}
function goToStep(index) {
  if (index < currentStep.value && currentStep.value !== steps.length - 1) {
    currentStep.value = index;
  }
}

async function createRoom() {
  if (dataPayloadTemplate.value.amenities.length === 0) {
    dataPayloadTemplate.value.amenities = await getAmenities();
  }
  try {
    isLoading.value = true;
    dataPayloadTemplate.value.name = name.value;
    dataPayloadTemplate.value.checkinRules.checkinTime.from = checkinFrom.value;
    dataPayloadTemplate.value.checkinRules.checkinTime.to = checkinTo.value;
    dataPayloadTemplate.value.checkinRules.checkoutTime = checkout.value;
    dataPayloadTemplate.value.checkinRules.guestsMax = maxGuests.value;

    const res = await api.staff.create(dataPayloadTemplate.value);
    if (res) {
      currentStep.value++;
      idCreatedNumber.value = res._id;
    }
  } catch (error) {
    storeToast.commit("addToast", {
      title: "Виникла помилка. Спробуйте ще раз",
      type: "error",
    });
  } finally {
    isLoading.value = false;
  }
}
</script>
<template>
  <div class="container-fluid my-3">
    <div class="row mb-5">
      <div class="col-lg-12">
        <div class="card">
          <div class="steps">
            <div
              v-for="(_, index) of steps"
              :key="index"
              :class="{
                active: index === currentStep,
                completed: index < currentStep,
              }"
              class="step"
            >
              <div @click="goToStep(index)" class="step__dot"></div>
            </div>
          </div>
          <Transition name="fade">
            <keep-alive>
              <component
                @next-step="nextStep"
                :is="steps[currentStep].component"
                :skip="steps[currentStep].stepSkip"
                :is-loading="isLoading"
                v-bind="{
                  ...steps[currentStep].props,
                  nameField: steps[currentStep].nameField,
                }"
                class="tab"
              ></component>
            </keep-alive>
          </Transition>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.steps {
  padding: 16px 0px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  max-width: max-content;
  margin: 0 auto;
  transform: translateY(-50%);
}

.step {
  padding-right: 20px;
  position: relative;

  &::before {
    content: "";
    opacity: 0.5;
    background: #fff;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 16px;
    width: calc(100% - 16px);
    transform: translateY(-50%);
    transition: all 0.3s cubic-bezier(0.5, 0.02, 0.13, 0.5);
  }

  &::after {
    content: "";
    opacity: 1;
    background: black;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 16px;
    width: 0;
    transform: translateY(-50%);
    transition: all 0.3s cubic-bezier(0.5, 0.02, 0.13, 0.5);
  }

  &:last-child {
    padding-right: 0;

    &::after {
      display: none;
    }

    &::before {
      display: none;
    }
  }

  &.completed {
    .step__dot {
      opacity: 1;
    }

    &::after {
      width: 100%;
    }
  }

  &.active {
    .step__dot {
      opacity: 1;
      &::before {
        opacity: 1;
      }
    }
  }

  &__dot {
    position: relative;
    cursor: pointer;
    width: 16px;
    height: 16px;
    background: black;
    border-radius: 50%;
    opacity: 0.5;
    transition: all 0.3s cubic-bezier(0.5, 0.02, 0.13, 0.5);
    transform-origin: center;
    &::before {
      content: "";
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 5px black;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 50%;
      opacity: 0;
      transition: all 0.3s cubic-bezier(0.5, 0.02, 0.13, 0.5);
    }
  }
}
</style>
