const getUrlApi = () => {
  if (process.env.VUE_APP_API_URL_OVERRIDE) {
    return process.env.VUE_APP_API_URL_OVERRIDE;
  }

  switch (process.env.NODE_ENV) {
    case "production":
      return "https://api.bookmenow.pro/v1";
    case "development":
    case "local":
    default:
      return "http://localhost:3021/v1";
  }
};

const getUrlWebApp = () => {
  switch (process.env.NODE_ENV) {
    case "production":
      return "https://app.bookmenow.pro";
    case "development":
    case "local":
    default:
      return "http://localhost:8080";
  }
};

const getUrlClientForm = () => {
  switch (process.env.NODE_ENV) {
    case "production":
      return "https://go.bookmenow.pro";
    case "development":
    case "local":
    default:
      return "http://localhost:8081";
  }
};

const config = {
  API_URL_BASE: getUrlApi(),
  WEBAPP_URL_BASE: getUrlWebApp(),
  CLIENT_FORM_URL_BASE: getUrlClientForm(),
};

export default config;
