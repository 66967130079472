<script setup>
import { defineEmits, defineProps } from "vue";
import { useRouter } from "vue-router";
import SmallLoader from "@/views/shared/SmallLoader.vue";

const props = defineProps({
  type: { type: String, required: false, default: () => "button" },
  to: { type: String, required: false, default: () => "" },
  class: { type: String, required: false, default: () => "" },
  style: { type: Object, required: false, default: () => null },
  loading: { type: Boolean, required: false, default: () => false },
  disabled: { type: Boolean, required: false, default: () => false },
  id: { type: String, required: false, default: () => "" },
});

const router = useRouter();

const emit = defineEmits(["click"]);

const click = () => {
  if (props.to && router.currentRoute.value.path !== props.to) {
    router.push(props.to);
  }
  emit("click");
};
</script>
<template>
  <button
    v-if="!to"
    :type="type"
    @click="click"
    class="btn"
    :class="props.class"
    :style="{
      ...style,
      cursor: loading ? 'wait' : disabled ? 'not-allowed' : 'pointer',
    }"
    :disabled="disabled || loading"
    :id="id"
  >
    <small-loader class="lds-ring" v-if="loading"></small-loader>
    <span :class="{ 'hide-text': loading }"><slot /></span>
  </button>
  <router-link v-if="to" :to="to" :style="style" class="page-btn-link">
    <button
      type="button"
      @click="click"
      class="btn"
      :class="props.class"
      :style="{
        cursor: loading ? 'wait' : disabled ? 'not-allowed' : 'pointer',
      }"
      :disabled="disabled || loading"
    >
      <slot />
    </button>
  </router-link>
</template>
<style scoped lang="scss">
.btn {
  position: relative;
  .hide-text {
    opacity: 0;
  }
  .lds-ring {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
</style>
