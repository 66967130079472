<template>
  <!-- ALL WAREHOUSE IS NOW MVP -->
  <div class="py-4 container-fluid">
    <div class="d-sm-flex justify-content-between">
      <div>
        <router-link
          to="/warehouse/orders/new-product-order"
          class="btn btn-icon bg-gradient-primary"
        >
          + Продажа
        </router-link>
      </div>
      <div class="d-flex">
        <div class="dropdown d-inline">
          <a
            href="javascript:;"
            class="btn btn-outline-dark dropdown-toggle"
            data-bs-toggle="dropdown"
            id="navbarDropdownMenuLink2"
          >
            Фильтры
          </a>
          <ul
            class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
            aria-labelledby="navbarDropdownMenuLink2"
            data-popper-placement="left-start"
          >
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Статус: Черновик</a
              >
            </li>
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Статус: Продано</a
              >
            </li>
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Статус: Отменен</a
              >
            </li>
            <li>
              <hr class="horizontal dark my-2" />
            </li>
            <li>
              <a
                class="dropdown-item border-radius-md text-danger"
                href="javascript:;"
                >Отменить фильтры</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Все продажи</h5>
                <p class="mb-0 text-sm"></p>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table class="table table-flush" id="products-list">
                <thead class="thead-light">
                  <tr>
                    <th>Id</th>
                    <th>Дата</th>
                    <th>Товар</th>
                    <th>Клиент</th>
                    <th>Цена продажи</th>
                    <th>Количество</th>
                    <th>Статус</th>
                    <th>Сумма</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="d-flex">
                        <div class="my-auto form-check">
                          <a
                            href="javascript:;"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Посмотреть"
                          >
                            <i class="fas fa-eye text-secondary"></i>
                          </a>
                        </div>
                        <div class="my-auto form-check">
                          <span class="my-2 text-xs">#10421</span>
                        </div>
                      </div>
                    </td>
                    <td class="text-sm">23.05.2022</td>
                    <td class="text-sm text-center">
                      <div class="d-flex">
                        <span>Помада</span>
                      </div>
                    </td>
                    <td class="text-sm">Артем Павлов</td>
                    <td class="text-sm">1350 грн</td>
                    <td class="text-sm">5 шт</td>
                    <td class="text-sm">
                      <span class="badge bg-gradient-secondary">Черновик</span>
                      <span class="my-2 text-xs"></span>
                    </td>
                    <td class="text-sm">6755 грн</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="d-flex">
                        <div class="my-auto form-check">
                          <a
                            href="javascript:;"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Посмотреть"
                          >
                            <i class="fas fa-eye text-secondary"></i>
                          </a>
                        </div>
                        <div class="my-auto form-check">
                          <span class="my-2 text-xs">#10421</span>
                        </div>
                      </div>
                    </td>
                    <td class="text-sm">23.05.2022</td>
                    <td class="text-sm text-center">
                      <div class="d-flex">
                        <span>Шампунь</span>
                      </div>
                    </td>
                    <td class="text-sm">Артем Павлов</td>
                    <td class="text-sm">1350 грн</td>
                    <td class="text-sm">5 кг</td>
                    <td class="text-sm">
                      <span class="badge bg-gradient-success">Продано</span>
                      <span class="my-2 text-xs"></span>
                    </td>
                    <td class="text-sm">6755 грн</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="d-flex">
                        <div class="my-auto form-check">
                          <a
                            href="javascript:;"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Посмотреть"
                          >
                            <i class="fas fa-eye text-secondary"></i>
                          </a>
                        </div>
                        <div class="my-auto form-check">
                          <span class="my-2 text-xs">#10421</span>
                        </div>
                      </div>
                    </td>
                    <td class="text-sm">23.05.2022</td>
                    <td class="text-sm text-center">
                      <div class="d-flex">
                        <span>Карандаш</span>
                      </div>
                    </td>
                    <td class="text-sm">Артем Павлов</td>
                    <td class="text-sm">1350 грн</td>
                    <td class="text-sm">5 л</td>
                    <td class="text-sm">
                      <span class="badge bg-gradient-danger">Отменен</span>
                      <span class="my-2 text-xs"></span>
                    </td>
                    <td class="text-sm">6755 грн</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import setTooltip from "@/assets/js/tooltip.js";

export default {
  name: "products-list",
  mounted() {
    if (document.getElementById("products-list")) {
      const dataTableSearch = new DataTable("#products-list", {
        searchable: true,
        fixedHeight: false,
        perPage: 25,
      });

      document.querySelectorAll(".export").forEach(function (el) {
        el.addEventListener("click", function () {
          var type = el.dataset.type;

          var data = {
            type: type,
            filename: "soft-ui-" + type,
          };

          if (type === "csv") {
            data.columnDelimiter = "|";
          }

          dataTableSearch.export(data);
        });
      });
    }
    setTooltip();
  },
};
</script>
