<template>Logging out...</template>

<script>
export default {
  name: "logout",

  components: {},

  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
  },

  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
  },

  async mounted() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("app.companyName");
    localStorage.removeItem("localUser");
    localStorage.removeItem("showDevFeatures");

    this.$store.commit("unsetAll");

    const reason = this.$route.query.reason;

    const switchAccessToken = localStorage.getItem("switch-accessToken");
    if (switchAccessToken) {
      localStorage.setItem("accessToken", switchAccessToken);
      localStorage.removeItem("switch-accessToken");
      window.location.href = "/";

      return;
    }

    let redirectUrl = "/authentication/signin";
    if (reason === "jwtExpired") {
      redirectUrl = `${redirectUrl}?reason=jwtExpired`;
    }
    window.location.href = redirectUrl;
  },
};
</script>
