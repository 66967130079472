import { createStore } from "vuex";

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-gradient-dark",
    isRTL: false,
    mcolor: "",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    isDarkMode: false,
    tenantName: null,
    toasts: [],
  },
  mutations: {
    setTenantName(state, tenantName) {
      state.tenantName = tenantName;
    },

    unsetAll(state) {
      state.tenantName = null;
    },
    addToast(state, payload) {
      state.toasts.push({
        ...payload,
        id: Date.now(),
      });
    },
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenavShow = document.getElementsByClassName("g-sidenav-show")[0];

      if (sidenavShow.classList.contains("g-sidenav-pinned")) {
        sidenavShow.classList.remove("g-sidenav-pinned");
        sidenavShow.classList.add("g-sidenav-hidden");
        state.isPinned = true;
      } else {
        sidenavShow.classList.remove("g-sidenav-hidden");
        sidenavShow.classList.add("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    navbarClose(state) {
      const sidenavShow = document.getElementsByClassName("g-sidenav-show")[0];
      sidenavShow.classList.remove("g-sidenav-pinned");
      sidenavShow.classList.add("g-sidenav-hidden");
      state.isPinned = true;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
  },
  getters: {},
});
