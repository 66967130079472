<script setup>
import Navbar from "@/examples/Navbars/Navbar.vue";
import Sidenav from "@/examples/Sidenav/index.vue";
import AppFooter from "@/examples/Footer.vue";
import { useStore } from "vuex";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useBreakpoints } from "@/composables/common/useBreakpoint";
import { useCompanyStore } from "@/storePinia/company";
import { storeToRefs } from "pinia";

const store = useStore();
const route = useRoute();

const { isMobile } = useBreakpoints();

const storeCompany = useCompanyStore();
const { company } = storeToRefs(storeCompany);

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const isLoading = computed(() => {
  return props.isLoading;
});

const navClasses = computed(() => {
  return {
    "position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky":
      store.state.isNavFixed,
    "position-absolute px-4 mx-0 w-100 z-index-2": store.state.isAbsolute,
    "px-0 mx-4 mt-4": store.state.isAbsolute,
  };
});
const showNavbar = computed(() => {
  return (
    store.state.showSidenav &&
    !(storeCompany.isEmptyTenant && !storeCompany.companyIsHotel) &&
    !isLoading.value
  );
});
const isCalendarMob = computed(() => {
  return (
    isMobile.value &&
    route.path === "/ecommerce/calendar" &&
    company.value?.crmMode !== "hotel"
  );
});
</script>
<template>
  <sidenav
    v-if="showNavbar"
    :class="[store.state.isRTL ? 'fixed-end' : 'fixed-start']"
    :custom_class="store.state.mcolor"
  />
  <main
    class="main-content d-flex flex-column position-relative d-height border-radius-lg overflow-x-hidden"
  >
    <!-- nav -->
    <navbar
      v-if="showNavbar"
      :class="[navClasses]"
      :minNav="() => store.commit('navbarMinimize')"
      :textWhite="store.state.isAbsolute ? 'text-white opacity-8' : ''"
    />
    <router-view />
    <app-footer v-show="store.state.showFooter" />
    <div v-if="isCalendarMob" class="mt-6"></div>
  </main>
</template>

<style>
.d-height {
  height: 100% !important;
  overflow-y: scroll;
}
</style>
