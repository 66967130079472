<script setup>
import ButtonApp from "@/components/common/other/ButtonApp.vue";
import { useRouter } from "vue-router";
import { useCompanyStore } from "@/storePinia/company";
const router = useRouter();
const storeCompany = useCompanyStore();
function routeToScheduler() {
  storeCompany.isEmptyTenant = false;
  router.push("/team/workschedule");
}
</script>
<template>
  <div class="card mt-6">
    <div class="card-header">
      <div class="row">
        <div class="col-12 text-center">
          <h4>Вітаємо! 🎉</h4>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="row text-center">
        <p>
          Залишився останній крок. Встановіть робочій графік співробітнику, щоб
          побачити його на календарі і розпочати запис клієнтів
        </p>
      </div>
      <div class="row mt-3">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 text-center">
          <ButtonApp
            @click="routeToScheduler"
            class="btn-icon bg-gradient-primary"
          >
            Встановити графік
          </ButtonApp>
        </div>
      </div>
    </div>
  </div>
</template>
