<template>
  <div v-show="!dataLoaded" class="container-fluid">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div v-show="dataLoaded" class="container-fluid">
    <div class="row mb-2">
      <div class="col-8"></div>
      <div class="col-lg-4 text-end">
        <span class="text-bold"> Баланс: </span
        ><span class=""> {{ company.smsBalance }} СМС </span>
      </div>
    </div>
    <div class="row">
      <div id="comments" class="card">
        <div class="card-body pt-0">
          <div>
            <div id="auto-sms">
              <div class="d-lg-flex">
                <div class="mt-3">
                  <h5 class="mb-0">Історія відправлень автоматичних SMS</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-3">
    <div class="table-responsive">
      <vue-good-table
        v-model:isLoading="isLoading"
        :columns="datatableColumns"
        :pagination-options="{
          enabled: true,
          perPage: tablePerPage,
          position: 'bottom',
          perPageDropdownEnabled: false,
          nextLabel: 'наст.',
          prevLabel: 'попер.',
          rowsPerPageLabel: 'записів на сторінці',
          ofLabel: 'із',
          pageLabel: 'сторінка',
          allLabel: 'всі',
        }"
        :rows="tableData.rows"
        :sort-options="{
          enabled: true,
          multipleColumns: false,
        }"
        :totalRows="tableData.totalRecords"
        compactMode
        mode="remote"
        responsive
        styleClass="vgt-table bordered"
        theme="default"
        v-on:page-change="onPageChange"
        v-on:sort-change="onSortChange"
        v-on:column-filter="onColumnFilter"
        v-on:per-page-change="onPerPageChange"
      >
        <template #table-row="props">
          <div v-if="props.column.field === 'createdAt'">
            <div v-if="props.row.createdAt">
              <router-link
                :to="`/service-orders/${props.row.orderId}`"
                data-bs-original-title="Переглянути"
                data-bs-toggle="tooltip"
                target="_self"
              >
                <span class="text-xs">{{
                  timestampToString(props.row.createdAt)
                }}</span>
              </router-link>
            </div>
          </div>

          <div v-if="props.column.field === 'reminder.startTime'">
            <div v-if="props.row.startTime">
              <router-link
                :to="`/service-orders/${props.row.orderId}`"
                data-bs-original-title="Переглянути"
                data-bs-toggle="tooltip"
                target="_self"
              >
                <span>
                  <span class="text-xs">{{
                    timestampToString(props.row.startTime)
                  }}</span>
                </span>
              </router-link>
            </div>
          </div>

          <div v-if="props.column.field === 'purpose'">
            <span class="text-xs">{{
              tableFieldPurposeMapping(props.row)
            }}</span>
          </div>
          <div v-if="props.column.field === 'reminder.text'">
            <span class="text-xs">{{ props.row.text }}</span>
          </div>

          <div v-if="props.column.field === 'clientId.phoneNumber'">
            <div v-if="props.row.clientId">
              <router-link
                :to="`/clients/${props.row.clientId._id}`"
                data-bs-original-title="Переглянути"
                data-bs-toggle="tooltip"
                target="_self"
              >
                <span class="text-xs">{{
                  props.row.recipient || props.row.clientId.phoneNumber
                }}</span>
              </router-link>
            </div>
          </div>

          <div v-if="props.column.field === 'clientId'">
            <div v-if="props.row.clientId">
              <router-link
                :to="`/clients/${props.row.clientId._id}`"
                data-bs-original-title="Переглянути"
                data-bs-toggle="tooltip"
                target="_self"
              >
                <span class="text-xs"
                  >{{ props.row.clientId.firstName }}
                  {{ props.row.clientId.lastName }}</span
                >
              </router-link>
            </div>
          </div>

          <div v-if="props.column.field === 'reminder.priceInCredits'">
            <span class="text-xs">{{ props.row.priceInCredits }}</span>
          </div>

          <div v-if="props.column.field === 'reminder.status'">
            <div class="badge" :class="getStatusClass(props.row?.status)">
              {{ getStatusLabel(props.row?.status) }}
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script setup>
import setTooltip from "@/assets/js/tooltip.js";
import Spinner from "@/views/shared/Spinner.vue";

import api from "@/services/api";
import constants from "@/constants";
import * as _ from "lodash";
import { DateTime } from "luxon";

import { onBeforeMount, onMounted, ref } from "vue";

const dataLoaded = ref(false);

const company = ref({
  smsBalance: 0,
  crmMode: "",
});

const tablePerPage = ref(constants.defaultDatatablePerPage);
const tableData = ref({
  totalRecords: 0,
  rows: [],
});

const instantMessagesTableData = ref({
  totalRecords: 0,
  rows: [],
});
const isLoading = ref(false);
const serverParams = ref({
  columnFilters: {
    // a map of column filters example: {name: 'john', age: '20'}
  },
  sort: [
    {
      field: "createdAt", // example: 'name'
      type: "desc", // 'asc' or 'desc'
    },
  ],

  page: 1,
  perPage: constants.defaultDatatablePerPage,
});
const datatableColumns = ref([
  {
    label: "Дата створення",
    field: "createdAt",
    sortable: false,
    thClass:
      "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
    tdClass: "text-sm font-weight-normal",
  },
  {
    label: "Дата відправки",
    field: "reminder.startTime",
    sortable: false,
    thClass:
      "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
    tdClass: "text-xs font-weight-normal",
  },
  {
    label: "Мета",
    field: "purpose",
    sortable: false,
    thClass:
      "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
    tdClass: "text-xs font-weight-normal",
  },
  {
    label: "Текст",
    field: "reminder.text",
    sortable: false,
    thClass:
      "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
    tdClass: "text-xs font-weight-normal",
  },
  {
    label: "Телефон",
    field: "clientId.phoneNumber",
    type: "string",
    sortable: false,
    thClass:
      "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
    tdClass: "text-xs font-weight-normal",
  },
  {
    label: "Клієнт",
    field: "clientId",
    type: "string",
    sortable: false,
    thClass:
      "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
    tdClass: "text-xs font-weight-normal",
  },
  {
    label: "Кількість (кредити)",
    field: "reminder.priceInCredits",
    sortable: false,
    thClass:
      "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
    tdClass: "text-xs font-weight-normal text-center",
  },
  {
    label: "Статус",
    field: "reminder.status",
    sortable: false,
    thClass:
      "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center",
    tdClass: "text-xs font-weight-normal text-center",
  },
]);

onMounted(async () => {
  company.value = await api.company.show();

  await loadItems();

  dataLoaded.value = true;

  setTooltip();
});

// eslint-disable-next-line no-unused-vars
function tableFieldPurposeMapping(row) {
  if (row?.purpose) {
    return row.purpose;
  }

  return "Нагадування";
}

function updateParams(newProps) {
  serverParams.value = Object.assign({}, serverParams.value, newProps);
}

async function onPageChange(params) {
  updateParams({ page: params.currentPage });
  await loadItems();
}

async function onPerPageChange(params) {
  updateParams({ perPage: params.currentPerPage });
  await loadItems();
}

async function onSortChange(params) {
  updateParams({
    page: 1,
    instantMessagesPage: 1,
    sort: [
      {
        type: params[0].type,
        field: params[0].field,
      },
    ],
  });
  await loadItems();
}

async function onColumnFilter(params) {
  updateParams(params);
  await loadItems();
}

async function loadItems() {
  const serviceOrders = await api.serviceOrders.list({
    limit: serverParams.value.perPage,
    offset: (serverParams.value.page - 1) * serverParams.value.perPage,

    sortField: _.get(serverParams.value, "sort[0].field"),
    sortDir: _.get(serverParams.value, "sort[0].type"),

    datatable: "y",
    sourcePage: "integration-reminders",
  });

  serviceOrders.rows.forEach((row) => {
    const resultingMessages = [];
    if (row.reminder && row.reminder.channelType === "sms") {
      resultingMessages.push({
        purpose: "Нагадування",
        orderId: row._id,
        startTime: row.reminder.startTime,
        status: row.reminder.status,
        text: row.reminder.text,
        priceInCredits: row.reminder.priceInCredits,
      });
    }
    if (row.notifyOnCreated && row.notifyOnCreated.channelType === "sms") {
      if (
        row.notifyOnCreated.messageId ||
        row.notifyOnCreated.status === "internalInsufficientFunds"
      ) {
        resultingMessages.push({
          purpose:
            company.value.crmMode === "hotel"
              ? "Бронь створено"
              : "Запис створено",
          orderId: row._id,
          startTime: row.createdAt,
          status: row.notifyOnCreated.status,
          text: row.notifyOnCreated.text,
          priceInCredits: row.notifyOnCreated.priceInCredits,
        });
      }
    }
    if (row.feedbackRequest && row.feedbackRequest.channelType === "sms") {
      resultingMessages.push({
        purpose: "Запит на відгук",
        orderId: row._id,
        startTime: row.feedbackRequest.startTime,
        status: row.feedbackRequest.status,
        text: row.feedbackRequest.text,
        priceInCredits: row.feedbackRequest.priceInCredits,
      });
    }

    if (resultingMessages.length > 0) {
      resultingMessages[0].clientId = row.clientId;
      resultingMessages[0].recipient = row.recipient;
      resultingMessages[0].phoneNumber = row.clientId.phoneNumber;
      resultingMessages[0].createdAt = row.createdAt;
      resultingMessages[0].heading = true;
      tableData.value.rows.push(...resultingMessages);
    }
  });

  tableData.value.totalRecords = serviceOrders.totalRecords;

  const instantMessageStatuses = await api.company.getInstantMessageStatuses({
    limit: serverParams.value.perPage,
    offset:
      (serverParams.value.instantMessagesPage - 1) * serverParams.value.perPage,
    datatable: "y",
  });

  instantMessagesTableData.value.totalRecords =
    instantMessageStatuses.totalRecords;
  instantMessagesTableData.value.rows = instantMessageStatuses.rows;
}

function timestampToString(input) {
  if (!input) {
    return "-";
  }
  const datetime = DateTime.fromISO(input);
  return datetime.setLocale("uk-UA").toFormat("dd.MM.yyyy HH:mm");
}

function getStatusLabel(status) {
  // Такої ситуації не повинно бути
  if (!status) {
    return;
  }

  switch (status) {
    case "internalQueued":
    case "Queued":
    case "Accepted":
      return "Очікує на відправку";
    case "Sent":
      return "Відправлено";
    case "Delivered":
    case "Read":
      return "Доставлено";
    case "Expired":
    case "Rejected":
    case "Unknown":
    case "Failed":
      return "Помилка відправки";
    case "Cancelled":
      return "Відміна відправки";
    case "internalInsufficientFunds":
      return "Недостатньо коштів";
  }
}

function getStatusClass(status) {
  // Такої ситуації не повинно бути
  if (!status) {
    return;
  }

  switch (status) {
    case "internalQueued":
    case "Queued":
    case "Accepted":
      return "badge badge-lg badge-info";
    case "Sent":
    case "Delivered":
    case "Read":
      return "badge badge-lg badge-success";
    case "Expired":
    case "Rejected":
    case "Unknown":
    case "Failed":
      return "badge badge-lg badge-danger";
    case "Cancelled":
      return "badge badge-lg badge-warning";
    case "internalInsufficientFunds":
      return "badge badge-lg badge-danger";
  }
}
</script>
