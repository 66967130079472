<template>
  <nav
    id="navbarBlur"
    :class="this.$store.state.isAbsolute ? 'mt-4' : 'mt-0'"
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-x sticky-top bg-gray-200 d-block"
    data-scroll="true"
    v-bind="$attrs"
  >
    <div class="container-fluid px-0 py-1 row w-100 flex-grow-1">
      <div class="col-4">
        <button
          class="btn btn-icon btn-sm btn-outline-secondary top-left-button d-lg-none small-button mb-0 py-1"
          @click="goBack"
        >
          <i class="material-icons customIcon">arrow_back_ios</i>
        </button>

        <div
          id="navbar"
          :class="this.$store.state.isRTL ? 'px-0' : ''"
          class="collapse navbar-collapse mt-sm-0 me-md-0 d-flex mt-0 mt-lg-2 mt-0 align-items-center"
        >
          <a
            class="p-0 nav-link text-body d-none d-lg-block"
            href="#"
            @click.prevent="minNav"
          >
            <div class="sidenav-toggler-inner d-none d-md-block">
              <i
                :class="textWhite ? 'bg-white' : ''"
                class="sidenav-toggler-line"
              ></i>
              <i
                :class="textWhite ? 'bg-white' : ''"
                class="sidenav-toggler-line"
              ></i>
              <i
                :class="textWhite ? 'bg-white' : ''"
                class="sidenav-toggler-line"
              ></i>
            </div>
          </a>
        </div>
      </div>
      <div class="col-4">
        <div class="d-flex justify-content-center ChessNavbarClick">
          <router-link
            class="d-inline-flex align-items-center img-chess gap-1 ChessNavbarClick"
            to="/"
            @click="goToCalendar"
          >
            <span class="material-symbols-outlined"> view_timeline </span>
            <span class="d-none d-lg-inline-block navText">Бронювання</span>
          </router-link>
        </div>
      </div>
      <div class="col-4">
        <div
          id="navbar"
          :class="this.$store.state.isRTL ? 'px-0' : ''"
          class="collapse navbar-collapse mt-sm-0 me-md-0"
        >
          <div
            :class="this.$store.state.isRTL ? 'me-md-auto' : 'ms-md-auto'"
            class="pe-md-3 d-flex align-items-center"
          ></div>

          <ul class="navbar-nav justify-content-end">
            <!-- Profile -->
            <li
              :class="this.$store.state.isRTL ? 'ps-2' : 'pe-2'"
              class="nav-item dropdown d-flex align-items-center"
            >
              <a
                id="DropdownMenuButton"
                :class="[
                  textWhite ? textWhite : 'text-body',
                  showMenu ? 'show' : '',
                ]"
                aria-expanded="false"
                class="p-0 nav-link lh-1"
                data-bs-toggle="dropdown"
                href="#"
                @click="showMenu = !showMenu"
              >
                <i class="material-icons cursor-pointer"> account_circle </i>
              </a>

              <ul
                :class="showProfileMenu ? 'show' : ''"
                aria-labelledby="DropdownMenuButton"
                class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
              >
                <li class="mb-2">
                  <router-link
                    :class="textWhite ? textWhite : 'text-body'"
                    class="px-0 nav-link font-weight-bold lh-1"
                    to="/authentication/logout"
                  >
                    <a
                      class="dropdown-item border-radius-md"
                      href="javascript:"
                    >
                      <div class="py-1 d-flex">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-1 text-sm font-weight-normal">
                            <span class="font-weight-bold text-danger"
                              >Вийти</span
                            >
                          </h6>
                        </div>
                      </div>
                    </a>
                  </router-link>
                </li>
                <li class="mb-2">
                  <router-link
                    class="dropdown-item border-radius-md"
                    to="/team/editprofile"
                  >
                    <div class="py-1 d-flex">
                      <div v-if="false" class="my-auto">
                        <img
                          alt="user image"
                          class="avatar avatar-sm me-3"
                          src="../../assets/img/team-2.jpg"
                        />
                      </div>

                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-1 text-sm font-weight-normal">
                          <span class="font-weight-bold">Мій профіль</span>
                        </h6>
                      </div>
                    </div>
                  </router-link>
                </li>
              </ul>
            </li>
            <!-- Profile finish -->

            <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
              <a
                id="iconNavbarSidenav"
                class="p-0 nav-link text-body lh-1"
                href="#"
                @click.prevent.stop="toggleSidebar"
              >
                <div class="sidenav-toggler-inner">
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div>
      <div
        v-if="showTrialActiveAlert"
        class="col-lg-12 mx-auto text-center text-sm"
        role="alert"
      >
        <span class="badge badge-lg badge-info"
          >Тестовий період. Залишилось днів:</span
        >
        <span class="badge badge-lg badge-info margin-left">{{
          daysUntilTrialEnds
        }}</span>
        <br />
        Щоб зберегти доступ
        <b
          ><router-link to="/subscription" class="subscribe"
            >підпишіться</router-link
          ></b
        >
        <hr class="customHr" />
      </div>
    </div>
    <div
      v-if="showSmsBalanceAlert"
      class="alert alert-custom alert-secondary alert-text mb-0"
      role="alert"
    >
      <span class="text-sm">
        У вас є СМС, заплановані на відправку клієнтам, але критично малий
        баланс. Щоб не втратити можливість надсилати СМС-повідомлення, необхідно
      </span>
      <br />
      <router-link
        class="btn btn-sm btn-outline btn-outline-success"
        to="/integrations/sms-payment"
        >Поповнити рахунок
      </router-link>
    </div>
  </nav>
</template>
<style scoped>
.navText {
  color: #7c8098;
}
.material-symbols-outlined {
  color: #7c8098;
}
.payForSmsBtn {
  color: white;
  font-weight: 900;
}

.alert-text {
  color: white;
}

@media screen and (max-width: 767px) {
  .mobile-hidden {
    display: none;
  }
}
</style>
<script>
import { mapMutations } from "vuex";
import { DateTime } from "luxon";

import api from "@/services/api";

export default {
  name: "navbar",

  data() {
    return {
      showDevFeatures: false,
      showMenu: false,
      showNotificationMenu: false,
      showProfileMenu: false,

      smsBalance: 0,
      showSmsBalanceAlert: false,
      showTrialActiveAlert: false,
      daysUntilTrialEnds: 7,
      intervalId: null,
    };
  },
  props: ["minNav", "textWhite"],
  beforeMount() {
    this.showDevFeatures = !!localStorage.getItem("showDevFeatures");
  },
  beforeUpdate() {
    this.toggleNavigationOnMobile();
  },
  created() {
    this.minNav;

    document.getElementById("body").addEventListener("click", () => {
      if (window.innerWidth < 1200) {
        this.navbarClose();
      }
    });
  },
  async mounted() {
    if (!this.intervalId) {
      this.intervalId = setInterval(() => {
        this.checkStatuses();
      }, 600000);

      setTimeout(() => {
        this.checkStatuses();
      }, 500);
    }
  },
  unmounted() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    ...mapMutations(["navbarMinimize", "navbarClose", "toggleConfigurator"]),
    async goBack() {
      await this.$router.go(-1);
    },
    async goToCalendar(event) {
      if (this.$route.path === "/ecommerce/calendar") {
        event.preventDefault();
        event.stopPropagation();
        window.location.href = "/ecommerce/calendar";
      }
    },
    toggleSidebar() {
      this.navbarMinimize();
    },
    toggleNavigationOnMobile() {
      if (window.innerWidth < 1200) {
        this.navbarClose();
      }
    },

    async checkStatuses() {
      try {
        const company = await api.company.show();
        this.smsBalance = company.smsBalance;

        this.showSmsBalanceAlert =
          (company.remindBeforeEnabled ||
            company.smsNotifyOnNewServiceOrderFromClient ||
            company.smsNotifyOnNewServiceOrderFromApp ||
            company.allowFeedbacks) &&
          this.smsBalance <= 10;

        if (company.subscriptionStatus === "trial" && company.trialEndDate) {
          const trialEndDate = DateTime.fromISO(company.trialEndDate);
          this.daysUntilTrialEnds = Math.round(
            trialEndDate.diff(DateTime.now().startOf("day"), "days").days
          );

          this.showTrialActiveAlert = this.daysUntilTrialEnds > 0;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style scoped>
.img-chess {
  width: 24px;
  flex-shrink: 0;
}

.trial {
  background-color: #458466;
  border-radius: 16px;
  font-weight: 400;
  color: white;
}
.subscribe {
  color: #0f4432;
  font-weight: 600;
  text-decoration: underline;
}
.margin-left {
  margin-left: 4px; /* Настройте отступ по своему усмотрению */
}
.alert {
  padding: 0.75rem 0.75rem;
}

.btn {
  margin-bottom: 0.25rem;
}
.customHr {
  margin-bottom: 4px !important;
  margin-top: 8px !important;
}
</style>
