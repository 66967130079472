import { ref } from "vue";
import { useStore } from "vuex";

export function useFetch(fn, isToastNotify = false) {
  const storeToast = useStore();

  const data = ref(null);
  const isLoading = ref(false);
  const errorMessage = ref(null);
  const hasError = ref(false);

  async function fetchData(payload = []) {
    isLoading.value = true;
    hasError.value = false;
    errorMessage.value = null;
    let res;

    try {
      res = await fn(...payload);
      if (res) {
        data.value = res;
        if (isToastNotify) {
          storeToast.commit("addToast", { title: "Збережено" });
        }
      }
    } catch (error) {
      console.error(error);
      hasError.value = true;
      errorMessage.value = error;
      if (isToastNotify) {
        storeToast.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    } finally {
      isLoading.value = false;
    }
    return data.value;
  }

  return {
    data,
    isLoading,
    hasError,
    errorMessage,
    fetchData,
  };
}
