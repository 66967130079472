import { DateTime, Interval } from "luxon";

export function getIntervalsFromISO(from, to) {
  const intervals = Interval.fromDateTimes(
    DateTime.fromISO(from),
    DateTime.fromISO(to).plus({ days: 1 })
  )
    .splitBy({ day: 1 })
    .map((d) => d.start.toISODate());

  return intervals;
}

export function ISODateToDay(isoDate) {
  if (!DateTime.fromISO(isoDate).isValid) {
    return "";
  }
  return DateTime.fromISO(isoDate).setLocale("ua").toFormat("dd MMMM (EEE)");
}
