const checkUserRole = (role) => {
  let user;

  try {
    const localUser = localStorage.getItem("localUser");
    if (!localUser) {
      return false;
    }
    user = JSON.parse(localUser);
  } catch (error) {
    console.error(error);
    return false;
  }

  const rolesMap = user.rolesMap;
  if (!rolesMap) {
    return false;
  }

  if (role === "root") {
    return rolesMap.isRoot;
  }
  if (role === "owner") {
    return rolesMap.isOwner;
  }
  if (role === "manager") {
    return rolesMap.isManager;
  }
  if (role === "staff") {
    return rolesMap.isStaff;
  }

  return false;
};

export default checkUserRole;
