export function getFromLocalStorageString(key = "") {
  const data = localStorage.getItem(key);

  if (data === undefined || data === null || data === "") {
    return null;
  }

  return data;
}

export function setToLocalStorageString(key = "", value = null) {
  let data = value;
  if (data === null || data === undefined) {
    data = "";
  }
  localStorage.setItem(key, data);
}

export function getFromLocalStorage(key = "") {
  let data = null;
  try {
    if (!localStorage.getItem(key)) {
      return;
    }
    data = JSON.parse(localStorage.getItem(key));
  } catch (error) {
    console.error(`Error in getter localStorage, ${error}`);
  }
  return data;
}

export function setToLocalStorage(key = "", value = null) {
  try {
    const data = JSON.stringify(value);
    localStorage.setItem(key, data);
  } catch (error) {
    console.error(`Error in setter localStorage, ${error}`);
  }
}
