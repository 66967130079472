<script setup>
import { computed } from "vue";
import { uuid } from "vue-uuid";

const props = defineProps({
  modelValue: { type: Boolean, required: true },
  label: { type: String, default: "" },
});
const emit = defineEmits(["update:modelValue"]);

const currentValue = computed({
  set: (val) => {
    emit("update:modelValue", val);
  },
  get: () => props.modelValue,
});

const id = uuid.v4();
</script>
<template>
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      :id="id"
      v-model="currentValue"
      :checked="currentValue"
    />
    <label v-if="label" class="form-check-label mx-2" :for="id">
      {{ label }}
    </label>
  </div>
</template>

<style lang="scss" scoped></style>
