<script setup>
import { computed } from "vue";
const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
});
const currentYear = computed(() => {
  return new Date().getFullYear();
});
</script>
<template>
  <main class="main-content mt-0">
    <div class="page-header align-items-start min-vh-100 not-authed-background">
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container my-auto">
        <router-view />
      </div>
      <footer class="footer position-absolute bottom-2 py-2 w-100">
        <div class="container">
          <div class="row align-items-center justify-content-lg-between">
            <div class="col-12 col-md-6 my-auto">
              <div
                class="copyright text-center text-sm text-white text-lg-start"
              >
                © {{ currentYear }}, зроблено з 🫶🏽 в Дніпрі
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </main>
</template>

<style scoped>
/* .not-authed-background {
  background: url("../assets/img/app/bmnbackground.jpeg") 0 0 / cover no-repeat;
} */
</style>
