<template>
  <div class="container-fluid my-3 py-3">
    <div class="row mb-5">
      <div class="col-lg-3">
        <div class="card position-sticky top-1">
          <ul class="nav flex-column bg-white border-radius-lg p-3">
            <!-- should be hidden for new order creating page -->
            <!-- <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex" data-scroll="" href="#status">
                <i class="material-icons text-lg me-2">offline_pin</i>
                <span class="text-sm">Статус</span>
              </a>
            </li> -->
            <li class="nav-item pt-2">
              <a
                class="nav-link text-dark d-flex"
                data-scroll=""
                href="#client"
              >
                <i class="material-icons text-lg me-2">person</i>
                <span class="text-sm">Клиент</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex" data-scroll="" href="#order">
                <i class="material-icons text-lg me-2">receipt_long</i>
                <span class="text-sm">Заказ</span>
              </a>
            </li>
            <!-- should be hidden for new order creating page -->
            <!-- <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex" data-scroll="" href="#service">
                <i class="material-icons text-lg me-2">spoke</i>
                <span class="text-sm">Заказ</span>
              </a>
            </li> -->
            <li class="nav-item pt-2">
              <a
                class="nav-link text-dark d-flex"
                data-scroll=""
                href="#history"
              >
                <i class="material-icons text-lg me-2">history</i>
                <span class="text-sm">История</span>
              </a>
            </li>
            <div class="w-50 mt-3">
              <a class="btn btn-primary" href="" role="button">Сохранить</a>
            </div>
          </ul>
        </div>
      </div>
      <div class="col-lg-9 mt-lg-0">
        <!-- Статус -->
        <!-- should be hidden for new order creation page -->
        <div class="card" id="status">
          <div class="card-header">
            <h5>Статус</h5>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-6">
                Текущий статус
                <div class="d-flex align-items-center">
                  <span class="badge bg-gradient-info">Новый</span>
                </div>
              </div>
              <div class="col-6">
                Изменить статус
                <select
                  class="form-control"
                  name="choices-status"
                  id="choices-status"
                >
                  <option value="Choice 1" selected>Новый</option>
                  <option value="Choice 2">Черновик</option>
                  <option value="Choice 2">В доставке</option>
                  <option value="Choice 3">Оплачен</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <!-- Статус finish -->

        <!-- Клиент  -->
        <div class="card mt-3" id="client">
          <div class="card-header">
            <h5>Клиент</h5>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-6">
                <!-- Нужен компонент, который позволит быстро искать из дропдауна нужного клиента по номеру телефона -->
                <select
                  class="form-control"
                  name="choices-client"
                  id="choices-client-edit"
                >
                  <option value="Choice 1" selected>Розничный клиент</option>
                  <option value="Choice 2">Клиент 1</option>
                  <option value="Choice 3">Клиент 2</option>
                  <option value="Choice 4">Клиент 3</option>
                </select>
              </div>
              <div class="col-6">
                <div class="input-group input-group-static">
                  <a
                    href="../clients/newclient"
                    class="btn btn-icon bg-gradient-dark"
                  >
                    + Клиент
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Клиент finish -->

        <!-- Товар -->
        <div class="card mt-3" id="order">
          <div class="card-header">
            <h5>Заказ</h5>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-6">
                <label>Товар</label>
                <!-- Нужен компонент, который позволит быстро искать из дропдауна нужный товар если их будет много -->
                <select
                  class="form-control"
                  name="choices-category"
                  id="choices-product-edit"
                >
                  <option value="Choice 0" selected>Выберите товар</option>
                  <option value="Choice 1">Товар 1</option>
                  <option value="Choice 2">Товар 2</option>
                  <option value="Choice 3">Товар 3</option>
                </select>
              </div>
              <div class="col-6 pt-0">
                <div class="input-group input-group-static">
                  <label>Цена</label>
                  <input type="text" class="form-control" placeholder="350" />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4"></div>
              <div class="col-4 text-center">
                <button
                  class="btn btn-sm btn-outline-dark px-3 mb-0"
                  type="button"
                  id="dropdownImport1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Добавить товар
                </button>
              </div>
            </div>
            <div class="col-12 pt-0 mt-3">
              <div class="input-group input-group-static">
                <label>Комментарий к заказу</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Что-то о чем вы не хотите забыть"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4"></div>
            <div class="col-4 text-center">
              <label>Способ оплаты</label>
              <select
                class="form-control"
                name="choices-payment-method"
                id="choices-payment-method"
              >
                <option value="Choice 0" selected>Наличные</option>
                <option value="Choice 1">Терминал</option>
              </select>
            </div>
            <div class="col-4"></div>
          </div>
          <div class="card-body pt-0">
            <div class="row mt-3">
              <div class="col-4"></div>
              <div class="col-4 text-center">
                <button
                  class="btn btn-primary px-3 mb-0"
                  type="button"
                  id="dropdownImport2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Оплатить
                </button>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-4">
              <span class="mb-2 text-lg"> Итого: </span>
              <span class="text-dark text-lg ms-2 font-weight-bold"
                >350.00 грн</span
              >
            </div>
          </div>
        </div>
        <!-- Товар finish -->

        <!-- Card History -->
        <div class="card mt-3" id="history">
          <div class="card-header">
            <h5>История заказа</h5>
          </div>
          <div class="card-body pt-0">
            <!-- should be empty for new order creating page -->
            <!-- <div class="col-6">
              <div class="timeline timeline-one-side">
                <div class="timeline-block mb-3">
                  <span class="timeline-step">
                    <i class="material-icons text-secondary text-lg">notifications</i>
                  </span>
                  <div class="timeline-content">
                    <h6 class="text-dark text-sm font-weight-bold mb-0">Запись создана</h6>
                    <p class="text-secondary font-weight-normal text-xs mt-1 mb-0">онлайн форма</p>
                    <p class="text-secondary font-weight-normal text-xs mt-1 mb-0">22.12.2022 10:15</p>
                  </div>
                </div>
                <div class="timeline-block mb-3">
                  <span class="timeline-step">
                    <i class="material-icons text-secondary text-lg">paid</i>
                  </span>
                  <div class="timeline-content">
                    <h6 class="text-dark text-sm font-weight-bold mb-0">Запись оплачена</h6>
                    <p class="text-secondary font-weight-normal text-xs mt-1 mb-0">онлайн форма</p>
                    <p class="text-secondary font-weight-normal text-xs mt-1 mb-0">22.12.2022 10:17</p>
                  </div>
                </div>
                <div class="timeline-block mb-3">
                  <span class="timeline-step">
                    <i class="material-icons text-secondary text-lg">done</i>
                  </span>
                  <div class="timeline-content">
                    <h6 class="text-dark text-sm font-weight-bold mb-0">Запись подтверждена</h6>
                    <p class="text-secondary font-weight-normal text-xs mt-1 mb-0">телеграм бот</p>
                    <p class="text-secondary font-weight-normal text-xs mt-1 mb-0">23.12.2022 19:23</p>
                  </div>
                </div>
                <div class="timeline-block mb-3">
                  <span class="timeline-step">
                    <i class="material-icons text-danger text-secondary text-lg">free_cancellation</i>
                  </span>
                  <div class="timeline-content">
                    <h6 class="text-dark text-sm font-weight-bold mb-0">Запись отменена</h6>
                    <p class="text-secondary font-weight-normal text-xs mt-1 mb-0">Менеджер: Артем Павлов</p>
                    <p class="text-secondary font-weight-normal text-xs mt-1 mb-0">23.12.2022 19:23</p>
                  </div>
                </div>
                <div class="timeline-block mb-3">
                  <span class="timeline-step">
                    <i class="material-icons text-danger text-secondary text-lg">free_cancellation</i>
                  </span>
                  <div class="timeline-content">
                    <h6 class="text-dark text-sm font-weight-bold mb-0">Запись перенесена <label># новой записи</label></h6>
                    <p class="text-secondary font-weight-normal text-xs mt-1 mb-0">Менеджер: Сергей Петров</p>
                    <p class="text-secondary font-weight-normal text-xs mt-1 mb-0">23.12.2022 19:23</p>
                  </div>
                </div>
                <div class="timeline-block mb-3">
                  <span class="timeline-step">
                    <i class="material-icons text-danger text-secondary text-lg">free_cancellation</i>
                  </span>
                  <div class="timeline-content">
                    <h6 class="text-dark text-sm font-weight-bold mb-0">Клиент не пришел</h6>
                    <p class="text-secondary font-weight-normal text-xs mt-1 mb-0">Менеджер: Артем Павлов</p>
                    <p class="text-secondary font-weight-normal text-xs mt-1 mb-0">23.12.2022 19:23</p>
                  </div>
                </div>
                <div class="timeline-block mb-3">
                  <span class="timeline-step">
                    <i class="material-icons text-success text-gradient text-lg">check_circle_outline</i>
                  </span>
                  <div class="timeline-content">
                    <h6 class="text-dark text-sm font-weight-bold mb-0">Выполнена</h6>
                    <p class="text-secondary font-weight-normal text-xs mt-1 mb-0">Менеджер: Артем Павлов</p>
                    <p class="text-secondary font-weight-normal text-xs mt-1 mb-0">24.12.2022 19:00</p>
                  </div>
                </div>
              </div>
              </div> -->
          </div>
        </div>
        <!-- Card History finish -->

        <!--  Save Account -->
        <div class="d-flex align-items-center mb-sm-0 mt-4">
          <div class="w-50"></div>
          <div class="w-50 text-end">
            <a class="btn btn-primary" href="" role="button">Сохранить</a>
          </div>
        </div>
        <!--  Save Account finish-->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */
import Choices from "choices.js";

export default {
  name: "new-order",

  mounted() {
    this.getChoices("choices-client-edit");
    this.getChoices("choices-payment-method");
    this.getChoices("choices-product-edit");
    this.getChoices("choices-status");

    if (document.getElementById("choices-tags-edit")) {
      var tags = document.getElementById("choices-tags-edit");
      const examples = new Choices(tags, {
        removeItemButton: true,
      });

      examples.setChoices(
        [
          {
            value: "One",
            label: "Expired",
            disabled: true,
          },
          {
            value: "Two",
            label: "Out of Stock",
            selected: true,
          },
        ],
        "value",
        "label",
        false
      );
    }
  },
  methods: {
    getChoices(id) {
      if (document.getElementById(id)) {
        var element = document.getElementById(id);
        return new Choices(element, {
          searchEnabled: false,
        });
      }
    },
  },
};
</script>
