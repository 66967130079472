import { ref } from "vue";

export function useSteps({ countSteps = 1 } = {}) {
  const current = ref(1);

  function nextStep() {
    if (current.value < countSteps) {
      current.value++;
    }
  }
  function prevStep() {
    if (current.value > 1) {
      current.value--;
    }
  }
  return {
    current,
    nextStep,
    prevStep,
  };
}
