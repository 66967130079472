const staffPermissionsMap = {
  "/authentication/logout": true,
  "/pages/editprofile": true,
  "/ecommerce/calendar": true,
  "/clients": true,
  "/service-orders": true,
};

const allowed = (path) => {
  if (!path) {
    return false;
  }

  let user;

  try {
    const localUser = localStorage.getItem("localUser");
    if (!localUser) {
      return false;
    }

    user = JSON.parse(localUser);
  } catch (error) {
    console.error(error);
    return false;
  }

  const rolesMap = user.rolesMap;
  if (!rolesMap) {
    return true;
  }

  const isOnlyStaff = !rolesMap.isRoot && !rolesMap.isOwner && !rolesMap.isManager && rolesMap.isStaff;
  if (!isOnlyStaff) {
    return true;
  }

  const rule = staffPermissionsMap[path];

  if (rule === undefined && isOnlyStaff) {
    return false;
  }
  if (rule === false) {
    return false;
  }

  if (rule === true) {
    return true;
  }

  return false;
};

export default allowed;
