<template>
  <!-- ALL WAREHOUSE IS NOW MVP -->
  <div class="py-4 container-fluid">
    <div class="d-sm-flex justify-content-between">
      <div>
        <router-link
          to="/warehouse/supply"
          class="btn btn-icon bg-gradient-primary"
        >
          + Поставка
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">История поставок</h5>
                <p class="mb-0 text-sm"></p>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table class="table table-flush" id="products-list">
                <thead class="thead-light">
                  <tr>
                    <th></th>
                    <th>id</th>
                    <th>Дата</th>
                    <th>Поставщик</th>
                    <th>Менеджер</th>
                    <th>Товар</th>
                    <th>Кол-во</th>
                    <th>Сумма</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="d-flex">
                        <div class="my-auto form-check">
                          <a
                            href="javascript:;"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Посмотреть"
                          >
                            <i class="fas fa-eye text-secondary"></i>
                          </a>
                        </div>
                      </div>
                    </td>
                    <td class="text-sm">#13923</td>
                    <td class="text-sm">23.05.2022</td>
                    <td class="text-sm">Johnson&Johnson</td>
                    <td class="text-sm">Артем Камушкин</td>
                    <td class="text-sm">Шампунь</td>
                    <td class="text-sm"><span>3</span><span>л</span></td>
                    <td class="text-sm"><span>750</span><span>грн</span></td>
                  </tr>
                  <tr>
                    <td>
                      <div class="d-flex">
                        <div class="my-auto form-check">
                          <a
                            href="javascript:;"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Посмотреть"
                          >
                            <i class="fas fa-eye text-secondary"></i>
                          </a>
                        </div>
                      </div>
                    </td>
                    <td class="text-sm">#13923</td>
                    <td class="text-sm">23.05.2022</td>
                    <td class="text-sm">Johnson&Johnson</td>
                    <td class="text-sm">Артем Камушкин</td>
                    <td class="text-sm">Шампунь</td>
                    <td class="text-sm"><span>3</span><span>л</span></td>
                    <td class="text-sm"><span>750</span><span>грн</span></td>
                  </tr>
                  <tr>
                    <td>
                      <div class="d-flex">
                        <div class="my-auto form-check">
                          <a
                            href="javascript:;"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Посмотреть"
                          >
                            <i class="fas fa-eye text-secondary"></i>
                          </a>
                        </div>
                      </div>
                    </td>
                    <td class="text-sm">#13923</td>
                    <td class="text-sm">23.05.2022</td>
                    <td class="text-sm">Johnson&Johnson</td>
                    <td class="text-sm">Артем Камушкин</td>
                    <td class="text-sm">Шампунь</td>
                    <td class="text-sm"><span>3</span><span>л</span></td>
                    <td class="text-sm"><span>750</span><span>грн</span></td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>Название</th>
                    <th>Бренд</th>
                    <th>Цена</th>
                    <th>Цена поставки</th>
                    <th>Цена продажи</th>
                    <th>Статус</th>
                    <th>Действие</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import setTooltip from "@/assets/js/tooltip.js";

export default {
  name: "products-list",
  mounted() {
    if (document.getElementById("products-list")) {
      const dataTableSearch = new DataTable("#products-list", {
        searchable: true,
        fixedHeight: false,
        perPage: 25,
      });

      document.querySelectorAll(".export").forEach(function (el) {
        el.addEventListener("click", function () {
          var type = el.dataset.type;

          var data = {
            type: type,
            filename: "soft-ui-" + type,
          };

          if (type === "csv") {
            data.columnDelimiter = "|";
          }

          dataTableSearch.export(data);
        });
      });
    }
    setTooltip();
  },
};
</script>
