import { onMounted, ref } from "vue";
import Modal from "bootstrap/js/dist/modal";

export function useModal({ onClose = null } = {}) {
  const modalRef = ref(null);
  const modalInstance = ref(null);

  function closeModal() {
    modalInstance.value.hide();
  }

  function openModal() {
    modalInstance.value.show();
  }

  onMounted(() => {
    modalInstance.value = new Modal(modalRef.value, {});
    modalRef.value.addEventListener("hidden.bs.modal", () => {
      if (onClose && typeof onClose === "function") {
        onClose();
      }
    });
  });
  return {
    modalRef,
    openModal,
    closeModal,
  };
}
