export const colorsOrderList = [
  "#FAEDCC",
  "#FAE3DE",
  "#ECE0DB",
  "#F1E1E9",
  "#F1F0F0",
  "#D6E4EE",
  "#E3E2E0",
  "#E6DEED",
  "#F5DFCC",
  "#DEECDC",
];
