<template>
  <div class="container-fluid my-3 py-3" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid my-3 py-3" v-show="dataLoaded">
    <div class="row mb-5">
      <div class="col-lg-3">
        <div class="card position-sticky top-1 d-none d-md-block">
          <ul class="nav flex-column bg-white border-radius-lg p-3">
            <li class="nav-item pt-2">
              <a
                class="nav-link text-dark d-flex"
                data-scroll=""
                href="#basic-info"
              >
                <i class="material-icons text-lg me-2">receipt_long</i>
                <span class="text-sm">Загальна інформація</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a
                class="nav-link text-dark d-flex"
                data-scroll=""
                href="#working-hours"
              >
                <i class="material-icons text-lg me-2">lock</i>
                <span class="text-sm">Розклад роботи філії</span>
              </a>
            </li>

            <li class="nav-item pt-2">
              <a
                class="nav-link text-dark d-flex"
                data-scroll=""
                href="#notifications"
              >
                <i class="material-icons text-lg me-2">notifications</i>
                <span class="text-sm">Автоматичне сповіщення</span>
              </a>
            </li>

            <li class="nav-item pt-2" v-if="branchTemplate._id">
              <a
                class="nav-link text-dark d-flex"
                data-scroll=""
                href="#delete"
              >
                <i class="material-icons text-lg me-2">delete</i>
                <span class="text-sm">Видалити</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-9 mt-lg-0 mt-4 mx-auto">
        <div class="row">
          <div class="col-6"></div>
          <div class="col-4 text-end">Доступність:</div>
          <div class="col-2 form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="branchTemplate.isActive"
            />
          </div>
        </div>
        <!-- Card Basic Info -->
        <div class="card" id="basic-info">
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-lg-6 mt-3">
                <div
                  class="input-group input-group-outline"
                  :class="{ 'is-filled': branchTemplate.address }"
                >
                  <label class="form-label">Адреса філії</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="branchTemplate.address"
                  />
                </div>
              </div>
              <div class="col-lg-6 mt-3">
                <div
                  class="input-group input-group-outline"
                  :class="{ 'is-filled': branchTemplate.phoneNumber }"
                >
                  <label class="form-label">Телефон</label>
                  <input
                    type="tel"
                    class="form-control"
                    minlength="13"
                    maxlength="13"
                    pattern="\+[0-9]{12}"
                    v-model="branchTemplate.phoneNumber"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="input-group input-group-static">
                  <label>Google maps посилання</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="например: https://g.page/barbershop98"
                    v-model="branchTemplate.googleMapsLocationLink"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="input-group input-group-static">
                  <label>Google maps iFrame</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="например: https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2645.895799105755!2d35.044636800000006!3d48.458528799999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40dbe3f05f676167%3A0x168696a0138e5e6b!2zOTggQmFyYmVyc2hvcCDinILvuI8gLyDQkdCw0YDQsdC10YDRiNC-0L8g0JTQvdC10L_RgC4g0JzRg9C20YHQutC40LUg0YHRgtGA0LjQttC60LgsINCR0YDQuNGC0YzQtSDQsdC-0YDQvtC00YssINCU0LXRgtGB0LrQuNC1INGB0YLRgNC40LbQutC4!5e0!3m2!1sen!2sua!4v1654428204057!5m2!1sen!2sua"
                    v-model="branchTemplate.googleMapsLocationIframe"
                  />
                </div>
              </div>
              <div
                class="col-lg-6 col-sm-12"
                v-if="
                  branchTemplate.googleMapsLocationIframe &&
                  branchTemplate.googleMapsLocationIframe.startsWith(
                    'https://www.google.com/maps/'
                  )
                "
              >
                <div class="input-group input-group-static">
                  <iframe
                    :src="branchTemplate.googleMapsLocationIframe"
                    width="100%"
                    height="350"
                    style="border: 0"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  >
                  </iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Card Basic Info finish -->

        <!-- Card Working hours -->
        <div class="card mt-4" id="working-hours">
          <div class="card-header">
            <h6>Графік роботи філії</h6>
          </div>
          <div class="card-body pt-0">
            <div class="table-responsive">
              <table class="table table-flush" id="datatable-basic">
                <thead class="thead-light">
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center"
                    ></th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center"
                    >
                      День тижня
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center"
                    >
                      З
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center"
                    >
                      До
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in branchTemplate.workingHours"
                    :key="item.index"
                  >
                    <td class="text-sm font-weight-normal">
                      <div class="form-check form-switch text-center">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          checked
                          v-model="item.isActive"
                        />
                      </div>
                    </td>
                    <td class="text-sm font-weight-normal text-center">
                      <div class="mt-2">{{ getDayName(item.day) }}</div>
                      <div v-if="item.breakFrom" class="mt-3">перерва</div>
                    </td>
                    <td class="text-sm font-weight-normal text-center">
                      <flat-pickr
                        v-model="item.from"
                        class="form-control datetimepicker"
                        placeholder="Please select start hours"
                        :config="config"
                      ></flat-pickr>
                      <flat-pickr
                        v-show="item.breakFrom"
                        v-model="item.breakFrom"
                        class="form-control datetimepicker"
                        placeholder="Please select start hours"
                        :config="config"
                      ></flat-pickr>
                    </td>
                    <td class="text-sm font-weight-normal text-center">
                      <flat-pickr
                        v-model="item.to"
                        class="form-control datetimepicker"
                        placeholder="Please select start hours"
                        :config="config"
                      ></flat-pickr>
                      <flat-pickr
                        v-show="item.breakTo"
                        v-model="item.breakTo"
                        class="form-control datetimepicker"
                        placeholder="Please select start hours"
                        :config="config"
                      ></flat-pickr>
                    </td>
                    <td>
                      <a
                        v-if="!item.breakFrom"
                        @click="addBreak(item)"
                        class="nav-link text-success d-flex"
                        data-scroll=""
                        href="#"
                      >
                        <i class="material-icons text-lg me-2"></i>
                        <span class="text-sm">+ перерва</span>
                      </a>
                      <a
                        v-if="item.breakFrom"
                        @click="removeBreak(item)"
                        class="nav-link text-danger d-flex"
                        data-scroll=""
                        href="#"
                      >
                        <i class="material-icons text-lg me-2">delete</i>
                        <span class="text-sm">перерва</span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- Card Working hours -->

        <!-- Дополнительные настройки -->
        <div class="card mt-4 pb-3" id="additional-settings">
          <div class="card-body">
            <h6>Налаштування аналітики</h6>
            <div class="row mt-3">
              <div class="col-lg-8">
                <span class="text-sm font-weight-normal">
                  Встановіть період за який ваш клієнт має здійснити повторний
                  візит, щоб не вважатись "загубленим" в підрахунку
                  аналітики.</span
                >
              </div>
              <div class="col-lg-4">
                <div class="row">
                  <div class="col-6 hours">
                    <div
                      class="input-group input-group-outline"
                      :class="{
                        'is-filled': company.analyticsLostClientPeriodDays,
                      }"
                    >
                      <label class="form-label">Днів</label>
                      <input
                        type="number"
                        min="1"
                        class="form-control"
                        v-model="company.analyticsLostClientPeriodDays"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Дополнительные настройки finish -->

        <!-- Card Удалить филиал -->
        <div class="card mt-4" id="delete" v-if="branchTemplate._id">
          <div class="card-body">
            <div class="d-flex align-items-center mb-sm-0 mb-4">
              <div class="w-50">
                <h6>Видалити філію</h6>
              </div>
              <div class="w-50 text-end">
                <!-- <button
                  class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto"
                  type="button"
                  name="button"
                >
                  Восстановить
                </button> -->
                <button
                  class="btn bg-gradient-danger mb-0 ms-2"
                  type="button"
                  name="button"
                  data-bs-toggle="modal"
                  :data-bs-target="`#${modalParamsRemoveBranch.modalId}`"
                >
                  Видалити
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Удалить филиал finish -->

        <!--  Зберегти -->
        <div class="fixed-bottom">
          <div class="row">
            <div class="col-10"></div>
            <div class="col-lg-2">
              <div class="mx-4">
                <button
                  type="button"
                  class="block btn bg-gradient-primary"
                  @click="save"
                >
                  Зберегти
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--  Зберегти фiнiш-->
      </div>
    </div>
  </div>

  <BmnCustomPopup :params="modalParamsRemoveBranch"></BmnCustomPopup>
</template>
<style scoped>
.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}
</style>

<script>
import flatPickr from "vue-flatpickr-component";
import Spinner from "@/views/shared/Spinner.vue";

import api from "@/services/api";
import BmnCustomPopup from "@/components/BmnCustomPopup.vue";

export default {
  name: "settings-branch",

  async beforeMount() {
    this.showDevFeatures = !!localStorage.getItem("showDevFeatures");
    this.company = await api.company.show();
    if (this.$route.params.id) {
      this.mode = "edit";
      this.branchTemplate = await api.branches.findOne(this.$route.params.id);

      // TODO: remove after migration
      if (
        !this.branchTemplate.workingHours ||
        !this.branchTemplate.workingHours.length
      ) {
        this.branchTemplate.workingHours = [
          {
            index: 1,
            day: "monday",
            from: "10:00",
            to: "20:00",
            breakFrom: null,
            breakTo: null,
            isActive: true,
          },
          {
            index: 2,
            day: "tuesday",
            from: "10:00",
            to: "20:00",
            breakFrom: null,
            breakTo: null,
            isActive: true,
          },
          {
            index: 3,
            day: "wednesday",
            from: "10:00",
            to: "20:00",
            breakFrom: null,
            breakTo: null,
            isActive: true,
          },
          {
            index: 4,
            day: "thursday",
            from: "10:00",
            to: "20:00",
            breakFrom: null,
            breakTo: null,
            isActive: true,
          },
          {
            index: 5,
            day: "friday",
            from: "10:00",
            to: "20:00",
            breakFrom: null,
            breakTo: null,
            isActive: true,
          },
          {
            index: 6,
            day: "saturday",
            from: "10:00",
            to: "20:00",
            breakFrom: null,
            breakTo: null,
            isActive: true,
          },
          {
            index: 7,
            day: "sunday",
            from: "10:00",
            to: "20:00",
            breakFrom: null,
            breakTo: null,
            isActive: true,
          },
        ];

        await this.save();
      }

      return;
    }

    this.mode = "create";
  },

  mounted() {
    this.dataLoaded = true;
  },

  data() {
    return {
      mode: "", // create | edit
      showDevFeatures: false,
      dataLoaded: false,

      company: {
        analyticsLostClientPeriodDays: null,
      },

      branchTemplate: {
        isActive: true,
        address: "",
        phoneNumber: "",
        photoUrl: "",
        workingHours: [
          {
            index: 1,
            day: "monday",
            from: "10:00",
            to: "20:00",
            breakFrom: null,
            breakTo: null,
            isActive: true,
          },
          {
            index: 2,
            day: "tuesday",
            from: "10:00",
            to: "20:00",
            breakFrom: null,
            breakTo: null,
            isActive: true,
          },
          {
            index: 3,
            day: "wednesday",
            from: "10:00",
            to: "20:00",
            breakFrom: null,
            breakTo: null,
            isActive: true,
          },
          {
            index: 4,
            day: "thursday",
            from: "10:00",
            to: "20:00",
            breakFrom: null,
            breakTo: null,
            isActive: true,
          },
          {
            index: 5,
            day: "friday",
            from: "10:00",
            to: "20:00",
            breakFrom: null,
            breakTo: null,
            isActive: true,
          },
          {
            index: 6,
            day: "saturday",
            from: "10:00",
            to: "20:00",
            breakFrom: null,
            breakTo: null,
            isActive: true,
          },
          {
            index: 7,
            day: "sunday",
            from: "10:00",
            to: "20:00",
            breakFrom: null,
            breakTo: null,
            isActive: true,
          },
        ],
      },

      config: {
        allowInput: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
      },

      modalParamsRemoveBranch: {
        modalId: "modal-default-1",
        modalTitle: "Видалення",
        modalDescription:
          "Ви впевнені, що хочете видалити філіал? Після видалення, філіал відновити неможливо.",
        modalButtonCancelText: "Відмінити",
        modalButtonConfirmText: "Видалити",
        data: {},
        confirmHandler: async () => {
          try {
            await api.branches.removeOne(this.$route.params.id);

            this.$store.commit("addToast", {
              title: "Збережено",
            });

            await this.$router.push("/settings/company");
          } catch (error) {
            this.$store.commit("addToast", {
              title: "Виникла помилка. Спробуйте ще раз",
              type: "error",
            });
          }
        },
      },
    };
  },

  components: {
    BmnCustomPopup,
    flatPickr,
    Spinner,
  },

  methods: {
    async save() {
      try {
        if (this.mode === "create") {
          await api.branches.create(this.branchTemplate);
        } else if (this.mode === "edit") {
          await api.branches.updateOne(this.$route.params.id, {
            isActive: true,
            address: this.branchTemplate.address,
            phoneNumber: this.branchTemplate.phoneNumber,
            photoUrl: this.branchTemplate.photoUrl,
            workingHours: this.branchTemplate.workingHours,
            googleMapsLocationLink: this.branchTemplate.googleMapsLocationLink,
            googleMapsLocationIframe:
              this.branchTemplate.googleMapsLocationIframe,
          });
        }

        await api.company.update({
          analyticsLostClientPeriodDays:
            this.company.analyticsLostClientPeriodDays,
        });

        this.$store.commit("addToast", {
          title: "Збережено",
        });

        await this.$router.push("/settings/company");
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    addBreak(item) {
      item.breakFrom = "13:00";
      item.breakTo = "14:00";
    },

    removeBreak(item) {
      item.breakFrom = null;
      item.breakTo = null;
    },

    getDayName(day) {
      switch (day) {
        case "monday":
          return "Пн";
        case "tuesday":
          return "Вт";
        case "wednesday":
          return "Ср";
        case "thursday":
          return "Чт";
        case "friday":
          return "Пт";
        case "saturday":
          return "Сб";
        case "sunday":
          return "Вс";
        default:
          return "unknown";
      }
    },
  },
};
</script>

<style scoped>
.datetimepicker.flatpickr-input {
  text-align: center;
}
</style>
