const isStaffOnly = () => {
  let user;

  try {
    const localUser = localStorage.getItem("localUser");
    if (!localUser) {
      return false;
    }
    user = JSON.parse(localUser);
  } catch (error) {
    console.error(error);
    return false;
  }

  const rolesMap = user.rolesMap;
  if (!rolesMap) {
    return false;
  }

  const isOnlyStaff = !rolesMap.isRoot && !rolesMap.isOwner && !rolesMap.isManager && rolesMap.isStaff;
  return isOnlyStaff;
};

export default isStaffOnly;
