<template>
  <!-- NOW MVP -->
  <div class="wrapper-page-analytics container-fluid py-4" v-show="isLoading">
    <Spinner :loaded="!isLoading"></Spinner>
  </div>
  <div class="wrapper-page-analytics container-fluid py-4" v-show="dataLoaded">
    <div class="row infoBlock" v-if="company.crmMode === 'hotel'">
      <div class="d-flex align-items-center gap-2">
        <span class="material-symbols-outlined"> info </span>
        <label class="text-xs">Звіт будується за датою заїзду</label>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3">
        <label v-if="company.crmMode === 'hotel'">Дати заїзду</label>
        <label v-if="company.crmMode !== 'hotel'">Дати записів</label>
        <SelectApp
          class="form-control customPadding"
          v-model="selectedDateRange"
          :settings="{
            allowEmpty: false,
            multiple: false,
            // closeOnSelect: false,
            options: listDateRanges,
            trackBy: '_id',
            label: 'name',
          }"
          @select="filterByDateRange(selectedDateRange)"
        ></SelectApp>
      </div>

      <div class="col-lg-3">
        <label v-if="company.localizationMode === 'hotel'"
          >Обʼєкти розміщення</label
        >
        <label v-if="company.localizationMode === 'beauty'">Майстри</label>
        <label v-if="company.localizationMode === 'spa'">Ресурси</label>
        <SelectApp
          class="form-control customPadding"
          v-model="selectedStaffIds"
          :settings="{
            taggable: true,
            multiple: true,
            closeOnSelect: false,
            options: listStaff,
            trackBy: '_id',
            label: 'name',
            placeholder: getLabelStaff(), // TODO: rename
            groupSelect: true,
            groupLabel: 'categoryName',
            groupValues: 'rooms',
          }"
          @select="filterByStaffId()"
          @remove="filterByStaffId()"
        />
      </div>

      <div class="col-lg-3">
        <label v-if="company.crmMode === 'hotel'">Статус броні</label>
        <label v-if="company.crmMode !== 'hotel'">Статус запису</label>
        <SelectApp
          class="form-control customPadding"
          v-model="selectedStatus"
          :settings="{
            placeholder: 'Оберіть статус',
            allowEmpty: true,
            multiple: true,
            // closeOnSelect: false,
            options: listStatuses,
            trackBy: 'value',
            label: 'name',
          }"
          @select="filterByStatus()"
          @remove="filterByStatus()"
        ></SelectApp>
      </div>
    </div>
    <hr />
    <div class="row"></div>
    <div class="row" v-show="selectedDateRange?._id === 'custom'">
      <div class="col-lg-3 col-sm-12 text-end">
        <flat-pickr
          v-model="customSelectedDateRange"
          id="calendarFlatpickr"
          class="form-control datetimepicker"
          :config="calendarConfig"
          ref="datePickerWrap"
        ></flat-pickr>
        <div class="mb-4"></div>
      </div>
    </div>
    <div class="row">
      <h5>
        Додаткові послуги
        <div class="badge badge-info badge-sm">
          {{ computedSelectedDateRange }}
        </div>
      </h5>
    </div>
    <div class="row mt-3">
      <div class="row mb-3">
        <div class="row mt-lg-3">
          <div
            class="col-lg-4 col-md-6 col-sm-6 mt-lg-0 mt-3"
            v-for="(value, _, i) in metrics"
            v-bind:key="i"
          >
            <div class="card mb-2">
              <div class="card-header p-3 pt-2">
                <div
                  class="icon icon-md icon-shape bg-gradient-success shadow-secondary shadow text-center border-radius-xl mt-n4 position-absolute"
                >
                  <i class="material-icons opacity-10">payment</i>
                </div>
                <div class="text-end pt-1">
                  <p class="text-sm mb-0">{{ value.label }}</p>
                  <h4 class="mb-0">{{ value.value }} {{ value.currency }}</h4>
                </div>
              </div>
              <hr class="dark horizontal my-0" />
              <div class="card-footer p-3">
                <p class="text-xs">
                  {{ value.tooltip }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div class="col-lg-4 col-sm-6 mt-2">
        <div class="card h-100" style="height: 500px">
          <div class="card-header pb-0 p-3">
            <div class="d-flex justify-content-between">
              <h6 class="mb-0">Додаткові послуги за кількістю</h6>
            </div>
          </div>
          <div class="card-body pb-0 p-3 mb-2">
            <div class="row">
              <div class="col-12 text-start">
                <div
                  class="chart"
                  v-if="
                    charts.quantityByAdditionalService.data?.datasets[0]?.data
                      ?.length
                  "
                >
                  <Pie
                    :chart-data="charts.quantityByAdditionalService.data"
                    :chart-options="charts.quantityByAdditionalService.options"
                    :height="500"
                  />
                </div>

                <div
                  class="parent-container-no-data"
                  v-if="
                    !charts.quantityByAdditionalService.data?.datasets[0]?.data
                      ?.length
                  "
                >
                  <div class="vertical-center">Немає даних</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 mt-2">
        <div class="card h-100">
          <div class="card-header pb-0 p-3">
            <div class="d-flex justify-content-between">
              <h6 class="mb-0">Додаткові послуги за вартістю</h6>
            </div>
          </div>
          <div class="card-body pb-0 p-3 mb-2">
            <div class="col-12 text-start">
              <div
                class="chart"
                v-if="charts.priceByAdditionalService.data?.labels?.length"
              >
                <Pie
                  :chart-data="charts.priceByAdditionalService.data"
                  :chart-options="charts.priceByAdditionalService.options"
                />
              </div>

              <div
                class="parent-container-no-data"
                v-if="!charts.priceByAdditionalService.data?.labels?.length"
              >
                <div class="vertical-center">Немає даних</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-lg-12"> -->
    </div>
  </div>
  <div>
    <div
      class="wrapper-page-analytics container-fluid py-4"
      v-if="!chartsDataLoaded.loadByStaff"
    >
      <Spinner :loaded="chartsDataLoaded.loadByStaff"></Spinner>
    </div>

    <div class="table-responsive">
      <vue-good-table
        mode=""
        :pagination-options="{
          enabled: true,
          perPage: serverParams.perPage,
          position: 'bottom',
          perPageDropdownEnabled: false,
          nextLabel: 'наст.',
          prevLabel: 'попер.',
          rowsPerPageLabel: 'записів на сторінці',
          ofLabel: 'із',
          pageLabel: 'сторінка',
          allLabel: 'всі',
        }"
        :totalRows="totalRecords"
        v-model:isLoading="isLoading"
        :columns="datatableColumns"
        :rows="rows"
        :row-style-class="rowStyleClassFn"
        theme="default"
        styleClass="vgt-table bordered"
        responsive
        compactMode
        v-on:page-change="onTablePageChange"
      >
      </vue-good-table>
    </div>
  </div>
</template>

<style scoped>
:deep(.totalRowHighlight td) {
  font-weight: bold !important;
}

.wrapper-page-analytics >>> .flatpickr-input {
  color: #095bc6;
  background-color: #aecef7;
  border: 0;
  border-radius: 0.375rem;
  text-align: center;
  padding: 10px;
  font-weight: 700;
}

.bmn-staff-removed {
  text-decoration: line-through;
  opacity: 0.7;
}

.parent-container-no-data {
  height: 400px;
}

.vertical-center {
  font-size: x-large;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.infoBlock {
  background-color: #deede3;
  border-radius: 8px;
  margin-bottom: 16px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 12px 12px 12px 12px;
}

.card-footer {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.card-header {
  padding-bottom: 4px !important;
}
.customHeight {
  line-height: 1px !important;
}
p {
  margin-bottom: 0.2rem;
}
.customPadding {
  padding-top: 0px !important;
}
</style>

<script>
import { DateTime } from "luxon";
import * as _ from "lodash";
import Spinner from "@/views/shared/Spinner.vue";

import setTooltip from "@/assets/js/tooltip.js";
import api from "@/services/api";
import constants from "@/constants";

import flatPickr from "vue-flatpickr-component";
import { Ukrainian } from "flatpickr/dist/l10n/uk";

import MonthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "vue-chartjs";
import SelectApp from "@/components/common/SelectApp.vue";
import delay from "@/utils/delay";

ChartJS.register(ArcElement, Tooltip, Legend);

const paletteColors = [
  "#1A73E8",
  "#4CAF50",
  "#D6EAFC",
  "#FFFFDF",
  "#D3C4E3",
  "#B8DEDB",
  "#D3C4E3",
  "GoldenRod",
  "Lavender",
  "LavenderBlush",
  "LightBlue",
  "LightGoldenRodYellow",
  "NavajoWhite",
  "PaleGreen",
  "PaleTurquoise",
  "PapayaWhip",
  "PeachPuff",
  "Pink",
  "Plum",
  "RosyBrown",
  "Salmon",
  "SkyBlue",
  // "#F47A1F",
  // "#FDBB2F",
  // "#377B2B",
  // "#7AC142",
  // "#007CC3",
  // "#00529B",
  // "#6050DC",
  // "#D52DB7",
  // "#FF2E7E",
  // "#FF6B45",
  // "#FFAB05",
  // "#E6F69D",
  // "#AADEA7",
  // "#64C2A6",
  // "#2D87BB",
];

const getPeriod = (name) => {
  const period = {
    dateFrom: null,
    dateTo: null,
  };

  switch (name) {
    //   case "allTime":
    //     period.dateFrom = null;
    //     period.dateTo = null;
    //     break;

    case "currentWeek":
      period.dateFrom = DateTime.now().startOf("week").toISODate();
      period.dateTo = DateTime.now().endOf("week").toISODate();
      break;

    case "previousWeek":
      period.dateFrom = DateTime.now()
        .minus({ week: 1 })
        .startOf("week")
        .toISODate();
      period.dateTo = DateTime.now()
        .minus({ week: 1 })
        .endOf("week")
        .toISODate();
      break;

    case "currentMonth":
      period.dateFrom = DateTime.now().startOf("month").toISODate();
      period.dateTo = DateTime.now().endOf("month").toISODate();
      break;

    case "nextMonth":
      period.dateFrom = DateTime.now()
        .plus({ month: 1 })
        .startOf("month")
        .toISODate();
      period.dateTo = DateTime.now()
        .plus({ month: 1 })
        .endOf("month")
        .toISODate();
      break;

    case "previousMonth":
      period.dateFrom = DateTime.now()
        .minus({ month: 1 })
        .startOf("month")
        .toISODate();
      period.dateTo = DateTime.now()
        .minus({ month: 1 })
        .endOf("month")
        .toISODate();
      break;

    case "currentYear":
      period.dateFrom = DateTime.now().startOf("year").toISODate();
      period.dateTo = DateTime.now().endOf("year").toISODate();
      break;

    case "previousYear":
      period.dateFrom = DateTime.now()
        .minus({ year: 1 })
        .startOf("year")
        .toISODate();
      period.dateTo = DateTime.now()
        .minus({ year: 1 })
        .endOf("year")
        .toISODate();
      break;

    case "monthToDate": // MTD
      period.dateFrom = DateTime.now().startOf("month").toISODate();
      period.dateTo = DateTime.now().toISODate();
      break;

    case "yearToDate": // YTD
      period.dateFrom = DateTime.now().startOf("year").toISODate();
      period.dateTo = DateTime.now().toISODate();
      break;
  }

  return period;
};

export default {
  name: "ecommerce-analytics-by-additional-services",

  components: {
    SelectApp,
    Pie,
    flatPickr,
    Spinner,
  },

  data() {
    return {
      dataLoaded: false,
      isLoading: true,
      showDevFeatures: false,

      company: {
        crmMode: "",
        localizationMode: "",
      },

      // selectedDateRange: "currentMonth",
      selectedDateRange: {},
      listDateRanges: [
        {
          _id: "previousMonth",
          name: "Попередній місяць",
        },
        {
          _id: "currentMonth",
          name: "Поточний місяць",
        },
        {
          _id: "nextMonth",
          name: "Наступний місяць",
        },
        {
          _id: "currentWeek",
          name: "Поточний тиждень",
        },
        {
          _id: "previousWeek",
          name: "Попередній тиждень",
        },
        {
          _id: "currentYear",
          name: "Поточний рік",
        },
        {
          _id: "previousYear",
          name: "Попередній рік",
        },
        {
          _id: "monthToDate",
          name: "Поточний місяць (до сьогодні)",
        },
        {
          _id: "yearToDate",
          name: "Поточний рік (до сьогодні)",
        },
        {
          _id: "custom",
          name: "Довільні дати",
        },
      ],
      serverParams: {
        page: 1,
        perPage: constants.defaultDatatablePerPage,
      },
      datatableColumns: [
        {
          label: "Назва",
          field: "name",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
        },
        {
          label: "Кількість",
          field: "calculations.quantity",
          fieldId: "quantity",
          sortable: false,
          type: "string",
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
          // width: "140px",
        },
        {
          label: "Вартість, грн.",
          field: "calculations.totalPrice",
          fieldId: "totalPrice",
          sortable: false,
          type: "string",
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
          // width: "140px",
        },
      ],

      customSelectedDateRange: null,
      calendarConfig: {
        mode: "range",
        dateFormat: "d-m-Y",
        locale: Ukrainian,
        inline: true,
        disableMobile: true,
        onChange: async (selectedDates) => {
          if (_.isArray(selectedDates) && selectedDates.length === 2) {
            this.selectedDateFrom = DateTime.fromJSDate(
              selectedDates[0]
            ).toISODate();
            this.selectedDateTo = DateTime.fromJSDate(
              selectedDates[1]
            ).toISODate();
            await this.fetchData();
          }
        },
      },

      metrics: {
        totalQuantity: {
          label: "Кількість",
          tooltip: "Кількість всіх додаткових послуг",
          value: 0,
          currency: "",
        },
        totalPrice: {
          label: "Вартість",
          tooltip: "Сума всіх вартостей за додаткові послуги",
          value: 0,
          currency: "грн",
        },
      },

      rows: [],
      totalRecords: 0,

      selectedClientsPeriodFrom: null,
      selectedClientsPeriodTo: null,
      clientsPeriod: null,
      calendarConfigClients: {
        defaultDate: Date.now(),
        plugins: [
          new MonthSelectPlugin({
            shorthand: false, //defaults to false
            dateFormat: "🗓 F Y", //defaults to "F Y"
            altFormat: "🗓 F Y", //defaults to "F Y"
            theme: "light", // defaults to "light"
          }),
        ],
        locale: Ukrainian,
        inline: false,
        disableMobile: true,
        onChange: async (selectedDates) => {
          if (!_.isArray(selectedDates) || selectedDates.length !== 1) {
            selectedDates = [new Date()];
          }

          this.selectedClientsPeriodFrom = DateTime.fromJSDate(selectedDates[0])
            .startOf("month")
            .toISODate();
          this.selectedClientsPeriodTo = DateTime.fromJSDate(selectedDates[0])
            .endOf("month")
            .toISODate();

          const todayDate = DateTime.now().toISODate();
          if (
            DateTime.fromISO(todayDate).startOf("month").toISODate() ===
            this.selectedClientsPeriodFrom
          ) {
            // exception: current month -> to current date
            this.selectedClientsPeriodTo = todayDate;
          }
        },
        maxDate: Date.now(),
      },

      selectedStaffIds: [],
      listStaff: [],
      listStatuses: [
        {
          _id: "new",
          name: "Новий",
          value: "new",
        },
        {
          _id: "done",
          name: "Клієнт прийшов",
          value: "done",
        },
        {
          _id: "cancelled",
          name: "Відмінено",
          value: "cancelled",
        },
      ],

      selectedDateFrom: null,
      selectedDateTo: null,
      selectedStatus: [],
      chartsDataLoaded: {
        loadByStaff: false,
      },
      charts: {
        quantityByAdditionalService: {
          data: {
            labels: [],
            datasets: [
              {
                backgroundColor: paletteColors,
                data: [],
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: true,
          },
        },
        priceByAdditionalService: {
          data: {
            labels: [],
            datasets: [
              {
                backgroundColor: paletteColors,
                data: [],
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
              tooltip: {
                callbacks: {
                  label: (context) => {
                    return `${context.label}: ${context.parsed} грн`;
                  },
                },
              },
            },
          },
        },
      },
    };
  },

  async created() {
    this.company = await api.company.show();

    // TODO: set includeRemoved to 1 to include removed staffs
    const staff = await api.staff.list({ includeRemoved: 0 });

    let resultArr = [];
    staff.forEach((item) => {
      const categoryIndex = resultArr.findIndex(
        (category) => category.categoryId === item.positionId._id
      );
      const roomItem = {
        name: item.name,
        _id: item._id,
      };

      if (categoryIndex >= 0) {
        resultArr[categoryIndex].rooms.push(roomItem);
      } else {
        const categoryName = item.positionId.name;
        const categoryId = item.positionId._id;
        const rank = item.positionId.rank;

        const itemWithCategory = {
          categoryName,
          categoryId,
          rank,
          rooms: [roomItem],
        };
        resultArr.push(itemWithCategory);
      }
    });

    resultArr = _.sortBy(resultArr, "rank");

    this.listStaff = resultArr;

    this.listStaff.forEach((item) => {
      if (item.isRemoved) {
        item.name = `${item.name} [видалений]`;
        return;
      }
      if (!item.isActive) {
        item.name = `${item.name} [не активний]`;
        return;
      }
    });

    const defaultPeriod = getPeriod("currentMonth");
    this.selectedDateFrom = defaultPeriod.dateFrom;
    this.selectedDateTo = defaultPeriod.dateTo;

    this.selectedDateRange = this.listDateRanges[1];
    this.selectedStatus = [this.listStatuses[0], this.listStatuses[1]];

    await this.fetchData();
  },

  async mounted() {
    this.showDevFeatures = !!localStorage.getItem("showDevFeatures");
    setTimeout(setTooltip, 200);
    await this.calendarConfigClients.onChange();
    this.dataLoaded = true;
    this.isLoading = false;
  },

  methods: {
    getLabelStaff() {
      const localizationMode = this.company?.localizationMode;

      switch (true) {
        case localizationMode === "spa":
          return "Всі ресурси";
        case localizationMode === "hotel":
          return "Всі номери";
        default:
          return "Всі майстри";
      }
    },

    rowStyleClassFn(row) {
      if (row.isTotalRow) {
        return "totalRowHighlight";
      }
      return "";
    },

    async fetchData() {
      this.chartsDataLoaded.loadByStaff = false;
      this.isLoading = true;

      const payload = {
        scope: "general",
      };

      if (this.selectedStaffIds.length) {
        payload.staffIds = this.selectedStaffIds.map((item) => {
          return item._id;
        });
      }

      if (this.selectedDateFrom) {
        payload.dateFrom = this.selectedDateFrom;
      }

      if (this.selectedDateTo) {
        payload.dateTo = this.selectedDateTo;
      }

      if (this.selectedStatus.length) {
        payload.statuses = this.selectedStatus.map((item) => {
          return item._id;
        });
      }

      const result = await api.analytics.getAdditionalServices(payload);

      this.metrics.totalPrice.value = result.totals.totalPrice;
      this.metrics.totalQuantity.value = result.totals.quantity;

      const labelTotal = "Всього";

      this.rows = result.additionalServicesListSorted;
      this.rows.push({
        name: labelTotal,
        calculations: {
          quantity: result.totals.quantity,
          totalPrice: result.totals.totalPrice,
        },
        isTotalRow: true,
      });
      this.totalRecords = result.additionalServicesListSorted.length;

      this.charts.quantityByAdditionalService.data.datasets[0].data = [];
      this.charts.quantityByAdditionalService.data.labels = [];

      this.charts.priceByAdditionalService.data.datasets[0].data = [];
      this.charts.priceByAdditionalService.data.labels = [];

      this.rows.forEach((additionalService) => {
        if (additionalService.name === labelTotal) {
          return;
        }

        if (additionalService.calculations.quantity) {
          this.charts.quantityByAdditionalService.data.datasets[0].data.push(
            additionalService.calculations.quantity
          );
          this.charts.quantityByAdditionalService.data.labels.push(
            additionalService.name
          );
        }

        if (additionalService.calculations.totalPrice) {
          this.charts.priceByAdditionalService.data.datasets[0].data.push(
            additionalService.calculations.totalPrice
          );
          this.charts.priceByAdditionalService.data.labels.push(
            additionalService.name
          );
        }
      });

      this.chartsDataLoaded.loadByStaff = true;
      this.isLoading = false;
    },

    async filterByDateRange(dateRange) {
      this.selectedDateRange = dateRange;

      const rangeValue = getPeriod(dateRange._id);

      this.selectedDateFrom = rangeValue.dateFrom;
      this.selectedDateTo = rangeValue.dateTo;

      if (dateRange._id !== "custom") {
        this.$refs.datePickerWrap.fp.clear();

        await this.fetchData();
      }
    },

    async filterByStaffId(staffId) {
      await delay(100);
      await this.fetchData();
    },
    async filterByStatus() {
      await this.fetchData();
    },

    timestampToString(input) {
      if (!input) {
        return "-";
      }
      const datetime = DateTime.fromISO(input);
      return datetime
        .setLocale("uk-UA")
        .toLocaleString({ month: "short", day: "numeric", year: "numeric" });
    },
    async onTablePageChange(params) {},
  },

  computed: {
    computedSelectedDateRange() {
      let from = "(Дата з)";
      let to = "(Дата до)";
      if (this.selectedDateFrom) {
        from = DateTime.fromISO(this.selectedDateFrom)
          .setLocale("uk-UA")
          .toFormat("dd.MM.yyyy");
      }
      if (this.selectedDateTo) {
        to = DateTime.fromISO(this.selectedDateTo)
          .setLocale("uk-UA")
          .toFormat("dd.MM.yyyy");
      }
      return `${from} - ${to}`;
    },
  },
};
</script>
