<template>
  <div class="container-fluid my-3 py-3">
    <div>
      <router-link
        to="/service-orders/new"
        class="btn btn-icon bg-gradient-primary"
        data-bs-toggle="modal"
        data-bs-target="#payoff"
      >
        + Виплата
      </router-link>
      <!-- Modal -->
      <div
        class="modal fade"
        id="payoff"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title font-weight-normal" id="exampleModalLabel">
                Виплата: Віктор Купцов
              </h5>
              <button
                type="button"
                class="btn-close text-dark"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <label class=""> Оберіть касу: </label>
              <div class="form-check mb-1">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="customRadio1"
                />
                <label class="custom-control-label text-sm" for="customRadio1"
                  >Готівка (баланс: 30550 грн)</label
                >
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="customRadio2"
                />
                <label class="custom-control-label text-sm" for="customRadio2"
                  >Картка (баланс: 0 грн)</label
                >
              </div>
              <label class="mb-0"> Сума: </label>
              <div class="input-group input-group-sm input-group-outline">
                <input
                  type="number"
                  class="form-control form-control-md"
                  placeholder="0 грн"
                />
              </div>
              <label class="mb-0"> Коментар: </label>
              <div class="input-group input-group-sm input-group-outline">
                <input
                  type="text"
                  class="form-control form-control-md"
                  placeholder="Будь-який коментар"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn bg-gradient-secondary"
                data-bs-dismiss="modal"
              >
                Закрити
              </button>
              <button type="button" class="btn bg-gradient-primary">
                Виплатити
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <!-- Card Общая информация -->
        <div class="col-lg-12 col-12 mt-md-0 mt-4">
          <div class="card h-100 mb-4">
            <div class="card-header pb-0 px-3">
              <div class="row">
                <div class="col-9">
                  <h5>Зарплатня: Віктор Купцов</h5>
                </div>
              </div>
              <div class="col-lg-4">
                <ul class="list-group">
                  <li
                    class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                  >
                    <div class="d-flex flex-column">
                      <h6 class="mb-1 font-weight-bold text-sm">
                        На балансі співробітника
                        <span class="text-bold text-danger text-sm"
                          >1732 грн</span
                        >
                      </h6>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h6 class="mb-0">Транзакції</h6>
                </div>
                <div
                  class="col-md-6 d-flex justify-content-start justify-content-md-end align-items-center"
                >
                  <i class="material-icons me-2 text-lg">date_range</i>
                  <small>23 - 30 March 2020</small>
                </div>
              </div>
            </div>
            <div class="card-body pt-4 p-3">
              <hr />
              <div class="row">
                <div class="col-6 text-start">
                  <h6
                    class="text-uppercase text-body text-sm font-weight-bolder my-3"
                  >
                    14.10.2022
                  </h6>
                </div>
                <div class="col-6 text-end my-3">
                  <h6 class="text-body text-sm font-weight-bolder"></h6>
                </div>
              </div>
              <ul class="list-group">
                <li
                  class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                >
                  <div class="d-flex align-items-center">
                    <button
                      class="btn btn-icon-only btn-rounded btn-outline-danger mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center"
                    >
                      <i class="material-icons text-lg">expand_more</i>
                    </button>
                    <div class="d-flex flex-column">
                      <h6 class="mb-1 text-dark text-sm mb-0">
                        Нарахування
                        <a target="_blank">
                          <button
                            type="button"
                            class="btn mb-3 mb-md-0 ms-auto"
                            data-bs-toggle="modal"
                            name="button"
                            data-bs-target="#editoutcometransaction"
                          >
                            <i
                              class="material-icons text-info position-relative"
                              >drive_file_rename_outline</i
                            >
                          </button>
                          <!-- Modal -->
                          <div
                            class="modal fade"
                            id="editoutcometransaction"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div
                              class="modal-dialog modal-dialog-centered"
                              role="document"
                            >
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5
                                    class="modal-title font-weight-normal"
                                    id="exampleModalLabel"
                                  >
                                    Редагувати нарахування
                                  </h5>
                                  <button
                                    type="button"
                                    class="btn-close text-dark"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <!-- Operations -->
                                  <div class="row mx-1"></div>
                                  <label> Сума: </label>
                                  <div
                                    class="input-group input-group-outline my-1 mt-0"
                                  >
                                    <input
                                      type="number"
                                      class="form-control"
                                      placeholder="350 грн"
                                    />
                                  </div>

                                  <!-- Operations finish -->
                                </div>
                                <div class="modal-footer">
                                  <button
                                    type="button"
                                    class="btn bg-gradient-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    закрити
                                  </button>
                                  <button
                                    type="button"
                                    class="btn bg-gradient-danger"
                                    data-bs-dismiss="modal"
                                  >
                                    Видалити
                                  </button>
                                  <button
                                    type="button"
                                    class="btn bg-gradient-primary"
                                  >
                                    Зберегти
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </h6>
                      <span class="text-xs mb-0"
                        >Схема: Схема для співробітників</span
                      >
                      <span class="text-xs mb-0"
                        >Правило: Відсоток від вартості послуги (15%)
                        <a target="_blank">
                          <i
                            class="material-icons text-md me-2 cursor-pointer text-info"
                            >send</i
                          >
                        </a>
                      </span>
                      <span class="text-xs">Послуга: Стрижка (350 грн)</span>
                    </div>
                  </div>
                  <div
                    class="d-flex text-end text-danger text-gradient text-sm"
                  >
                    52,50 грн
                  </div>
                </li>
                <li
                  class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                >
                  <div class="d-flex align-items-center">
                    <button
                      class="btn btn-icon-only btn-rounded btn-outline-danger mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center"
                    >
                      <i class="material-icons text-lg">expand_more</i>
                    </button>
                    <div class="d-flex flex-column">
                      <h6 class="mb-1 text-dark text-sm mb-0">
                        Нарахування
                        <a target="_blank">
                          <button
                            type="button"
                            class="btn mb-3 mb-md-0 ms-auto"
                            data-bs-toggle="modal"
                            name="button"
                            data-bs-target="#editoutcometransaction"
                          >
                            <i
                              class="material-icons text-info position-relative"
                              >drive_file_rename_outline</i
                            >
                          </button>
                          <!-- Modal -->
                          <div
                            class="modal fade"
                            id="editoutcometransaction"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          ></div>
                        </a>
                      </h6>
                      <span class="text-xs mb-0"
                        >Схема: Схема для співробітників</span
                      >
                      <span class="text-xs mb-0"
                        >Правило: фіксована сума від виконаної послуги (50 грн)
                        <a target="_blank">
                          <i
                            class="material-icons text-md me-2 cursor-pointer text-info"
                            >send</i
                          >
                        </a>
                      </span>
                      <span class="text-xs">Послуга: Стрижка (350 грн)</span>
                    </div>
                  </div>
                  <div
                    class="d-flex text-end text-danger text-gradient text-sm"
                  >
                    50 грн
                  </div>
                </li>
                <li
                  class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                >
                  <div class="d-flex align-items-center">
                    <button
                      class="btn btn-icon-only btn-rounded btn-outline-success mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center"
                    >
                      <i class="material-icons text-lg">expand_less</i>
                    </button>
                    <div class="d-flex flex-column">
                      <h6 class="mb-1 text-dark text-sm">
                        Виплата
                        <a target="_blank">
                          <button
                            type="button"
                            class="btn mb-3 mb-md-0 ms-auto"
                            data-bs-toggle="modal"
                            name="button"
                            data-bs-target="#editincomemetransaction"
                          >
                            <i
                              class="material-icons text-info position-relative"
                              >drive_file_rename_outline</i
                            >
                          </button>
                          <!-- Modal -->
                          <div
                            class="modal fade"
                            id="editincomemetransaction"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div
                              class="modal-dialog modal-dialog-centered"
                              role="document"
                            >
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5
                                    class="modal-title font-weight-normal"
                                    id="exampleModalLabel"
                                  >
                                    Редагувати виплату
                                  </h5>
                                  <button
                                    type="button"
                                    class="btn-close text-dark"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <label class=""> Оберіть касу: </label>
                                  <div class="form-check mb-1">
                                    <input
                                      class="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="customRadio1"
                                    />
                                    <label
                                      class="custom-control-label text-sm"
                                      for="customRadio1"
                                      >Готівка (баланс: 30550 грн)</label
                                    >
                                  </div>
                                  <div class="form-check">
                                    <input
                                      class="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="customRadio2"
                                    />
                                    <label
                                      class="custom-control-label text-sm"
                                      for="customRadio2"
                                      >Картка (баланс: 0 грн)</label
                                    >
                                  </div>
                                  <label class="mb-0"> Сума: </label>
                                  <div
                                    class="input-group input-group-sm input-group-outline"
                                  >
                                    <input
                                      type="number"
                                      class="form-control form-control-md"
                                      placeholder="0 грн"
                                    />
                                  </div>
                                  <label class="mb-0"> Коментар: </label>
                                  <div
                                    class="input-group input-group-sm input-group-outline"
                                  >
                                    <input
                                      type="any"
                                      class="form-control form-control-md"
                                      placeholder="Будь-який коментар"
                                    />
                                  </div>
                                </div>
                                <div class="modal-footer">
                                  <button
                                    type="button"
                                    class="btn bg-gradient-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    Закрити
                                  </button>
                                  <button
                                    type="button"
                                    class="btn bg-gradient-danger"
                                    data-bs-dismiss="modal"
                                  >
                                    Видалити
                                  </button>
                                  <button
                                    type="button"
                                    class="btn bg-gradient-primary"
                                  >
                                    Виплатити
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </h6>
                      <span class="text-xs mb-0">З каси: Готівка</span>
                      <span class="text-xs mb-0">Адмін: Артем Павлов</span>
                      <span class="text-xs mb-0">Коментар: дякую!</span>
                    </div>
                  </div>
                  <div
                    class="d-flex text-success text-gradient text-sm text-end"
                  >
                    102,50 грн
                  </div>
                </li>
              </ul>
              <hr />
              <div class="row">
                <div class="col-6 text-start">
                  <h6
                    class="text-uppercase text-body text-sm font-weight-bolder my-3"
                  >
                    12.10.2022
                  </h6>
                </div>
                <div class="col-6 text-end my-3">
                  <h6 class="text-body text-sm font-weight-bolder"></h6>
                </div>
              </div>
              <ul class="list-group">
                <li
                  class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                >
                  <div class="d-flex align-items-center">
                    <button
                      class="btn btn-icon-only btn-rounded btn-outline-danger mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center"
                    >
                      <i class="material-icons text-lg">expand_more</i>
                    </button>
                    <div class="d-flex flex-column">
                      <h6 class="mb-1 text-dark text-sm mb-0">
                        Нарахування
                        <a target="_blank">
                          <i
                            class="material-icons text-md me-2 cursor-pointer text-info"
                            >edit</i
                          >
                        </a>
                      </h6>
                      <span class="text-xs mb-0"
                        >Схема: Схема для співробітників</span
                      >
                      <span class="text-xs mb-0"
                        >Правило: Відсоток від вартості послуги (40%)
                        <a target="_blank">
                          <i
                            class="material-icons text-md me-2 cursor-pointer text-info"
                            >send</i
                          >
                        </a>
                      </span>
                      <span class="text-xs">Послуга: Стрижка (350 грн)</span>
                    </div>
                  </div>
                  <div
                    class="d-flex text-end text-danger text-gradient text-sm"
                  >
                    140 грн
                  </div>
                </li>
                <li
                  class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                >
                  <div class="d-flex align-items-center">
                    <button
                      class="btn btn-icon-only btn-rounded btn-outline-danger mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center"
                    >
                      <i class="material-icons text-lg">expand_more</i>
                    </button>
                    <div class="d-flex flex-column">
                      <h6 class="mb-1 text-dark text-sm mb-0">
                        Нарахування
                        <a target="_blank">
                          <i
                            class="material-icons text-md me-2 cursor-pointer text-info"
                            >edit</i
                          >
                        </a>
                      </h6>
                      <span class="text-xs mb-0"
                        >Схема: Схема для співробітників</span
                      >
                      <span class="text-xs mb-0"
                        >Правило: Відсоток від вартості послуги (40%)
                        <a target="_blank">
                          <i
                            class="material-icons text-md me-2 cursor-pointer text-info"
                            >send</i
                          >
                        </a>
                      </span>
                      <span class="text-xs">Послуга: Стрижка (350 грн)</span>
                    </div>
                  </div>
                  <div
                    class="d-flex text-end text-danger text-gradient text-sm"
                  >
                    140 грн
                  </div>
                </li>
                <li
                  class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                >
                  <div class="d-flex align-items-center">
                    <button
                      class="btn btn-icon-only btn-rounded btn-outline-danger mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center"
                    >
                      <i class="material-icons text-lg">expand_more</i>
                    </button>
                    <div class="d-flex flex-column">
                      <h6 class="mb-1 text-dark text-sm mb-0">
                        Нарахування
                        <a target="_blank">
                          <i
                            class="material-icons text-md me-2 cursor-pointer text-info"
                            >edit</i
                          >
                        </a>
                      </h6>
                      <span class="text-xs mb-0"
                        >Схема: Схема для співробітників</span
                      >
                      <span class="text-xs mb-0"
                        >Правило: Відсоток від вартості послуги (40%)
                        <a target="_blank">
                          <i
                            class="material-icons text-md me-2 cursor-pointer text-info"
                            >send</i
                          >
                        </a>
                      </span>
                      <span class="text-xs">Послуга: Стрижка (350 грн)</span>
                    </div>
                  </div>
                  <div
                    class="d-flex text-end text-danger text-gradient text-sm"
                  >
                    140 грн
                  </div>
                </li>
                <li
                  class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                >
                  <div class="d-flex align-items-center">
                    <button
                      class="btn btn-icon-only btn-rounded btn-outline-success mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center"
                    >
                      <i class="material-icons text-lg">expand_less</i>
                    </button>
                    <div class="d-flex flex-column">
                      <h6 class="mb-1 text-dark text-sm">
                        Виплата
                        <a target="_blank">
                          <i
                            class="material-icons text-md me-2 cursor-pointer text-info"
                            >edit</i
                          >
                        </a>
                      </h6>
                      <span class="text-xs mb-0">З каси: Готівка</span>
                      <span class="text-xs mb-0">Адмін: Артем Павлов</span>
                      <span class="text-xs mb-0">Коментар: дякую!</span>
                    </div>
                  </div>
                  <div
                    class="d-flex text-success text-gradient text-sm text-end"
                  >
                    420 грн
                  </div>
                </li>
                <li
                  class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                >
                  <div class="d-flex align-items-center">
                    <button
                      class="btn btn-icon-only btn-rounded btn-outline-dark mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center"
                    >
                      <i class="material-icons text-lg">priority_high</i>
                    </button>
                    <div class="d-flex flex-column">
                      <h6 class="mb-1 text-dark text-sm">Нарахування</h6>
                      <span class="text-xs mb-0"
                        >Схема: Схема для співробітників</span
                      >
                      <span class="text-xs mb-0"
                        >Правило: Мінімальна сума за вихід (300 грн)
                      </span>
                    </div>
                  </div>
                  <div class="d-flex align-items-center text-dark text-sm">
                    відмінено
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Card Общая информация finish -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.comission {
  min-width: 90px;
  max-width: 90px;
}
.col {
  flex: 0 0 0%;
}
</style>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";

export default {
  name: "salary",
  data() {
    return {};
  },
  mounted() {
    setNavPills();
    setTooltip();
  },
};
</script>
