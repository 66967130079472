<template>
  <div class="container-fluid my-3 py-3" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid" v-show="dataLoaded">
    <div class="row mb-5">
      <div class="col-lg-10 mx-auto mt-2">
        <div class="row">
          <div class="available">
            <div class="element">Доступність</div>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="positionTemplate.isActive"
              />
            </div>
          </div>
        </div>
        <!-- Card Basic Info -->
        <div class="card" id="basic-info">
          <div class="card-body pt-0 mt-2">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label>Назва категорії</label>
                <div class="input-group input-group-outline">
                  <input
                    v-model="positionTemplate.name"
                    type="text"
                    class="form-control"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label>Детальний опис</label>
                <div class="input-group input-group-outline">
                  <textarea
                    v-model="positionTemplate.description"
                    class="form-control"
                    placeholder=""
                    rows="2"
                    @input="autoResizeTextarea"
                    style="height: 75px"
                    ref="description"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Card Basic Info finish -->

        <!-- Card Удалить должность -->
        <div class="card mt-4" id="delete" v-if="this.positionTemplate._id">
          <div class="card-body">
            <div class="d-flex align-items-center mb-sm-0 mb-4">
              <div class="w-50">
                <h6>Видалити</h6>
              </div>
              <div class="w-50 text-end">
                <!-- <button
                  class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto"
                  type="button"
                  name="button"
                >
                  Восстановить
                </button> -->
                <button
                  class="btn bg-gradient-danger mb-0 ms-2"
                  type="button"
                  name="button"
                  data-bs-toggle="modal"
                  :data-bs-target="`#${modalParamsRemovePosition.modalId}`"
                >
                  Видалити
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Удалить должность finish -->

        <!--  Save Button -->
        <div class="fixed-bottom mb-0">
          <div class="row">
            <div class="col-10"></div>
            <div class="col-lg-2">
              <div class="mx-4">
                <button
                  type="button"
                  class="block btn bg-gradient-primary"
                  @click="save"
                >
                  Зберегти
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--  Save Button finish -->
      </div>
    </div>
  </div>

  <BmnCustomPopup :params="modalParamsRemovePosition"></BmnCustomPopup>
</template>
<style scoped>
.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}
.available {
  display: flex;
  align-items: center; /* Выравниваем элементы по центру по вертикали */
  justify-content: flex-end;
}
.element {
  margin-right: 10px;
}
</style>
<script>
import api from "../../../../services/api";
import BmnCustomPopup from "@/components/BmnCustomPopup.vue";
import Spinner from "@/views/shared/Spinner.vue";
import { autoResizeTextarea } from "@/components/common/functions/autoResizeTextarea";
export default {
  name: "settings-position",

  components: {
    BmnCustomPopup,
    Spinner,
  },

  async beforeMount() {
    if (this.$route.params.id) {
      this.mode = "edit";
      this.positionTemplate = await api.positions.findOne(
        this.$route.params.id
      );
    } else {
      this.mode = "create";
    }

    setTimeout(autoResizeTextarea, 1000, {
      target: this.$refs.description,
    });

    this.dataLoaded = true;
  },

  data() {
    return {
      dataLoaded: false,
      mode: "", // create | edit

      positionTemplate: {
        name: "",
        description: "",
        isActive: true,
      },

      modalParamsRemovePosition: {
        modalId: "modal-default-1",
        modalTitle: "Видалення",
        modalDescription:
          "Ви впевнені, що хочете видалити посаду? Після видалення, посаду відновити неможливо.",
        modalButtonCancelText: "Відмінити",
        modalButtonConfirmText: "Видалити",
        data: {},
        confirmHandler: async () => {
          try {
            await api.positions.removeOne(this.$route.params.id);

            this.$store.commit("addToast", {
              title: "Збережено",
            });

            await this.$router.push("/settings/positions");
          } catch (error) {
            this.$store.commit("addToast", {
              title: "Виникла помилка. Спробуйте ще раз",
              type: "error",
            });
          }
        },
      },
    };
  },

  methods: {
    autoResizeTextarea,
    async save() {
      try {
        if (this.mode === "create") {
          await api.positions.create(this.positionTemplate);
        } else if (this.mode === "edit") {
          await api.positions.updateOne(
            this.$route.params.id,
            this.positionTemplate
          );
        }

        this.$store.commit("addToast", {
          title: "Збережено",
        });

        await this.$router.push("/settings/positions");
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },
  },
};
</script>
