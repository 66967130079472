<template>
  <div class="container-fluid my-3 py-3">
    <div class="row mb-5">
      <div class="col-lg-12 mt-lg-0 mt-4">
        <!-- Сертифікати -->
        <div class="card mt-4 pb-3" id="certificates">
          <div class="badge bg-gradient-info">DEV</div>
          <div class="card-header">
            <h6>Сертифікати</h6>
            <p class="text-sm mb-0">
              Додайте сертифікат Клієнту і він зможе їм розрахуватись у
              майбутньому
            </p>
            <button
              type="button"
              class="btn btn-sm bg-gradient-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              + сертифікат
            </button>
            <!-- Modal -->
            <div
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5
                      class="modal-title font-weight-normal"
                      id="exampleModalLabel"
                    >
                      + Сертифікат
                    </h5>
                    <button
                      type="button"
                      class="btn-close text-dark"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="form-check mb-3">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="customRadio1"
                      />
                      <label class="custom-control-label" for="customRadio1"
                        >Сертифікат на суму</label
                      >
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="customRadio2"
                      />
                      <label class="custom-control-label" for="customRadio2"
                        >Сертифікат на послугу</label
                      >
                    </div>
                    <div class="input-group input-group-outline mb-2 certsum">
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Сума грн"
                      />
                    </div>
                    <div class="input-group input-group-static">
                      <label for="exampleFormControlSelect2" class="ms-0"
                        >Оберіть послугу (можна декаілька):</label
                      >
                      <select
                        multiple=""
                        class="form-control pb-4"
                        id="exampleFormControlSelect2"
                      >
                        <option>Стрижка</option>
                        <option>Стрижка бороди</option>
                        <option>Масаж спини</option>
                        <option>Масаж стоп</option>
                        <option>Педікюр</option>
                      </select>
                    </div>
                    <div class="input-group input-group-static certsum">
                      <label class="mt-2">Дійсний до:</label>
                      <flat-pickr
                        class="form-control datetimepicker text-xs"
                        placeholder="Оберіть дату"
                      ></flat-pickr>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn bg-gradient-secondary"
                      data-bs-dismiss="modal"
                    >
                      закрити
                    </button>
                    <button type="button" class="btn bg-gradient-primary">
                      Зберегти
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-hover" id="datatable-search">
              <thead class="thead-light text-center">
                <tr>
                  <th class="text-xs">Дата створення</th>
                  <th class="text-xs">Дійсний до</th>
                  <th class="text-xs">На суму/послугу</th>
                  <th class="text-xs">Менеджер</th>
                  <th class="text-xs">Клієнт</th>
                  <th class="text-xs">Коментар</th>
                  <th class="text-xs">Ціна</th>
                  <th class="text-xs">Статус</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-xs font-weight-normal text-center">
                    <span class="my-2 text-xs">03.05.2022</span>
                  </td>
                  <td class="text-xs font-weight-normal text-center">
                    <span class="my-2 text-xs">03.05.2023</span>
                  </td>
                  <td class="text-xs font-weight-normal text-center">
                    <span class="my-2 text-xs">1000 грн</span>
                  </td>
                  <td class="text-xs font-weight-normal text-center">
                    <span class="my-2 text-xs">Сергей Петров</span>
                  </td>
                  <td class="text-xs font-weight-normal text-center">
                    <span class="my-2 text-xs">Артем Павлов</span>
                  </td>
                  <td class="text-xs font-weight-normal text-center">
                    <span class="my-2 text-xs">блабла</span>
                  </td>
                  <td
                    class="text-xs font-weight-normal text-success text-center"
                  >
                    <span class="my-2 text-xs">1000 грн </span>
                  </td>
                  <td
                    class="text-xs font-weight-normal text-success text-center"
                  >
                    <span class="badge bg-gradient-success text-xxs"
                      >Використан</span
                    >
                  </td>
                </tr>
                <tr>
                  <td class="text-xs font-weight-normal text-center">
                    <span class="my-2 text-xs">01.05.2022</span>
                  </td>
                  <td class="text-xs font-weight-normal text-center">
                    <span class="my-2 text-xs">03.05.2023</span>
                  </td>
                  <td class="text-xs font-weight-normal text-center">
                    <span class="my-2 text-xs">Стрижка бороди</span>
                  </td>
                  <td class="text-xs font-weight-normal text-center">
                    <span class="my-2 text-xs">Сергей Петров</span>
                  </td>
                  <td class="text-xs font-weight-normal text-center">
                    <span class="my-2 text-xs">Виктор Купцов</span>
                  </td>
                  <td class="text-xs font-weight-normal text-center">
                    <span class="my-2 text-xs">блабла</span>
                  </td>
                  <td
                    class="text-xs font-weight-normal text-success text-center"
                  >
                    <span class="my-2 text-xs">400 грн</span>
                  </td>
                  <td
                    class="text-xs font-weight-normal text-success text-center"
                  >
                    <span class="badge bg-gradient-info text-xxs">Новий</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Сертифікати finish -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.certsum {
  max-width: 50%;
}
</style>

<script>
import flatPickr from "vue-flatpickr-component";

export default {
  name: "client-details",

  components: {
    flatPickr,
  },
};
</script>
