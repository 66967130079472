import { ref } from "vue";

function useScheduler() {
  const schedulerWrapRef = ref(null);
  function setHeightScheduler(selector, heightFooter = 50) {
    if (selector) {
      const toTopDistance =
        selector.getBoundingClientRect().top + document.body.scrollTop;
      selector.style.setProperty(
        "height",
        `calc(100dvh - ${heightFooter + toTopDistance}px)`
      );
    }
  }
  return { schedulerWrapRef, setHeightScheduler };
}
export { useScheduler };
