<template>
  <div
    ref="modalRef"
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title font-weight-normal" id="exampleModalLabel">
            + Параметр
          </h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="$emit('close')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>
            Створення нового параметра, за яким ви будете отримувати оцінку від
            клієнтів
          </p>
          <p v-if="attribute?.isDefault">
            Це системний параметр. Його назву змінити неможливо, але можливо
            його вимкнути.
          </p>
          <label class="form-label">Назва</label>
          <div class="input-group input-group-outline mb-4">
            <input
              type="text"
              class="form-control"
              v-model="formData.label"
              :disabled="attribute?.isDefault"
              :readonly="attribute?.isDefault"
            />
          </div>
          <label class="form-label">Статус</label>
          <div class="form-check customCheck">
            <input
              class="form-check-input customCheckbox"
              type="checkbox"
              value=""
              id="fcustomCheck1"
              v-model="formData.enabled"
            />
            <label class="custom-control-label" for="customCheck1"
              >Увімкнено</label
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary"
            data-bs-dismiss="modal"
            @click="$emit('close')"
          >
            Закрити
          </button>
          <button
            type="button"
            class="btn bg-gradient-primary"
            @click="save"
            :disabled="!formData.label"
          >
            Зберегти
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import api from "@/services/api";
import {
  ref,
  reactive,
  onMounted,
  defineEmits,
  onBeforeUnmount,
  defineProps,
} from "vue";

import { useStore } from "vuex";
import Modal from "bootstrap/js/dist/modal";

const emit = defineEmits(["submit", "close"]);
const store = useStore();

const props = defineProps({
  attribute: {
    type: Object,
  },
});

const formData = reactive({
  label: props.attribute ? props.attribute.label : "",
  enabled: props.attribute ? props.attribute.enabled : 0,
});

const modalRef = ref(null);
const modalInstance = ref(null);
async function save() {
  try {
    if (props.attribute) {
      await api.feedbackAttributes.update(props.attribute._id, formData);
    } else {
      await api.feedbackAttributes.create(formData);
    }

    store.commit("addToast", {
      title: "Збережено",
    });

    emit("submit");
  } catch (error) {
    console.error(error);
    store.commit("addToast", {
      title: "Виникла помилка. Спробуйте ще раз",
      type: "error",
    });
  }
}

onMounted(() => {
  modalInstance.value = new Modal(modalRef.value, {});
  modalInstance.value.show();
});
onBeforeUnmount(() => {
  modalInstance.value.hide();
});
</script>

<style scoped>
.customCheckbox {
  margin-top: 0.4rem;
}
.customCheck {
  padding-left: 0;
}
.custom-control-label {
  padding-left: 8px;
}
</style>
