<template>
  <div class="container-fluid" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid" v-show="dataLoaded">
    <div class="row">
      <div class="col-12">
        <router-link
          class="btn btn-primary"
          to="/settings/services/new"
          role="button"
          >+ послуга
        </router-link>
        <div class="card">
          <!-- Card header -->
          <div class="card-body">
            <h6 class="mb-0">Послуги</h6>
            <p class="text-sm mb-0">
              {{ getLabelDescription() }}
            </p>
          </div>
          <div class="vgt-responsive">
            <table class="custom-table vgt-table">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  ></th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 thead th"
                    aria-controls="col-1"
                    style="min-width: auto; width: auto"
                  >
                    <span>назва</span>
                  </th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    aria-controls="col-1"
                    style="min-width: auto; width: auto"
                  >
                    <span>ціна</span>
                  </th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    aria-controls="col-1"
                    style="min-width: auto; width: auto"
                  >
                    <span>посада</span>
                  </th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    aria-controls="col-1"
                    style="min-width: auto; width: auto"
                  >
                    <span>тривалість</span>
                  </th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    aria-controls="col-1"
                    style="min-width: auto; width: auto"
                  >
                    <span>Онлайн запис</span>
                  </th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    aria-controls="col-1"
                    style="min-width: auto; width: auto"
                  >
                    <span>ДОСТУПНІСТЬ</span>
                  </th>
                </tr>
              </thead>
              <draggable
                @end="onEnd"
                v-model="tableData.rows"
                v-bind="dragOptions"
                tag="tbody"
                item-key="name"
                handle=".handle"
              >
                <template #item="{ element }">
                  <tr @click="routeToStaffItem(element?._id)" class="">
                    <td>
                      <div class="text-center disableCopyElement">
                        <i class="material-icons text-lg me-2 grab-box handle">
                          drag_indicator</i
                        >
                      </div>
                    </td>
                    <td
                      class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                    >
                      <span>{{ element.name }}</span>
                    </td>
                    <td
                      class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                    >
                      <div
                        v-for="singlePrice in element.prices"
                        :key="singlePrice.positionId?._id"
                      >
                        <span v-if="singlePrice.price"
                          >{{ singlePrice.price }} грн</span
                        >
                        <span v-if="!singlePrice.price"> - </span>
                      </div>
                    </td>
                    <td
                      class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                    >
                      <div
                        v-for="singlePrice in element.prices"
                        :key="singlePrice.positionId?._id"
                      >
                        {{ singlePrice.positionId?.name }}
                      </div>
                    </td>
                    <td
                      class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                    >
                      {{ displayDuration(element.duration) }}
                    </td>

                    <td
                      class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                    >
                      <div class="mx-1">
                        <div
                          class="badge badge-dot"
                          v-if="element.onlineBooking"
                        >
                          <i class="bg-success"></i>
                          <span class="text-dark text-xs">активно</span>
                        </div>
                        <div
                          class="badge badge-dot"
                          v-if="!element.onlineBooking"
                        >
                          <i class="bg-danger"></i>
                          <span class="text-dark text-xs">не активно</span>
                        </div>
                      </div>
                    </td>
                    <td
                      class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                    >
                      <div class="mx-1">
                        <div class="mx-1">
                          <div class="badge badge-dot" v-if="element.isActive">
                            <i class="bg-success"></i>
                            <span class="text-dark text-xs">активно</span>
                          </div>
                          <div class="badge badge-dot" v-if="!element.isActive">
                            <i class="bg-danger"></i>
                            <span class="text-dark text-xs">не активно</span>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </draggable>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/views/shared/Spinner.vue";

import api from "@/services/api";
import constants from "@/constants";
import timeDisplay from "@/utils/timeDisplay";
import { useTable } from "@/composables/good-table";
import draggable from "vuedraggable";

export default {
  setup() {
    const { loadItems, tableData, isLoading } = useTable(api.services.list);

    return {
      loadItems,
      isLoading,
      tableData,
    };
  },
  name: "settings-services",

  components: {
    draggable,
    Spinner,
  },

  async mounted() {
    this.company = await api.company.show();

    setTimeout(() => {
      this.dataLoaded = true;
      this.loadItems();
    }, 500);
  },

  data() {
    return {
      dataLoaded: false,
      company: {
        crmMode: null,
        localizationMode: null,
      },
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 500,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    async remove(id) {
      await api.services.removeOne(id);
    },
    routeToStaffItem(id) {
      this.$router.push(`/settings/services/${id}`);
    },

    async onEnd() {
      let sortIndex = 0;
      const newOrder = this.tableData.rows.map((item) => {
        sortIndex += 1;
        return {
          _id: item._id,
          rank: sortIndex,
        };
      });

      try {
        await api.services.sort(newOrder);
        this.$store.commit("addToast", {
          title: "Збережено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    async toggleIsActive(service) {
      const payload = {
        isActive: service.isActive,
      };
      await api.services.updateOne(service._id, payload);
    },

    displayDuration(minutes) {
      return timeDisplay.minutesToHumanFormat(minutes);
    },

    getLabelDescription() {
      const localizationMode = this.company.localizationMode;

      switch (true) {
        case localizationMode === "spa":
          return `Тут ви можете керувати послугами: створювати, задати їй
            тривалість, вартість.
            Таким чином, по посаді яку ви вказали ресурсу
            bookmenow зрозуміє який з ресурс може виконувати цю послугу.`;
        case localizationMode === "hotel":
          return `Тут ви можете керувати послугами: створювати, задати їй
            тривалість, ціну, суму для онлайн оплати.
            Таким чином, по посаді яку ви вказали номеру
            bookmenow зрозуміє який з номерів може виконувати цю послугу.`;
        default:
          return `Тут ви можете керувати послугами: створювати, задати їй
            тривалість, ціну, суму для онлайн оплати.
            Таким чином, по посаді яку ви вказали майстру
            bookmenow зрозуміє хто з майстрів може виконувати цю послугу.`;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.disableCopyElement {
  -webkit-touch-callout: none; /* Отключение контекстного меню iOS */
  -webkit-user-select: none; /* Отключение выбора текста в Safari */
  -khtml-user-select: none; /* Отключение выбора текста в Konqueror HTML */
  -moz-user-select: none; /* Отключение выбора текста в Firefox */
  -ms-user-select: none; /* Отключение выбора текста в Internet Explorer/Edge */
  user-select: none; /* Отключение выбора текста в большинстве современных браузеров */
}

table.vgt-table td {
  vertical-align: middle;
}

.img-thumbnail {
  padding: 0;
  border: none;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

tr {
  background-color: #fff;
  cursor: pointer;

  &:hover {
    background: #fbfbfb;
  }
}

.grab-box {
  cursor: grab;
}

.ghost {
  opacity: 0.7;
  background: #d7d6d6;
}
</style>
