export const colors = {
  COLOR_1: "#C0DDDB",
  COLOR_2: "#FAFAD6",
  COLOR_3: "#FADFB3",
  COLOR_4: "#BCECED",
  COLOR_5: "#FCF0D8",
  COLOR_6: "#FFFFDF",
  COLOR_7: "#D3C4E3",
  COLOR_8: "#B8DEDB",
};
