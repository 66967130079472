<template>
  <div class="container-fluid my-3 py-3">
    <div class="row mb-5">
      <div class="col-lg-12">
        <!-- Card Общая информация -->
        <div class="card" id="basic-info">
          <div class="card-header">
            <div class="row">
              <div class="col-9">
                <h6>Абонементи</h6>
                <div class="form-check form-switch text-center">
                  <input
                    class="form-control form-check-input"
                    type="checkbox"
                    checked
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="nav-wrapper position-relative end-0">
              <ul class="nav nav-pills nav-fill p-1" role="tablist">
                <li class="nav-item">
                  <a
                    id="nav-profile-tab"
                    class="nav-link mb-0 px-0 py-1 active"
                    data-bs-toggle="tab"
                    href="#nav-profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="true"
                  >
                    Огляд
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link mb-0 px-0 py-1"
                    data-bs-toggle="tab"
                    href="#nav-contact"
                    role="tab"
                    aria-controls="dashboard"
                    aria-selected="false"
                  >
                    Абонементи
                  </a>
                </li>
              </ul>
            </div>
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade active show"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <div class="card-body p-1">
                  <div class="table-responsive">
                    <div class="row">
                      <div class="w-50">
                        <router-link
                          to="/sell-abonement"
                          class="btn btn-icon bg-gradient-primary"
                        >
                          + продаж
                        </router-link>
                        <!-- Modal
                        <div
                          class="modal fade"
                          id="sellabonement"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div
                            class="modal-dialog modal-dialog-centered"
                            role="document"
                          >
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5
                                  class="modal-title font-weight-normal"
                                  id="exampleModalLabel"
                                >
                                  Продаж абонементу
                                </h5>
                                <button
                                  type="button"
                                  class="btn-close text-dark"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <div class="row">
                                  <div class="w-50">Оберіть клієнта</div>
                                  <div class="w-50">
                                    <div class="dropdown d-inline">
                                      <a
                                        href="javascript:;"
                                        class="btn btn-sm btn-outline-dark dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        id="navbarDropdownMenuLink2"
                                      >
                                        Вибрати
                                      </a>
                                      <ul
                                        class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                                        aria-labelledby="navbarDropdownMenuLink2"
                                        data-popper-placement="left-start"
                                      >
                                        <li>
                                          <a
                                            class="dropdown-item border-radius-md"
                                            href="javascript:;"
                                            >Сергій (+380671234567)</a
                                          >
                                        </li>
                                        <li>
                                          <a
                                            class="dropdown-item border-radius-md"
                                            href="javascript:;"
                                            >Петро (+380671234567)</a
                                          >
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="w-50">Оберіть абонменет</div>
                                  <div class="w-50">
                                    <div class="dropdown d-inline">
                                      <a
                                        href="javascript:;"
                                        class="btn btn-sm btn-outline-dark dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        id="navbarDropdownMenuLink2"
                                      >
                                        Вибрати
                                      </a>
                                      <ul
                                        class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                                        aria-labelledby="navbarDropdownMenuLink2"
                                        data-popper-placement="left-start"
                                      >
                                        <li>
                                          <a
                                            class="dropdown-item border-radius-md"
                                            href="javascript:;"
                                            >Гітара группове 12 відвідувань</a
                                          >
                                        </li>
                                        <li>
                                          <a
                                            class="dropdown-item border-radius-md"
                                            href="javascript:;"
                                            >Гітара группове 8 відвідувань</a
                                          >
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                Оплата
                                <div class="col-lg-12">
                                  <div
                                    class="input-group input-group-outline paymentInput"
                                  >
                                    <input
                                      type="email"
                                      class="form-control"
                                      placeholder="Сума грн."
                                    />
                                  </div>
                                    <div class="row mt-2">
                                      <div class="col-lg-3">
                                        <button
                                          class="btn btn-icon btn-3 btn-outline-secondary"
                                          style="
                                            width: 110px;
                                            padding-left: 5px;
                                            padding-right: 5px;
                                          "
                                          type="button"
                                        >
                                          <span class="btn-inner--icon"
                                            ><i class="material-icons"
                                              >payments</i
                                            ></span
                                          >
                                          <span class="btn-inner--text">
                                            Готівка</span
                                          >
                                        </button>
                                      </div>
                                      <div class="col-lg-3">
                                        <button
                                          class="btn btn-outline-secondary"
                                          style="
                                            width: 110px;
                                            padding-left: 5px;
                                            padding-right: 5px;
                                          "
                                          type="button"
                                        >
                                          <span class="btn-inner--icon"
                                            ><i class="material-icons"
                                              >credit_card</i
                                            ></span
                                          >
                                          <span class="btn-inner--text">
                                            На картку</span
                                          >
                                        </button>
                                      </div>
                                      <div class="col-lg-3">
                                        <button
                                          class="btn btn-icon btn-3 btn-outline-secondary"
                                          style="
                                            width: 110px;
                                            padding-left: 5px;
                                            padding-right: 5px;
                                          "
                                          type="button"
                                        >
                                          <span class="btn-inner--icon"
                                            ><i class="material-icons"
                                              >receipt_long</i
                                            ></span
                                          >
                                          <span class="btn-inner--text">
                                            monobank</span
                                          >
                                        </button>
                                      </div>
                                      <div class="col-lg-3">
                                        <button
                                          class="btn btn-icon btn-3 btn-outline-secondary"
                                          style="
                                            width: 110px;
                                            padding-left: 5px;
                                            padding-right: 5px;
                                          "
                                          type="button"
                                        >
                                          <span class="btn-inner--icon"
                                            ><i class="material-icons"
                                              >receipt_long</i
                                            ></span
                                          >
                                          <span class="btn-inner--text">
                                            LiqPay</span
                                          >
                                        </button>
                                      </div>
                                    </div>
                                     <div class="row">
                                      <span>До сплати: 5000</span><br>
                                      <span class="text-success">Сплачено: 2500</span><br>
                                      <span class="text-danger">Залишилось: 2500</span><br>
                                    </div>
                                    <div class="row">
                                      <a href="https://pay.mbnk.biz/2308246KZxySsHSvjf3e">https://pay.mbnk.biz/2308246KZxySsHSvjf3e</a>
                                    </div>
                                  <div
                                    class="d-flex justify-content-center mb-1"
                                    v-if="showDevFeatures"
                                  >
                                    <div class="col-12">
                                      <button
                                        class="btn btn-success px-3 mb-0"
                                        type="button"
                                      >
                                        Зберегти
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button
                                  type="button"
                                  class="btn bg-gradient-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  закрити
                                </button>
                                <button
                                  type="button"
                                  class="btn bg-gradient-primary"
                                  data-bs-dismiss="modal"
                                >
                                  зберегти
                                </button>
                              </div>
                            </div>
                          </div>
                        </div> -->
                      </div>
                      <div class="w-50">
                        <div class="dropdown d-inline">
                          <a
                            href="javascript:;"
                            class="btn btn-sm btn-outline-dark dropdown-toggle"
                            data-bs-toggle="dropdown"
                            id="navbarDropdownMenuLink2"
                          >
                            Дійсний
                          </a>
                          <ul
                            class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                            aria-labelledby="navbarDropdownMenuLink2"
                            data-popper-placement="left-start"
                          >
                            <li>
                              <a
                                class="dropdown-item border-radius-md"
                                href="javascript:;"
                                >Всі статуси</a
                              >
                            </li>
                            <hr class="horizontal dark my-2" />
                            <li>
                              <a
                                class="dropdown-item border-radius-md"
                                href="javascript:;"
                                >Дійсний</a
                              >
                            </li>
                            <li>
                              <a
                                class="dropdown-item border-radius-md"
                                href="javascript:;"
                                >Скінчився</a
                              >
                            </li>
                            <li>
                              <a
                                class="dropdown-item border-radius-md"
                                href="javascript:;"
                                >Скінчився за датою</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <table class="table table-flush text-xs" id="products-list">
                      <thead class="thead-light">
                        <tr>
                          <th class="text-center">№</th>
                          <th class="text-center">Назва</th>
                          <th class="text-center">Клієнт</th>
                          <th class="text-center">Продаж</th>
                          <th class="text-center">Вартість</th>
                          <!-- <th class="text-center">Діє до</th> -->
                          <th class="text-center">Баланс</th>
                          <th class="text-center">Статус</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-center">
                            <span>1</span>
                          </td>
                          <td class="text-center">
                            <span>Гітара 12 групових</span>
                          </td>
                          <td class="text-center">
                            <span
                              >Сергій Петров<br />
                              +380960047047</span
                            >
                          </td>
                          <td class="text-center">
                            <span
                              >19.12.2022<br />
                              Artem Pavlov</span
                            >
                          </td>
                          <td class="text-center">
                            <span>2500 грн</span>
                          </td>
                          <!-- <td class="text-center">
                            <span>20.12.2022</span>
                          </td> -->
                          <td class="text-center">
                            <span>6</span>
                          </td>
                          <td class="text-center">
                            <span class="badge badge-dot">
                              <i class="bg-success"></i>
                              <span class="text-dark text-xs">дійсний</span>
                            </span>
                          </td>
                          <td class="text-center">
                            <button
                              type="button"
                              class="btn mb-3 mb-md-0 ms-auto"
                              name="button"
                            >
                              <i
                                class="mx-2 text-sm material-icons text-danger position-relative"
                                >delete</i
                              >
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-center">
                            <span>2</span>
                          </td>
                          <td class="text-center">
                            <span>Гітара 12 групових</span>
                          </td>
                          <td class="text-center">
                            <span
                              >Марк Петров<br />
                              +380960047047</span
                            >
                          </td>
                          <td class="text-center">
                            <span
                              >19.12.2022<br />
                              Artem Pavlov</span
                            >
                          </td>
                          <td class="text-center">
                            <span>2500 грн</span>
                          </td>

                          <td class="text-center">
                            <span>0</span>
                          </td>
                          <td class="text-center">
                            <span class="badge badge-dot me-4">
                              <i class="bg-danger"></i>
                              <span class="text-dark text-xs">скінчився</span>
                            </span>
                          </td>
                          <td class="text-center">
                            <button
                              type="button"
                              class="btn mb-3 mb-md-0 ms-auto"
                              name="button"
                            >
                              <i
                                class="mx-2 text-sm material-icons text-danger position-relative"
                                >delete</i
                              >
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-center">
                            <span>3</span>
                          </td>
                          <td class="text-center">
                            <span>Гітара 12 групових</span>
                          </td>
                          <td class="text-center">
                            <span
                              >Марічка<br />
                              +380960047047</span
                            >
                          </td>
                          <td class="text-center">
                            <span
                              >19.12.2022<br />
                              Artem Pavlov</span
                            >
                          </td>
                          <td class="text-center">
                            <span>2500 грн</span>
                          </td>
                          <td class="text-center">
                            <span>0</span>
                          </td>
                          <td class="text-center">
                            <span class="badge badge-dot me-4">
                              <i class="bg-danger"></i>
                              <span class="text-dark text-xs">скінчився</span>
                            </span>
                          </td>
                          <td class="text-center">
                            <button
                              type="button"
                              class="btn mb-3 mb-md-0 ms-auto"
                              name="button"
                            >
                              <i
                                class="mx-2 text-sm material-icons text-danger position-relative"
                                >delete</i
                              >
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-center">
                            <span>4</span>
                          </td>
                          <td class="text-center">
                            <span>Гітара 12 групових</span>
                          </td>
                          <td class="text-center">
                            <span
                              >Майкл Джордан<br />
                              +380960047047</span
                            >
                          </td>
                          <td class="text-center">
                            <span
                              >31.12.2023<br />
                              Artem Pavlov</span
                            >
                          </td>
                          <td class="text-center">
                            <span>2500 грн</span>
                          </td>
                          <td class="text-center">
                            <span>0</span>
                          </td>
                          <td class="text-center">
                            <span class="badge badge-dot me-4">
                              <i class="bg-info"></i>
                              <span class="text-dark text-xs"
                                >очікує оплату</span
                              >
                            </span>
                          </td>
                          <td class="text-center">
                            <button
                              type="button"
                              class="btn mb-3 mb-md-0 ms-auto"
                              name="button"
                            >
                              <i
                                class="mx-2 text-sm material-icons text-danger position-relative"
                                >delete</i
                              >
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="nav-contact"
                role="tabpanel"
                aria-labelledby="nav-contact-tab"
              >
                <div class="card-body p-3">
                  <div class="table-responsive">
                    <router-link
                      to="/add-abonement/"
                      class="btn btn-icon bg-gradient-primary"
                    >
                      + абонемент
                    </router-link>
                    <table class="table table-flush text-xs" id="products-list">
                      <thead class="thead-light">
                        <tr>
                          <th class="text-start">Назва</th>
                          <th class="text-start">Вартість</th>
                          <th class="text-start">Кількість візитів</th>
                          <th class="text-start">Послуги</th>
                          <!-- <th>Заморозка</th> -->
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-sm">
                            <div class="col-5 text-start">
                              <span class="text-xs">Гітара групове</span>
                            </div>
                          </td>
                          <td class="text-sm">
                            <div class="col-5 text-start">
                              <span class="text-xs">4500 грн</span>
                            </div>
                          </td>
                          <td class="text-sm">
                            <div class="col-5 text-start">
                              <span class="text-xs">12</span>
                            </div>
                          </td>
                          <td class="text-sm">
                            <div class="col-5 text-start">
                              <span class="text-xs">Групові гітара</span>
                            </div>
                          </td>
                          <td class="text-sm">
                            <div class="d-flex">
                              <a
                                href="javascript:;"
                                data-bs-toggle="tooltip"
                                data-bs-original-title="Посмотреть"
                              >
                                <i class="fas fa-eye text-secondary"></i>
                              </a>
                              <i
                                class="mx-2 material-icons text-danger position-relative"
                                >delete</i
                              >
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-sm">
                            <div class="col-5 text-start">
                              <span class="text-xs">Барабани групове</span>
                            </div>
                          </td>
                          <td class="text-sm">
                            <div class="col-5 text-start">
                              <span class="text-xs">4500 грн</span>
                            </div>
                          </td>
                          <td class="text-sm">
                            <div class="col-5 text-start">
                              <span class="text-xs">12</span>
                            </div>
                          </td>
                          <td class="text-sm">
                            <div class="col-5 text-start">
                              <span class="text-xs">Групові барабани</span>
                            </div>
                          </td>
                          <td class="text-sm">
                            <div class="d-flex">
                              <a
                                href="javascript:;"
                                data-bs-toggle="tooltip"
                                data-bs-original-title="Посмотреть"
                              >
                                <i class="fas fa-eye text-secondary"></i>
                              </a>
                              <i
                                class="mx-2 material-icons text-danger position-relative"
                                >delete</i
                              >
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-sm">
                            <div class="col-5 text-start">
                              <span class="text-xs">Гітара індивідуальні</span>
                            </div>
                          </td>
                          <td class="text-sm">
                            <div class="col-5 text-start">
                              <span class="text-xs">6500 грн</span>
                            </div>
                          </td>
                          <td class="text-sm">
                            <div class="col-5 text-start">
                              <span class="text-xs">12</span>
                            </div>
                          </td>
                          <td class="text-sm">
                            <div class="col-5 text-start">
                              <span class="text-xs">Гітара індив</span>
                            </div>
                          </td>
                          <td class="text-sm">
                            <div class="d-flex">
                              <a
                                href="javascript:;"
                                data-bs-toggle="tooltip"
                                data-bs-original-title="Посмотреть"
                              >
                                <i class="fas fa-eye text-secondary"></i>
                              </a>
                              <i
                                class="mx-2 material-icons text-danger position-relative"
                                >delete</i
                              >
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-sm">
                            <div class="col-5 text-start">
                              <span class="text-xs"
                                >Барабани індивідуальні</span
                              >
                            </div>
                          </td>
                          <td class="text-sm">
                            <div class="col-5 text-start">
                              <span class="text-xs">6500 грн</span>
                            </div>
                          </td>
                          <td class="text-sm">
                            <div class="col-5 text-start">
                              <span class="text-xs">12</span>
                            </div>
                          </td>
                          <td class="text-sm">
                            <div class="col-5 text-start">
                              <span class="text-xs">Барабани індив</span>
                            </div>
                          </td>
                          <td class="text-sm">
                            <div class="d-flex">
                              <a
                                href="javascript:;"
                                data-bs-toggle="tooltip"
                                data-bs-original-title="Посмотреть"
                              >
                                <i class="fas fa-eye text-secondary"></i>
                              </a>
                              <i
                                class="mx-2 material-icons text-danger position-relative"
                                >delete</i
                              >
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Card Общая информация finish -->
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";

export default {
  name: "salary",
  data() {
    return {};
  },
  mounted() {
    setNavPills();
  },
};
</script>
