<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <h5>Інтеграція з Telegram</h5>
              <div class="">
                Інтеграція з Telegram дозволяє адміністраторам та власникам
                отримувати автоматичні сповіщення про нові бронювання, а також
                про зміни в існуючих.
                <br />
                <!-- Кожен користувач адмін панелі може налаштувати які саме сповіщення він хоче отримувати від бота. Ці налаштування можна виконати в <a href="/team/editprofile" class="text-primary">Профілі користувача</a> -->
                <br />
                <h6 class="">Інструкція для Адміністраторів</h6>
                Щоб ващі Адміністратори почали отримувати повідомлення від
                bookmenow:
                <ol>
                  <li class="mb-2">
                    Переконайтесь, що Адміністратору встановлено той самий номер
                    телефону який у нього використовується в Telegram. Це можна
                    зробити<a href="/team/managers" class="text-primary ms-1"
                      >тут</a
                    >.
                  </li>
                  <li class="mb-2">
                    Попросіть Адміністратора підписатись на Telegram бота
                    bookmenow. Він доступний за посиланням
                    <a
                      href="https://t.me/Bookmenowbot"
                      target="_blank"
                      class="text-primary ms-1"
                      >https://t.me/bookmenowbot</a
                    >.
                  </li>
                  <li class="mb-2">
                    Адміністратор має виконати всі вказівки бота
                  </li>
                  Якщо все зроблено вірно, то Адміністратор отримає таке
                  повідомлення:
                  <br />
                  <code
                    >Вітаємо! Тепер ви будете отримувати найважливіщі
                    повідомлення від bookmenow. До зв’язку 🧑‍💻</code
                  >
                </ol>
                <img
                  src="../../../assets/img/gif/telegram-notification.gif"
                  alt=""
                  style="width: 100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>
