import { createRouter, createWebHistory } from "vue-router";

import SignupComponent from "../views/auth/Signup.vue";
import SigninComponent from "../views/auth/Signin.vue";
const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/authentication/signin",
  },

  {
    path: "/authentication/signin",
    name: "Вхід",
    component: SigninComponent,
    meta: {
      layout: "AuthLayout",
    },
  },
  {
    path: "/authentication/signup",
    name: "Реєстрація",
    component: SignupComponent,
    meta: {
      layout: "AuthLayout",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const routerGuest = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "active",
});

export default routerGuest;
