<script setup>
import { computed } from "vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});
const emit = defineEmits(["update:modelValue", "onChange"]);
const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>
<template>
  <div class="form-check form-switch">
    <input
      class="form-check-input"
      @change="emit('onChange', value)"
      v-model="value"
      type="checkbox"
    />
  </div>
</template>

<style lang="scss" scoped></style>
