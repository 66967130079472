<script setup>
import { computed, defineOptions, ref, useAttrs } from "vue";
import { useSlots } from "vue";

defineOptions({
  inheritAttrs: false,
});
const props = defineProps({
  modelValue: {
    type: [String, Number, null],
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
  invalid: {
    type: Boolean,
    default: false,
  },
  valid: {
    type: Boolean,
    default: false,
  },
});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:modelValue"]);
const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
const inputRef = ref();
defineExpose({
  inputRef,
});
</script>
<template>
  <div class="input-wrap d-flex gap-2 w-100">
    <div
      class="input-group input-group-outline flex-grow-1"
      :class="{
        'is-filled': value,
        'is-invalid ': invalid,
        'is-valid ': valid,
      }"
    >
      <label class="form-label">{{ label }}</label>
      <slot v-if="slots.customInput" name="customInput"></slot>
      <input
        ref="inputRef"
        v-else
        type="text"
        class="form-control text-xs error"
        :disabled="disabled"
        v-model="value"
        v-bind="attrs"
      />
    </div>
    <div v-if="slots.iconBtn" class="flex-shrink-0 icon">
      <slot name="iconBtn"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.input-wrap {
  .input-group.input-group-outline {
    & .form-label {
      display: inline-block;
      top: 50%;
      transform: translateY(-50%);
      width: auto !important;
      height: auto !important;
      left: 0.625rem;
      padding: 0;
      z-index: 5;
      line-height: 1;
      pointer-events: none;
      &::after {
        display: none;
      }
      &::before {
        display: none;
      }

      & + .form-control {
        border-top-color: #d2d6da !important;
        box-shadow: none !important;
      }
    }
    &.is-filled {
      .form-label {
        padding: 0 4px;
        background: #fff;
        transform: translateY(-1.7rem);
        &::after {
          box-shadow: none !important;
        }
        & + .form-control {
          border-top-color: #d2d6da !important;
          box-shadow: none !important;
        }
        ::v-deep {
          & + .form-control {
            border-top-color: #d2d6da !important;
            box-shadow: none !important;
          }
        }
      }
    }
    &.is-valid.is-filled {
      .form-label + .form-control {
        border-top-color: #4caf50 !important;
      }
    }
    &.is-filled.is-invalid {
      .form-label + .form-control {
        border-top-color: #f44335 !important;
      }
    }
  }

  .icon {
    width: auto;
  }

  input {
    height: var(--height-ui-el);
  }
}
</style>
