<template>
  <div class="container-fluid" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>

  <div class="container-fluid" v-show="dataLoaded">
    <div class="row">
      <div class="col-12">
        <router-link
          v-if="!branches.length"
          class="btn btn-primary"
          to="/settings/branches/new"
          role="button"
          >+ Філія</router-link
        >
        <!-- TODO: temporarily allow only one branch -->
        <button
          v-if="branches.length"
          type="button"
          class="btn bg-gradient-primary"
          :disabled="true"
        >
          + Філія
        </button>
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h6 class="mb-0">Всі філії</h6>
            <p class="text-sm mb-0">
              Філія - це фізичне місце, де ваша компанія здійснює послуги.
              Зазвичай у філій різні адреси, тому коли клієнт бронює послугу то
              він може обрати по якій адресі він хоче отримати послугу.
            </p>
          </div>
          <div class="table-responsive">
            <table class="table table-hover" id="datatable-search">
              <thead class="thead-light">
                <tr>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  ></th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    адреса
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Телефон
                  </th>
                </tr>
              </thead>
              <tbody v-if="branches && branches.length">
                <tr v-for="branch in branches" :key="branch._id">
                  <td class="text-sm font-weight-normal text-center edit">
                    <a
                      :href="`/settings/branches/${branch._id}`"
                      class=""
                      data-bs-toggle="tooltip"
                      data-bs-original-title="Редагувати"
                    >
                      <i
                        class="material-icons text-secondary position-relative text-lg"
                        >drive_file_rename_outline</i
                      >
                    </a>
                  </td>
                  <td class="text-sm font-weight-normal">
                    {{ branch.address }}
                  </td>
                  <td class="text-sm font-weight-normal">
                    {{ branch.phoneNumber }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.edit {
  max-width: 15px;
}
</style>

<script>
import { DataTable } from "simple-datatables";
import Spinner from "@/views/shared/Spinner.vue";

import api from "../../../../services/api";

export default {
  name: "settings-branches",

  components: {
    Spinner,
  },

  async created() {
    this.branches = await api.branches.list();
  },

  async mounted() {
    setTimeout(() => {
      // eslint-disable-next-line no-unused-vars
      const dataTableSearch = new DataTable("#datatable-search", {
        searchable: true,
        fixedHeight: false,
      });
      this.dataLoaded = true;
    }, 500);
  },

  data() {
    return {
      dataLoaded: false,
      branches: [],
    };
  },

  methods: {
    async remove(id) {
      await api.branches.removeOne(id);
    },

    async toggleIsActive(branch) {
      const payload = {
        isActive: branch.isActive,
      };
      await api.branches.updateOne(branch._id, payload);
    },
  },
};
</script>
