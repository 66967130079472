import { computed, ref, watch } from "vue";

export function useDaysSelection() {
  const days = ref([
    { number: 1, label: "Понеділок", isActive: false },
    {
      number: 2,
      label: "Вівторок",
      isActive: false,
    },
    { number: 3, label: "Середа", isActive: false },
    { number: 4, label: "Четвер", isActive: false },
    {
      number: 5,
      label: "Пятниця",
      isActive: false,
    },
    { number: 6, label: "Субота", isActive: false },
    { number: 7, label: "Неділя", isActive: false },
  ]);

  const allDay = ref(true);

  watch(
    allDay,
    (newValue) => {
      if (newValue) {
        days.value = days.value.map((item) => ({ ...item, isActive: true }));
      } else {
        days.value = days.value.map((item) => ({ ...item, isActive: false }));
      }
    },
    { immediate: true }
  );
  const selectedDays = computed(() => {
    if (!days.value) {
      return null;
    }
    return days.value.filter((item) => item.isActive);
  });
  return { days, allDay, selectedDays };
}
