<template>
  <div class="container-fluid my-3 py-3">
    <div class="row mb-5">
      <div class="col-lg-9 mx-auto">
        <div class="card mt-2" id="run-cron-jobs">
          <div class="card-body">
            <div class="d-flex align-items-center mb-sm-0 mb-4">
              <div class="w-50">
                <h6>Запустити cron-скрипти</h6>
                Список скриптів:
                <ul>
                  <li v-for="job in cronJobs" :key="job">
                    {{ job }}
                  </li>
                </ul>
              </div>
              <div class="w-50 text-end">
                <button
                  class="btn bg-gradient-danger mb-0 ms-2"
                  type="button"
                  name="button"
                  data-bs-toggle="modal"
                  :data-bs-target="`#${modalParamsRunCronJobs.modalId}`"
                >
                  Запустити cron-скрипти
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <BmnCustomPopup :params="modalParamsRunCronJobs"></BmnCustomPopup>
</template>

<style scoped></style>

<script>
import api from "@/services/api";
import BmnCustomPopup from "@/components/BmnCustomPopup.vue";

export default {
  data() {
    return {
      profile: {
        rolesMap: {
          isRoot: false,
          isOwner: false,
          isManager: false,
          isStaff: false,
        },
      },

      cronJobs: ["checkServiceOrdersForPrepayment"],

      modalParamsRunCronJobs: {
        modalId: "modal-default-1",
        modalTitle: "Запустити скрипти",
        modalDescription: "Ви впевнені, що хочете запустити скрипти?",
        modalButtonCancelText: "Ні, не варто",
        modalButtonConfirmText: "Так, запустити",
        data: {},
        confirmHandler: async () => {
          for (const name of this.cronJobs) {
            const result = await api.maintenance.runCronScript(name);
            console.log(result);
          }
        },
      },
    };
  },

  async created() {
    this.showDevFeatures = !!localStorage.getItem("showDevFeatures");
    if (!this.showDevFeatures) {
      window.location.href = "/";
      return;
    }

    try {
      const user = await api.myprofile.show();

      this.profile.rolesMap = user.rolesMap;

      if (!this.profile.rolesMap.isRoot) {
        window.location.href = "/";
        return;
      }
    } catch (error) {
      console.error(error);
    }
  },

  components: {
    BmnCustomPopup,
  },
};
</script>
