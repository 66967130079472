<template>
  <div class="container-fluid my-3 py-3" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid" v-show="dataLoaded">
    <div class="row">
      <div class="col-lg-12 mx-auto">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <h5>Менеджер каналів</h5>
              <div class="">
                Якщо ви використовуєте декілька каналів продажів, то за
                допомогою Менеджера каналів ви можете підключити синхронізацію
                бронювань між bookmenow і більшістю Online Travel Agencies, як
                то booking.com / airbnb / тощо.<br />
                Фінкціонал Менеджера каналів тарифікується окремо за ціною
                вказаною
                <a href="https://bookmenow.pro/pricing-hotels" target="_blank"
                  >тут</a
                >
                <br />
                Для підключення - зверніться, будь ласка, до вашого менеджера.
                <br />
                <br />
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="company.isChannelManagerEnabled"
                    disabled
                  />
                  <label class="form-check-label" for="flexSwitchCheckDefault"
                    >Увімкнути Менеджер каналів</label
                  >
                </div>
                <!-- <h6 class="">Як підключити bookmenow до booking.com</h6> -->
                <div>
                  <h6>Як підключити bookmenow до airbnb</h6>
                  <p>
                    <a
                      href="https://bookmenow.pro/kb/integration-airbnb"
                      target="_blank"
                      >https://bookmenow.pro/kb/integration-airbnb</a
                    >
                  </p>
                </div>
                <div>
                  <h6>Як підключити bookmenow до booking.com</h6>
                  <p>
                    <a
                      href="https://bookmenow.pro/kb/integration-booking"
                      target="_blank"
                      >https://bookmenow.pro/kb/integration-booking</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  Зберегти -->
        <div class="fixed-bottom">
          <div class="row">
            <div class="col-10"></div>
            <div class="col-lg-2">
              <div class="mx-4">
                <button
                  type="button"
                  class="block btn bg-gradient-primary"
                  @click="save"
                >
                  <SmallLoader v-if="isLoading" />
                  <span v-else> Зберегти</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--  Зберегти фiнiш-->
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import Spinner from "@/views/shared/Spinner.vue";
import SmallLoader from "@/views/shared/SmallLoader.vue";

export default {
  name: "channel-manager",

  async mounted() {
    this.company = await api.company.show();
    this.showDevFeatures = !!localStorage.getItem("showDevFeatures");

    this.dataLoaded = true;
  },

  data() {
    return {
      showDevFeatures: false,
      dataLoaded: false,
      isLoading: false,
      company: {
        crmMode: "",
        isChannelManagerEnabled: false,
      },
    };
  },

  components: {
    SmallLoader,
    Spinner,
  },

  methods: {
    async save() {
      this.isLoading = true;

      try {
        await api.company.update({
          isChannelManagerEnabled: this.company.isChannelManagerEnabled,
        });

        this.$store.commit("addToast", {
          title: "Збережено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }

      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}
</style>
