export const sizes = {
  WIDTH_AREA_RESOURCES_FULLCALENDAR_PC: 150,
  WIDTH_AREA_RESOURCES_FULLCALENDAR_MOB: 80,
  WIDTH_AREA_RESOURCES_FULLCALENDAR_MOB_SMALLER: 40,
  MIN_WIDTH_SLOT_FULLCALENDAR_PC: 90,
  MIN_WIDTH_SLOT_FULLCALENDAR_MOB_SMALLER: 40,
  MIN_WIDTH_SLOT_FULLCALENDAR_TIMELINE_PC: 70,
  MIN_WIDTH_SLOT_FULLCALENDAR_TIMELINE_MOB_SMALLER: 30,
  WIDTH_COLUMN_SCHEDULER_PC: 70,
  WIDTH_COLUMN_SCHEDULER_MOB: 50,
  WIDTH_AREA_SCHEDULER_PC: 150,
  WIDTH_AREA_SCHEDULER_MOB: 70,
  WIDTH_COLUMN_SCHEDULER_SMALLER_MOB: 30,
  WIDTH_AREA_SCHEDULER_SMALLER_MOB: 40,
};
